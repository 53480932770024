import {
  BOARD_BASE_FRAGMENT,
  BOARD_MEMBER_OPTIONS_FRAGMENT,
} from '#board/fragment'
import {
  BASE_WORKSPACE_FRAGMENT,
  WORKSPACE_SETTINGS_FRAGMENT,
} from '#workspace/fragment'

export const WORKSPACES_QUERY = gql`
  ${BASE_WORKSPACE_FRAGMENT}
  query WorkspacesListQuery {
    workspaces {
      ...BaseWorkspace
    }
  }
`
export const WORKSPACE_DETAIL_QUERY = gql`
  ${BASE_WORKSPACE_FRAGMENT}
  query WorkspaceDetailQuery($id: ID, $handle: String) {
    workspace(id: $id, handle: $handle) {
      ...BaseWorkspace
    }
  }
`

export const WORKSPACE_DETAIL_SETTING_QUERY = gql`
  ${BASE_WORKSPACE_FRAGMENT}
  query WorkspaceDetailSettingQuery($id: ID!) {
    workspace(id: $id) {
      ...BaseWorkspace
      enableInvitationLink
      invitationToken
      membershipRestrictions
      membershipRestrictionsDomains
    }
  }
`

export const WORKSPACE_QUICK_LOAD_QUERY = gql`
  query WorkspaceQuickLoadQuery($handle: String!) {
    workspace(handle: $handle) {
      id
    }
  }
`

export const WORKSPACE_BOARDS_QUERY = gql`
  ${BOARD_BASE_FRAGMENT}
  query WorkspaceBoardsQuery($filter: WorkspaceBoardFilterInput!) {
    boards(filter: $filter) {
      ...BaseBoard
    }
  }
`

export const WORKSPACE_LIST_BOARDS_QUERY = gql`
  ${BOARD_BASE_FRAGMENT}
  ${BOARD_MEMBER_OPTIONS_FRAGMENT}
  query WorkspaceListBoardsQuery(
    $filter: WorkspaceBoardFilterInput!
    $workspaceId: ID!
  ) {
    boards(filter: $filter) {
      ...BaseBoard
    }
    memberBoards(workspaceId: $workspaceId) {
      ...BoardMemberOptionsFields
      board {
        id
      }
    }
  }
`

export const CREATE_WORKSPACE_MUTATION = gql`
  ${BASE_WORKSPACE_FRAGMENT}
  mutation CreateWorkspaceMutation($name: String!) {
    createWorkspace(name: $name) {
      workspace {
        ...BaseWorkspace
      }
    }
  }
`

export const UPDATE_WORKSPACE_MUTATION = gql`
  ${BASE_WORKSPACE_FRAGMENT}
  mutation UpdateWorkspaceMutation($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      workspace {
        ...BaseWorkspace
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_WORKSPACE_WITH_LOGO_MUTATION = gql`
  ${BASE_WORKSPACE_FRAGMENT}
  mutation UpdateWorkspaceMutation($input: UpdateWorkspaceInput!, $id: ID!, $logo: Upload!) {
    updateWorkspaceLogo(id: $id, logo: $logo) {
      logo
    }
    updateWorkspace(input: $input) {
      workspace {
        ...BaseWorkspace
      }
      errors {
        field
        messages
      }
    }
  }
`

export const LAST_ACTIVE_WORKSPACE_QUERY = gql`
  query LastActiveWorkspaceQuery {
    lastActiveWorkspace {
      handle
    }
  }
`

export const TOGGLE_WORKSPACE_LINK_INVITATION_MUTATION = gql`
  ${WORKSPACE_SETTINGS_FRAGMENT}
  mutation ToggleWorkspaceLinkInvitation($workspaceId: ID!, $enable: Boolean!) {
    toggleWorkspaceLinkInvitation(workspaceId: $workspaceId, enable: $enable) {
      workspace {
        ...WorkspaceSettings
      }
    }
  }
`
