<template>
  <UPopover
    ref="popoverRef"
    v-model:open="open"
    :ui="{ width: 'w-[280px]' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
    v-bind="$attrs"
  >
    <template #panel>
      <template v-if="showCreateForm">
        <div class="flex items-center justify-between px-4 pt-3 pb-2">
          <div class="flex items-center gap-1">
            <UButton
              size="xs"
              variant="ghost"
              color="gray"
              icon="i-heroicons-arrow-left"
              @click="showCreateForm = false"
            />
            <p class="font-semibold text-sm text-gray-900">Create label</p>
          </div>
          <UButton
            size="xs"
            icon="i-heroicons-x-mark"
            color="gray"
            variant="ghost"
            @click="open = false"
          />
        </div>
        <LabelForm
          ref="createForm"
          :initial-value="{ name: search }"
          @submit="onCreateLabel"
        >
          <template #footer>
            <div class="flex justify-end items-center mt-4 gap-2">
              <UButton type="submit"> Create </UButton>
            </div>
          </template>
        </LabelForm>
      </template>
      <template v-else-if="selectedLabel">
        <div class="flex items-center justify-between px-4 py-3">
          <div class="flex items-center gap-1">
            <UButton
              size="xs"
              variant="ghost"
              color="gray"
              icon="i-heroicons-arrow-left"
              @click="selectedLabel = null"
            />
            <p class="font-semibold text-sm text-gray-900">Edit label</p>
          </div>
          <UButton
            size="xs"
            icon="i-heroicons-x-mark"
            color="gray"
            variant="ghost"
            @click="open = false"
          />
        </div>
        <LabelForm
          ref="editForm"
          :initial-value="selectedLabel"
          @submit="onUpdateLabel"
        >
          <template #footer>
            <div class="flex justify-end items-center mt-4 gap-2">
              <UButton
                color="red"
                class="text-red-500"
                variant="soft"
                data-test="label-delete-button"
                @click="onDeleteLabel"
              >
                Delete
              </UButton>
              <UButton type="submit"> Save </UButton>
            </div>
          </template>
        </LabelForm>
      </template>
      <template v-else>
        <div class="py-3 px-4">
          <div class="flex items-center justify-between">
            <p class="font-semibold text-sm text-gray-900">{{ heading }}</p>
            <UButton
              size="xs"
              icon="i-heroicons-x-mark"
              color="gray"
              variant="ghost"
              data-test="label-close-button"
              @click="open = false"
            />
          </div>
          <div class="mt-2">
            <UInput
              v-model="search"
              icon="i-heroicons-magnifying-glass"
              size="sm"
              autofocus
              placeholder="Search labels..."
              data-test="label-search"
            />
          </div>
          <div
            v-if="filteredLabels?.length"
            class="mt-4 overflow-auto pr-1 scroll-stable minimal-scrollbar max-h-[25rem]"
          >
            <div
              v-for="(label, index) in filteredLabels"
              ref="optionRefs"
              :key="label.id"
              :class="[
                'rounded-md flex items-center gap-2.5 p-1',
                currentFocusLabelIndex === index ? 'bg-gray-100' : '',
              ]"
              @mouseenter="currentFocusLabelIndex = index"
              @mouseleave="currentFocusLabelIndex = -1"
              @click="onClickLabel(label)"
            >
              <Checkbox
                :checked="
                  selectedIds.includes(label.id)
                    || ((isInSelectedTasks || editMultiple)
                      && getCheckboxValue(label) === 'checked') ? true : false
                "
                :indeterminate="
                  isInSelectedTasks
                    ? getCheckboxValue(label) === 'indeterminate'
                    : undefined
                "
                data-test="label-checkbox"
                @change="onClickLabel(label)"
                @click.stop
              />
              <div
                class="px-2.5 flex items-center h-8 rounded-md text-sm text-white truncate flex-1 cursor-pointer"
                :style="getColorPreset(label.color)"
                data-test="label-name"
              >
                {{ label.name }}
              </div>
              <Tooltip
                v-if="label.settingsPackId"
                :text="getSettingsPackName(label.settingsPackId)"
                class="min-w-8 flex justify-center items-center"
              >
                <IconSettingPack />
              </Tooltip>
              <UButton
                v-else
                size="sm"
                class="text-gray-700"
                icon="i-heroicons-pencil-square"
                variant="ghost"
                color="gray"
                data-test="edit-btn"
                @click.prevent.stop="selectedLabel = label"
              />
            </div>
          </div>
          <div class="mt-4 space-y-2">
            <UButton
              icon="i-heroicons-plus-small"
              class="w-full flex justify-center"
              variant="soft"
              color="gray"
              data-test="create-label-btn"
              @click="onShowCreateForm"
            >
              Create new label
            </UButton>
            <UButton
              v-if="labels?.length && labels.length > limit && !search"
              class="w-full flex justify-center"
              variant="soft"
              color="gray"
              data-test="show-more-btn"
              @click="limit += perPage"
            >
              Show more label
            </UButton>
          </div>
        </div>
      </template>
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
import type { Label } from '#label/types'

const props = defineProps({
  selectedIds: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  parentId: {
    type: String,
    required: true,
  },
  taskId: {
    type: String,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
  heading: {
    type: String,
    default: '',
  },
  editMultiple: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['select', 'remove'])

const variables = reactive({
  parentId: props.parentId,
  source: props.source,
  loadFull: true,
})

const toast = useToast()
const { tasks } = useBoardTasksLoader()
const { checkedTasks, updateSelectedTasksLabel } = useListViewSharedState()
const { boardData } = useBoardSharedState()
const { currentBoard } = useWorkspaceSharedState()
const { createLabel } = useCreateLabel()
const { updateLabel } = useUpdateLabel()
const { deleteLabel } = useDeleteLabel()
const { result, load, onResult } = useLabelsLazyQuery(variables, {
  fetchPolicy: 'cache-first',
})

const optionRefs = ref<HTMLElement[]>([])
const currentFocusLabelIndex = ref(-1)
const perPage = 8
const search = ref('')
const limit = ref(perPage)
const open = ref(false)
const labels = ref<Label[]>()
const createForm = ref()
const editForm = ref()
const popoverRef = ref()
const isUpdating = ref(false)
const isCreating = ref(false)
const showCreateForm = ref(false)
const selectedLabel = ref<Label | null>()

const isCurrentBoard = computed(() => currentBoard.value.id === props.parentId)

const filteredLabels = computed(() => {
  if (!labels.value) {
    return []
  }

  return labels.value
    .filter((label) =>
      (label.name ?? '').toLowerCase().includes(search.value.toLowerCase())
    )
    .slice(0, limit.value)
})

const isInSelectedTasks = computed(
  () =>
    checkedTasks.value.has(props.taskId as string) &&
    checkedTasks.value.size
)

const selectedTasks = computed(() => {
  return tasks.value.filter((task) => checkedTasks.value.has(task.id))
})

const getSettingsPackName = (id: string) => {
  const settingsPacks = boardData.value.settingsPacks
  return settingsPacks?.find((settingsPack) => settingsPack.id === id)?.name
}

const getCheckboxValue = (label: Label): string => {
  const selectedTasksLabels = selectedTasks.value.map((task) =>
    task.labels.map((label) => label.id)
  )
  const labelInTasks = selectedTasksLabels.map((labels) =>
    labels.includes(label.id)
  )

  if (labelInTasks.every(Boolean)) {
    return 'checked'
  } else if (labelInTasks.some(Boolean)) {
    return 'indeterminate'
  }

  return 'unchecked'
}

const setLabelWithPositions = () => {
  const selected = props.selectedIds
  const items = conditionalValue<Label[]>(
    isCurrentBoard.value,
    boardData.value.labels,
    result.value?.labels as Label[],
    []
  )
  labels.value = [...items].sort((a, b) => {
    if (selected.includes(a.id) && selected.includes(b.id)) {
      return 0
    }

    if (selected.includes(a.id)) {
      return -1
    }

    return 1
  })
}

const onUpdateLabel = async (params: Pick<Label, 'name' | 'color'>) => {
  if (selectedLabel.value) {
    isUpdating.value = true
    const data = await updateLabel({
      id: selectedLabel.value.id,
      name: params.name,
      color: params.color,
    })
    isUpdating.value = false
    if (data?.error) {
      return editForm.value.setErrors(parseGqlErrors(data.error.graphQLErrors))
    }

    selectedLabel.value = null
  }
}

const onShowCreateForm = () => {
  showCreateForm.value = true
  nextTick(() => {
    popoverRef.value.popperInstance?.forceUpdate()
  })
}

const onClickLabel = (label: Label) => {
  if (isInSelectedTasks.value || props.editMultiple) {
    return updateSelectedTasksLabel(label, getCheckboxValue(label))
  }

  return toggleLabel(label)
}

const onCreateLabel = async (params: Pick<Label, 'name' | 'color'>) => {
  isCreating.value = true
  const isExistingLabel = (labels.value ?? []).find(
    (label) => label.name === params.name && label.color === params.color
  )

  if (isExistingLabel) {
    isCreating.value = false
    showCreateForm.value = false
    createForm.value.resetForm()
    return toggleLabel(isExistingLabel)
  }

  const data = await createLabel({
    ...params,
    parentId: props.parentId,
    source: props.source,
    name: params.name ?? '',
  })
  isCreating.value = false
  if (data?.error) {
    return createForm.value.setErrors(parseGqlErrors(data.error.graphQLErrors))
  }

  if (data?.data) {
    emit('select', data.data.label)
  }

  showCreateForm.value = false
  createForm.value.resetForm()
}

const onDeleteLabel = async () => {
  if (selectedLabel.value) {
    const { error } = await deleteLabel(selectedLabel.value.id)
    if (error) {
      return toast.add({
        title: 'Something went wrong',
        color: 'red',
      })
    }

    selectedLabel.value = null

    toast.add({
      title: 'Deleted label successfully',
    })
  }
}

const toggleLabel = (label: Label) => {
  if (props.selectedIds.includes(label.id)) {
    return emit('remove', label)
  }

  emit('select', label)
}

const scrollToView = (index: number) => {
  if (optionRefs.value[index]) {
    optionRefs.value[index].scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

defineShortcuts({
  arrowup: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusLabelIndex.value > 0) {
        currentFocusLabelIndex.value--
      } else {
        currentFocusLabelIndex.value = filteredLabels.value?.length - 1
      }

      scrollToView(currentFocusLabelIndex.value)
    },
  },
  arrowdown: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusLabelIndex.value < filteredLabels.value?.length - 1) {
        currentFocusLabelIndex.value++
      } else {
        currentFocusLabelIndex.value = 0
      }

      scrollToView(currentFocusLabelIndex.value)
    },
  },
  enter: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (
        currentFocusLabelIndex.value >= 0 &&
        filteredLabels.value[currentFocusLabelIndex.value]
      ) {
        if (isInSelectedTasks.value) {
          updateSelectedTasksLabel(
            filteredLabels.value[currentFocusLabelIndex.value], getCheckboxValue(filteredLabels.value[currentFocusLabelIndex.value])
          )
          return
        }

        toggleLabel(filteredLabels.value[currentFocusLabelIndex.value])
      }
    },
  },
})

onResult(setLabelWithPositions)

defineExpose({
  open: () => {
    open.value = true
  },
  close: () => {
    open.value = false
  },
  toggle: () => {
    open.value = !open.value
  },
})

watch(
  () => open.value,
  async (val) => {
    if (val) {
      currentFocusLabelIndex.value = -1
      extend(variables, {
        parentId: props.parentId,
      })
      if (!isCurrentBoard.value) {
        await load()
        return nextTick(() => {
          popoverRef.value.popperInstance?.forceUpdate()
        })
      }

      setLabelWithPositions()
    }

    selectedLabel.value = null
    showCreateForm.value = false
    setLabelWithPositions()
  }
)

watch(
  () => boardData.value.labels,
  () => {
    if (open.value) {
      setLabelWithPositions()
    }
  }
)
</script>
