<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <TaskModalView v-if="currentTask.viewMode === TaskViewMode.MODAL" />
    <TaskSidebarView v-if="currentTask.viewMode === TaskViewMode.SIDEBAR" />
    <USlideover
      v-if="currentPage || currentBoard.id"
      v-model="isOpen"
      :prevent-close="preventClose"
      :overlay="false"
      :ui="{
        width: currentPage === 'tasks' ? 'max-w-2xl' : 'max-w-md',
        base: 'mt-[60px] border rounded-none',
        ring: '!h-auto',
        translate: {
          right: 'translate-x-0',
        },
      }"
      @close="closeSettings"
    >
      <component
        :is="BoardSettingComponent[currentPage as keyof typeof BoardSettingComponent]"
        :workspace-id="currentWorkspace.id"
        :parent-id="currentBoard.id || currentSettingsPack.id"
        :source="currentBoard.id ? 'board' : 'settings-pack'"
      />
    </USlideover>
    <UNotifications>
      <template #title="{ title }">
        <span class="text-sm" data-test="toast-title" v-html="title" />
      </template>

      <template #description="{ description }">
        <span
          class="text-sm"
          data-test="toast-description"
          v-html="description"
        />
      </template>
    </UNotifications>
    <UModals />
  </div>
</template>

<script setup lang="ts">
import { TaskViewMode } from '#task/constant'

const description = 'Leanbase'

const toast = useToast()
const route = useRoute()
const router = useRouter()
const { isOnline } = useNetwork()
await useWorkspaceStateUpdater()

const { currentTask, currentBoard, currentWorkspace, currentSettingsPack } =
  useWorkspaceSharedState()
const { isOpen, preventClose, currentPage } = useBoardSettingsNavigator()

const BoardSettingComponent = {
  index: defineAsyncComponent(
    () => import('#board/components/BoardSettings.vue')
  ),
  tasks: defineAsyncComponent(
    () => import('#task-type/components/TaskTypeSettingsList.vue')
  ),
  statuses: defineAsyncComponent(
    () => import('#status/components/StatusSettingsList.vue')
  ),
  labels: defineAsyncComponent(
    () => import('#label/components/LabelSettingList.vue')
  ),
  fieldForm: defineAsyncComponent(
    () => import('#field/components/FieldFormSettings.vue')
  ),
  settingsPacks: defineAsyncComponent(
    () => import('#settings-pack/components/SettingsPackSettingList.vue')
  ),
  settingsPack: defineAsyncComponent(
    () => import('#settings-pack/components/SettingsPackSettingModify.vue')
  ),
  settingsPackPreview: defineAsyncComponent(
    () => import('#settings-pack/components/SettingsPackSettingPreview.vue')
  ),
}

const closeSettings = () => {
  const { query: currentQuery } = route
  const { fieldId, id, ...cleanQuery } = currentQuery

  router.replace({ query: cleanQuery })
}

useHead({
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  ],
  link: [{ rel: 'icon', href: '/favicon.png' }],
  htmlAttrs: {
    lang: 'en',
  },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Leanbase` : 'Leanbase'
  },
})

useSeoMeta({
  description,
  ogDescription: description,
  ogImage: 'https://dashboard-template.nuxt.dev/social-card.png',
  twitterImage: 'https://dashboard-template.nuxt.dev/social-card.png',
  twitterCard: 'summary_large_image',
})

defineShortcuts({
  escape: {
    whenever: [isOpen],
    handler: () => {
      isOpen.value = false
      closeSettings()
    },
  },
})

watch(
  () => isOnline.value,
  (online) => {
    if (online) {
      return toast.add({
        color: 'green',
        title: 'Your\'re back online.',
      })
    }

    toast.add({
      color: 'red',
      title: 'You are offline.',
      description: 'Changes made now will not be saved.',
    })
  }
)
</script>
