import type { WatchHandle } from 'vue'
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import { dropTargetForExternal } from '@atlaskit/pragmatic-drag-and-drop/external/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import type { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import type { ElementState, TargetElement } from '#core/types'
import type {
  TaskItem,
  TaskDraggableData,
  TaskDraggableModifier,
} from '#task/types'
import { TaskLevel } from '#task/constant'

export const useTaskAsDropTarget = (params: {
  item: TaskItem | Ref<TaskItem>
  targetElement?: TargetElement
  /**
   * The wrapper element is used to create a drop target for the entire task item
   * for handle case update task parent
   */
  wrapperTargetElement?: TargetElement
  dragHandleTarget?: TargetElement
  modifiers?: TaskDraggableModifier[]
}) => {
  const { currentBoard } = useWorkspaceSharedState()
  const { currentDraggingTask } = useCurrentDraggingTask()
  const { updateTaskPosition } = useTaskMove()

  const isDraggingOver = ref(false)
  const cleanup = ref<CleanupFn>()
  const state = ref<ElementState>({
    type: 'idle',
  })
  const closestEdge = ref<Edge | null>(null)
  const canDrop = ref<null | boolean>(null)
  const watcher = ref<WatchHandle>()

  const createDraggable = () => {
    const {
      item,
      targetElement,
      wrapperTargetElement,
      dragHandleTarget,
      modifiers,
    } = params

    const taskItem = unref(item)
    let element = undefined
    let dragHandleElement = undefined
    let wrapperElement = undefined
    if (targetElement) {
      element = unrefElement(evaluativeFn(targetElement))
    }

    if (dragHandleTarget) {
      dragHandleElement = unrefElement(evaluativeFn(dragHandleTarget))
    }

    if (wrapperTargetElement) {
      wrapperElement = unrefElement(evaluativeFn(wrapperTargetElement))
    }

    const computeCanDrop = (
      item: TaskDraggableData,
      destinationItem: TaskDraggableData
    ) => {
      /**
       * Prevent dragging a task into its own subtasks
       */
      if (
        item.taskId === destinationItem.parentId &&
        modifiers?.includes('parent')
      ) {
        return false
      }

      /**
       * Prevent dragging a module task into its own subtasks or a nearby a task that is not a module
       */
      if (
        item.level === TaskLevel.MODULE &&
        destinationItem.parentId &&
        modifiers?.includes('parent')
      ) {
        return false
      }

      /**
       * Prevent dragging a subtask same level with task or module
       */
      if (
        item.level === TaskLevel.SUBTASK &&
        destinationItem.level !== TaskLevel.SUBTASK
      ) {
        return false
      }

      if (
        item.level === TaskLevel.TASK &&
        destinationItem.level === TaskLevel.SUBTASK
      ) {
        return false
      }

      return true
    }

    const resetState = () => {
      canDrop.value = null
      closestEdge.value = null
      currentDraggingTask.closestEdge = closestEdge.value
      currentDraggingTask.item = {}
    }

    const draggableFunctions = []
    if (element) {
      draggableFunctions.push(
        ...[
          draggable({
            element,
            dragHandle: dragHandleElement,
            getInitialData: (): TaskDraggableData => ({
              type: 'task',
              taskId: taskItem.id,
              sectionId: taskItem.sectionId,
              position: taskItem.position,
              level: taskItem.level,
              parentId: taskItem.parentId,
            }),
            onGenerateDragPreview: ({
              location,
              source,
              nativeSetDragImage,
            }) => {
              const rect = source.element.getBoundingClientRect()
              currentDraggingTask.rect = rect
              setCustomNativeDragPreview({
                nativeSetDragImage,
                getOffset: () => {
                  const offsetX = Math.min(
                    location.current.input.clientX - rect.x,
                    rect.width
                  )
                  const offsetY = Math.min(
                    location.current.input.clientY - rect.y,
                    rect.height
                  )
                  return { x: offsetX, y: offsetY }
                },
                render({ container }) {
                  state.value = {
                    type: 'preview',
                    container,
                    rect,
                  }
                  return () => {
                    state.value = {
                      type: 'dragging',
                    }
                  }
                },
              })
            },
            onDragStart: () => {
              state.value = {
                type: 'dragging',
              }
              addChromiumDragAndDropWorkaround()
            },
            onDrop: () => {
              currentDraggingTask.rect = undefined
              state.value = {
                type: 'idle',
              }
              clearChromiumDragAndDropWorkaround()
              unblockPointerEventsOnEverything()
            },
          }),
          dropTargetForExternal({
            element,
          }),
          dropTargetForElements({
            element,
            canDrop: (args) => args.source.data.type === 'task',
            getIsSticky: () => true,
            getData: ({ input, element }) => {
              const data = {
                type: 'task',
                taskId: taskItem.id,
                sectionId: taskItem.sectionId,
                position: taskItem.position,
                level: taskItem.level,
                parentId: taskItem.parentId,
              }
              if (!currentDraggingTask.closestEdge) {
                currentDraggingTask.closestEdge = 'none'
              }

              return attachClosestEdge(data, {
                input,
                element,
                allowedEdges: ['top', 'bottom'],
              })
            },
            onDragEnter: (args) => {
              const item = args.source.data
              if (item?.taskId !== taskItem.id) {
                closestEdge.value = extractClosestEdge(args.self.data)
                currentDraggingTask.closestEdge = closestEdge.value
              }
            },
            onDrag: (args) => {
              const item = args.source.data
              currentDraggingTask.item = item
              canDrop.value = computeCanDrop(
                item as TaskDraggableData,
                args.self.data as TaskDraggableData
              )
              if (item?.taskId !== taskItem.id) {
                closestEdge.value = extractClosestEdge(args.self.data)
                currentDraggingTask.closestEdge = closestEdge.value
              }
            },
            onDragLeave: () => {
              closestEdge.value = null
              currentDraggingTask.closestEdge = closestEdge.value
            },
            onDrop: ({ source, self }) => {
              const item = source.data as TaskDraggableData
              const destinationItem = self.data as TaskDraggableData
              if (!canDrop.value) {
                return resetState()
              }

              const allowChangeParentLevels = [TaskLevel.SUBTASK]
              const canChangeParent =
                allowChangeParentLevels.includes(item.level) &&
                destinationItem.level === item.level
              /**
               * Sometimes when dragging and dropping tasks, we only want to update a specific field to avoid affecting the logic's correctness.
               * For example, when the user groups by module, we only need to change the parentId instead of the entire list of IDs that the task interacts with.
               * If the modifiers array is empty, the function will only update the position.
               */
              const modifyFields = {
                sectionId: item.sectionId,
                parentId: item.parentId,
              }
              if (modifiers?.includes('section')) {
                modifyFields.sectionId = destinationItem.sectionId
              }

              if (modifiers?.includes('parent') || canChangeParent) {
                modifyFields.parentId = destinationItem.parentId
              }

              resetState()
              const currClosestEdge = extractClosestEdge(destinationItem)
              const tasks = useReadTasksFromCache(currentBoard.value.id)
              let indexOfTarget = tasks.findIndex(
                (task) => task.id === destinationItem.taskId
              )
              if (indexOfTarget === -1) {
                indexOfTarget = tasks.length
              }

              const startIndex = tasks.findIndex(
                (task) => task.id === item.taskId
              )
              const destinationIndex = getReorderDestinationIndex({
                startIndex,
                indexOfTarget,
                closestEdgeOfTarget: currClosestEdge,
                axis: 'vertical',
              })
              updateTaskPosition({
                taskIds: [item.taskId] as string[],
                sectionId: modifyFields.sectionId,
                index: destinationIndex,
                parentId: modifyFields.parentId,
              })
            },
          }),
        ]
      )
    }

    if (wrapperElement) {
      draggableFunctions.push(
        dropTargetForElements({
          element: wrapperElement,
          canDrop: ({ source }) => source.data.type === 'task',
          getIsSticky: () => true,
          getData: () => ({ taskId: taskItem.id }),
          onDragEnter: () => {
            isDraggingOver.value = true
          },
          onDragLeave: () => {
            isDraggingOver.value = false
          },
          onDragStart: () => {
            isDraggingOver.value = true
          },
          onDrop: ({ self, location, source }) => {
            isDraggingOver.value = false
            if (location.current.dropTargets.length > 1) {
              return
            }

            currentDraggingTask.closestEdge = null
            currentDraggingTask.item = {}
            updateTaskPosition({
              taskIds: [source.data.taskId] as string[],
              sectionId: source.data.sectionId as string,
              index: 1e9,
              parentId: self.data.taskId as string,
            })
          },
        })
      )
    }

    cleanup.value = combine(...draggableFunctions)
  }

  /**
   * @issue Can not drag task into module task was created by optimistic response
   * Watch for item id change to re-create draggable
   */
  if (isRef(params.item)) {
    const item = params.item as Ref<TaskItem>
    watcher.value = watch(
      () => item.value.id,
      (taskId) => {
        logger.log(
          '[Task DnD] Item id changed, re-create draggable for',
          taskId
        )
        cleanup.value?.()
        createDraggable()
      }
    )
  }

  onMounted(() => {
    createDraggable()
  })

  onBeforeUnmount(() => {
    cleanup.value?.()
    watcher.value?.()
  })

  return {
    canDrop,
    state,
    closestEdge,
    isDraggingOver,
  }
}
