import { MIN_TASK_FRAGMENT } from '#task/fragment'
import { SECTION_FRAGMENT } from '#section/fragment'
import { MIN_USER_FRAGMENT } from '#auth/fragment'

export const REACTION_FRAGMENT = gql`
  ${MIN_USER_FRAGMENT}
  fragment Reaction on ReactionType {
    id
    creator{
      ...MinUser
    }
    emoji
  }
`
export const COMMENT_FRAGMENT = gql`
  ${REACTION_FRAGMENT}
  fragment Comment on CommentType {
    created
    modified
    id
    content
    reactions {
      ...Reaction
    }
  }
`
export const ACTION_FRAGMENT = gql`
  ${MIN_USER_FRAGMENT}
  ${SECTION_FRAGMENT}
  ${COMMENT_FRAGMENT}
  ${MIN_TASK_FRAGMENT}
  fragment Action on ActionType {
    id
    created
    modified
    id
    data
    type
    task {
      ...MinTask
    }
    section {
      ...Section
    }
    creator {
      ...MinUser
    }
    comment {
      ...Comment
    }
  }
`
