import type { ShortcutKeyHandler, ShortcutKeyOption } from '#core/types'

export const useShortcutKeys = (handlers: ShortcutKeyHandler[], option?: ShortcutKeyOption) => {
  let isOutside = null
  let stopFunc = () => {}

  const target = option?.target
  if (target) {
    const { isOutside: isMouseOutside, stop } = useMouseInElement(target)
    stopFunc = stop
    isOutside = computed(() => isMouseOutside.value)
  }

  handlers.forEach((handler) => {
    const { shortcutKeyEvent, handle } = handler as ShortcutKeyHandler
    const bus = useEventBus(shortcutKeyEvent)
    const busListener = () => {
      if (target && isOutside?.value) {
        return
      }

      handle()
    }

    onMounted(() => {
      bus.on(busListener)
    })

    onUnmounted(() => {
      stopFunc()
      bus.off(busListener)
    })
  })
}
