<template>
  <UPopover
    v-model:open="open"
    :ui="{ width: 'w-64' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
    v-bind="$attrs"
  >
    <template #panel>
      <div class="py-3 px-4">
        <div class="flex items-center justify-between">
          <p class="font-semibold text-sm">Move task</p>
          <UButton
            size="xs"
            icon="i-heroicons-x-mark"
            color="gray"
            variant="ghost"
            @click.stop.prevent="open = false"
          />
        </div>
        <div class="space-y-3 mt-3">
          <TaskMoveForm
            v-if="open"
            :task="tasks[0]"
            :ui-menu="uiMenu"
            :workspace-id="workspaceId"
          >
            <template #footer="{ state }">
              <div class="flex justify-end mt-4 gap-2">
                <UButton
                  color="gray"
                  variant="soft"
                  @click.stop.prevent="open = false"
                >
                  Cancel
                </UButton>
                <UButton
                  size="sm"
                  :disabled="!state.canMove"
                  :loading="isMoving"
                  data-test="task-move-submit-button"
                  @click.stop.prevent="onMoveTask(state)"
                >
                  Move
                </UButton>
              </div>
            </template>
          </TaskMoveForm>
        </div>
      </div>
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'

const props = defineProps({
  tasks: {
    type: Array as PropType<
      Pick<TaskItem, 'id' | 'position' | 'board' | 'section' | 'boardId' | 'sectionId' | 'parentId'>[]
    >,
    required: true,
  },
  workspaceId: {
    type: String,
    required: true,
  },
  uiMenu: {
    type: Object as PropType<{ container: string }>,
  },
})

const emit = defineEmits(['close'])

const toast = useToast()
const { moveTasks } = useTaskMove()
const { currentBoard } = useWorkspaceSharedState()

const open = ref(false)
const isMoving = ref(false)

const onMoveTask = async (state: Record<string, unknown>) => {
  const { tasks } = props

  const isMoveWithinBoard = state.selectedBoard === currentBoard.value.id

  isMoving.value = true

  const taskIds = tasks.map((task) => task.id)
  const data = await moveTasks({
    sectionId: state.selectedSection as string,
    taskIds,
  })

  isMoving.value = false
  if (data?.errors) {
    return toast.add({
      color: 'red',
      title: 'Something went wrong. Please try again.',
    })
  }

  open.value = false
  if (data?.data?.moveTasks.success) {
    const { tasks: newTasks } = data.data.moveTasks
    if (!newTasks.length) {
      return toast.add({
        color: 'red',
        title: 'Something went wrong, no task moved. Please try again.',
      })
    }

    const newTask = newTasks[0]

    if (newTasks.length === 1) {
      return toast.add({
        icon: 'i-heroicons-check-circle',
        color: 'green',
        title: `Move task "${newTask.name}" to <b>${state.selectedSectionName}</b> ${
          isMoveWithinBoard ? '' : `in <b>${state.selectedBoardName}</b>`
        }`,
      })
    } else {
      const leftOver = newTasks.length === 2 ? 'task' : 'tasks'
      toast.add({
        icon: 'i-heroicons-check-circle',
        color: 'green',
        title: `Move "${newTask.name}" and ${
          newTasks.length - 1
        } other ${leftOver} to <b>${state.selectedSectionName}</b> ${
          isMoveWithinBoard ? '' : `in <b>${state.selectedBoardName}</b>`
        }`,
      })
    }
  }

  sleep(200).then(() => emit('close'))
}

defineExpose({ open: () => (open.value = true) })
</script>
