<template>
  <div
    v-if="closestEdge === 'top'"
    class="w-full border-t border-primary-500"
  />
  <div class="relative group">
    <!-- More actions -->
    <div class="absolute left-full top-0 bottom-0 z-10 px-1 pt-2 has-[[data-popper-placement]]:z-20">
      <TaskActions
        ref="taskActionsRef"
        :task="subtask"
        :button-props="{
          class: 'hover:bg-gray-100',
        }"
        active-class="bg-gray-100"
        in-active-class="group-hover:opacity-100 opacity-0"
      />
    </div>
    <div
      ref="rowRef"
      class="flex items-baseline justify-between px-1.5 py-2 group-hover:bg-gray-100 cursor-pointer"
      :class="{
        'bg-gray-100': isEditing,
        'cursor-not-allowed': !can('dashboard.data.managing_tasks_sections'),
      }"
      :draggable="can('dashboard.data.managing_tasks_sections')"
      data-test="subtask-row-btn"
      @click="onOpenTask"
    >
      <div class="flex items-baseline gap-1 flex-1">
        <div
          class="absolute right-full top-0 bottom-0 z-10 px-1.5 pt-3"
          @click.prevent.stop
        >
          <UButton
            ref="dragHandleRef"
            class="size-3 p-0 items-center justify-center"
            variant="ghost"
            size="sm"
            color="gray"
            :disabled="!can('dashboard.data.managing_tasks_sections')"
          >
            <IconDrag
              class="hidden group-hover:flex"
            />
          </UButton>
        </div>
        <div class="mr-0.5">
          <template v-if="subtask.level === TaskLevel.TASK">
            <TaskTypeSelectPopover
              v-if="subtask.type"
              source="board"
              :parent-id="subtask.boardId"
              :selected-task-type-id="subtask.type.id"
              :accept-levels="[subtask.level]"
              :disabled="!can('dashboard.data.managing_tasks_sections')"

              @click.prevent.stop
              @change="updateTask(subtask.id, { type: $event.id })"
            >
              <div
                class="p-1 bg-white border border-white group-hover:border-gray-300 rounded-md hover:!border-gray-400"
              >
                <TaskTypeIcon
                  :background="subtask.type.background"
                  :icon="subtask.type.icon"
                  size="xs"
                  :class="
                    {
                      'cursor-not-allowed': !can('dashboard.data.managing_tasks_sections'),
                    }"
                />
              </div>
            </TaskTypeSelectPopover>
          </template>
          <template v-else>
            <div class="rounded-md p-1">
              <TaskTypeIcon
                :background="subtask.type.background"
                :icon="subtask.type.icon"
                size="xs"
              />
            </div>
          </template>
        </div>
        <p class="text-xs text-gray-500 relative -top-[1px]">
          #{{ subtask.number }}
        </p>
        <div class="relative ml-0.5">
          <p
            class="h-full break-words px-2 py-0.5 min-h-4 font-medium text-sm tracking-normal leading-5 whitespace-pre-wrap"
            :style="{ wordBreak: 'break-word' }"
          >
            {{ formState.name || 'Write a task name' }}
          </p>

          <UTextarea
            ref="taskNameRef"
            v-model="formState.name"
            placeholder="Write a task name"
            autoresize
            :ui="{
              wrapper: 'absolute inset-0',
              color: {
                white: {
                  outline:
                    'shadow-none text-gray-700 bg-white ring-0 focus:!bg-white group-hover:ring-1 focus:!ring-2 rounded-sm',
                },
              },
              rounded: 'rounded-none',
              base: 'resize-none w-full outline-none focus:!ring-primary-500  group-hover:ring-gray-300 overflow-hidden',
              padding: {
                sm: 'px-2 py-0.5',
              },
              placeholder: 'font-normal',
            }"
            textarea-class="font-medium text-sm tracking-normal leading-5 min-h-4 h-full"
            data-test="subtask-name-input"
            :disabled="!can('dashboard.data.managing_tasks_sections')"
            @click.prevent.stop
            @focus="isEditing = true"
            @blur="onUpdateSubtaskName"
            @keydown.enter.prevent.stop="$event.target?.blur()"
          />
        </div>
      </div>
      <div
        class="flex items-start gap-1 ml-2 flex-wrap max-w-48 justify-end relative"
        data-test="task-row-data"
        @click.prevent.stop
      >
        <div class="absolute inset-0" @click.prevent.stop="onOpenTask" />
        <TaskDatePickerPopover
          :start-date="subtask.startDate"
          :due-date="subtask.dueDate"
          :disabled="!can('dashboard.data.managing_tasks_sections')"
          @change="(dates) => updateTask(subtask.id, dates)"
        >
          <UButton
            size="2xs"
            variant="soft"
            color="gray"
            :class="{
              'px-1 invisible group-hover:visible relative top-[1px] duration-0':
                !subtask.startDate && !subtask.dueDate,
              visible: !!subtask.assignee,
            }"
            :disabled="!can('dashboard.data.managing_tasks_sections')"
            :ui="{
              rounded: 'rounded-full',
            }"
          >
            <TaskDatesFormat
              v-if="subtask.startDate || subtask.dueDate"
              :class="[
                'text-gray-900',
                {
                  'cursor-not-allowed': !can('dashboard.data.managing_tasks_sections'),
                },
              ]"
              :start="subtask.startDate"
              :end="subtask.dueDate"
              :show-time="false"
              :format="{
                month: 'MMM',
              }"
            />
            <Icon v-else name="i-heroicons-calendar" :size="16" />
          </UButton>
        </TaskDatePickerPopover>
        <TaskAssigneePopover
          :current-assignee="subtask.assignee"
          :board-id="subtask.boardId"
          :disabled="!can('dashboard.data.managing_tasks_sections')"
          @select="(assignee) => updateTask(subtask.id, { assignee })"
          @remove="updateTask(subtask.id, { assignee: null })"
        >
          <UButton
            v-if="subtask.assignee"
            size="2xs"
            variant="soft"
            color="gray"
            :padded="false"
            :ui="{
              rounded: 'rounded-full',
            }"
            :disabled="!can('dashboard.data.managing_tasks_sections')"
          >
            <template #leading>
              <Avatar
                :id="subtask.assignee.id"
                :src="subtask.assignee.photo"
                :name="subtask.assignee.fullName"
                img-class="hover:brightness-90"
                class="hover:brightness-90"
                :ui-size="{
                  xs: 'size-6',
                }"
              />
            </template>
          </UButton>
          <UButton
            v-else
            class="group-hover:visible relative top-[1px] !p-1 duration-0"
            :class="{
              invisible: !subtask.startDate && !subtask.dueDate,
            }"
            :disabled="!can('dashboard.data.managing_tasks_sections')"
            size="xs"
            variant="soft"
            color="gray"
            icon="i-heroicons-user-circle"
            :ui="{
              rounded: 'rounded-full',
            }"
          />
        </TaskAssigneePopover>
        <StatusSelectPopover
          v-if="subtask.status"
          :selected-status-id="subtask.status.id"
          :parent-id="subtask.boardId"
          source="board"
          :disabled="!can('dashboard.data.managing_tasks_sections')"
          @change="updateTask(subtask.id, { status: $event })"
        >
          <StatusBadge
            v-if="subtask.status"
            :class="{
              'cursor-not-allowed': !can('dashboard.data.managing_tasks_sections'),
            }"
            class="flex items-center gap-1 text-xs max-w-24"
            :type="subtask.status.type"
            :label="subtask.status.name"
          />
        </StatusSelectPopover>
      </div>
    </div>
  </div>
  <div
    v-if="closestEdge === 'bottom'"
    class="w-full border-b border-primary-500"
  />
  <Teleport v-if="state.type === 'preview'" :to="state.container">
    <div
      class="box-border relative bg-white border border-gray-200 rounded-md shadow-2xl h-8 px-2 truncate text-xs leading-8 -rotate-2"
    >
      {{ subtask.name }}
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { TaskLevel } from '#task/constant'
import type { TaskItemLoader } from '#task/types'

const props = defineProps({
  subtask: {
    type: Object as PropType<TaskItemLoader>,
    required: true,
  },
})

const { updateTask } = useUpdateTask()
const { setCurrentTask } = useWorkspaceSharedState()
const { can } = useBoardAbility()

const rowRef = ref()
const dragHandleRef = ref()
const taskNameRef = ref()
const isEditing = ref(false)
const formState = reactive({
  name: props.subtask.name,
})

onClickOutside(rowRef, () => {
  if (isEditing.value) {
    isEditing.value = false
  }
})

const { state, closestEdge } = useTaskAsDropTarget({
  targetElement: rowRef,
  dragHandleTarget: dragHandleRef,
  item: props.subtask,
  modifiers: [],
})

const onUpdateSubtaskName = async (event?: Event) => {
  event?.preventDefault()
  if (!formState.name) {
    formState.name = props.subtask.name
  }

  if (formState.name !== props.subtask.name) {
    await updateTask(props.subtask.id, { name: formState.name })
  }

  taskNameRef.value.$el.querySelector('textarea')?.blur()
}

const onOpenTask = () => {
  if (isEditing.value) {
    isEditing.value = false
    return
  }

  const prefix = /^(.*)\/t\/.+$/.exec(window.location.pathname)?.[1]

  updateTaskUrl(props.subtask, prefix)
  setCurrentTask(props.subtask)
}

watch(
  () => props.subtask.name,
  (name) => {
    formState.name = name
  }
)
</script>
