/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/

import { LABEL_FRAGMENT } from '#label/fragment'
import { STATUS_FRAGMENT } from '#status/fragment'
import { TASK_TYPE_FRAGMENT } from '#task-type/fragment'
import { TASK_FRAGMENT } from '#task/fragment'
import { CUSTOM_FIELD_LOADER_FRAGMENT } from '#field/loader_fragment'
import { USER_LOADER_FRAGMENT } from '#auth/loader_fragment'

export const TASK_LOADER_FRAGMENT = gql`
  ${TASK_FRAGMENT}
  ${LABEL_FRAGMENT}
  ${STATUS_FRAGMENT}
  ${TASK_TYPE_FRAGMENT}
  ${CUSTOM_FIELD_LOADER_FRAGMENT}
  ${USER_LOADER_FRAGMENT}
  fragment TaskLoader on TaskType {
    ...Task
    startDate
    dueDate
    subtasksCount
    lastActivity
    labelIds
    labels @client {
      ...Label
    }
    type @client {
      ...TaskType
    }
    status @client {
      ...Status
    }
    assignee @client {
      ...UserLoader
    }
    parent @client {
      id
      name
      number
      handle
      sectionId
      type {
        id
        name
        icon
      }
    }
    taskFields {
      ...CustomFieldLoader
    }
    parentClosed
    sectionClosed
  }
`

// Sync with task/sync.py > TaskSync
export const TASK_SYNC_FRAGMENT = gql`
  ${TASK_LOADER_FRAGMENT}
  fragment TaskSync on TaskType {
    ...TaskLoader
    description
  }
`
