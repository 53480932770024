export enum TaskLevel {
  MODULE = 1,
  TASK = 2,
  SUBTASK = 3,
}

export enum TaskViewMode {
  MODAL = 'modal',
  SIDEBAR = 'sidebar',
}

export enum ArchiveModalType {
  MODULE = 'module',
  MODULES = 'modules',
  SECTION = 'section',
}

export enum DueDateType {
  NO_DATE = 'no_date',
  OVERDUE = 'overdue',
  TODAY = 'today',
  DUE_NEXT_DAY = 'due_next_day',
  DUE_NEXT_WEEK = 'due_next_week',
  DUE_NEXT_MONTH = 'due_next_month',
}

export const DuedateOptions = [
  {
    key: DueDateType.NO_DATE,
    label: 'No date',
    wrapperClass: 'bg-gray-100',
    iconClass: 'text-gray-500',
  },
  {
    key: DueDateType.OVERDUE,
    label: 'Overdue',
    wrapperClass: 'bg-red-50',
    iconClass: 'text-red-500',
  },
  {
    key: DueDateType.TODAY,
    label: 'Today',
    wrapperClass: 'bg-green-50',
    iconClass: 'text-green-500',
  },
  {
    key: DueDateType.DUE_NEXT_DAY,
    label: 'Due in the next day',
    wrapperClass: 'bg-gray-100',
    iconClass: 'text-gray-500',
  },
  {
    key: DueDateType.DUE_NEXT_WEEK,
    label: 'Due in the next week',
    wrapperClass: 'bg-gray-100',
    iconClass: 'text-gray-500',
  },
  {
    key: DueDateType.DUE_NEXT_MONTH,
    label: 'Due in the next month',
    wrapperClass: 'bg-gray-100',
    iconClass: 'text-gray-500',
  },
]

export enum ActivityType {
  LAST_WEEK = 'last_week',
  LAST_TWO_WEEKS = 'last_two_weeks',
  LAST_FOUR_WEEKS = 'last_four_weeks',
  WITHOUT_LAST_FOUR_WEEKS = 'without_last_four_weeks',
}

export enum DateFieldFilter {
  TODAY = 'today',
  NEXT_DAY = 'next_day',
  NEXT_WEEK = 'next_week',
  NEXT_MONTH = 'next_month',
}

export const DateFieldFilterOptions = [
  {
    key: DateFieldFilter.TODAY,
    label: 'Today',
  },
  {
    key: DateFieldFilter.NEXT_DAY,
    label: 'Next day',
  },
  {
    key: DateFieldFilter.NEXT_WEEK,
    label: 'Next week',
  },
  {
    key: DateFieldFilter.NEXT_MONTH,
    label: 'Next month',
  },
]

export const ActivityOptions = [
  {
    key: ActivityType.LAST_WEEK,
    label: 'Active in the last week',
  },
  {
    key: ActivityType.LAST_TWO_WEEKS,
    label: 'Active in the last two weeks',
  },
  {
    key: ActivityType.LAST_FOUR_WEEKS,
    label: 'Active in the last four weeks',
  },
  {
    key: ActivityType.WITHOUT_LAST_FOUR_WEEKS,
    label: 'Without activity in the last four weeks',
  },
]

export const TaskAttachmentAcceptMimeTypes = [
  'image/*, application/pdf, .zip, .doc, .docx, .xls, .xlsx, .ppt, .pptx',
]

export enum TaskDependencyType {
  FINISH_TO_START = 1,
  FINISH_TO_FINISH = 2,
  START_TO_START = 3,
  START_TO_FINISH = 4,
}

export const TaskLevelTimelineColor = {
  [TaskLevel.MODULE]: 'bg-violet-300 border-violet-600 text-violet-600',
  [TaskLevel.TASK]: 'bg-blue-400 border-blue-600 text-blue-600',
  [TaskLevel.SUBTASK]: 'bg-sky-300 border-sky-600 text-sky-600',
}

export enum TaskFilterCriteria {
  SEARCH = 'search',
  INCLUDE_MEMBER = 'member',
  EXCLUDE_MEMBER = '_member',
  DUE_DATE = 'due_date',
  ACTIVITY = 'activity',
  INCLUDE_LABEL = 'label',
  EXCLUDE_LABEL = '_label',
  INCLUDE_STATUS = 'status',
  EXCLUDE_STATUS = '_status',
  INCLUDE_MODULE = 'module',
  INCLUDE_FIELD = 'field',
  EXCLUDE_FIELD = '_field',
  INCLUDE_TASK_TYPE = 'type',
}

export const TASK_TYPE_NAME = 'TaskType'

export enum TaskDependencyBlockType {
  BLOCKING = 1,
  BLOCKED_BY = 2,
}
