<template>
  <UTooltip
    :popper="{ placement: 'top', arrow: true }"
    :ui="{
      base: 'h-fit text-wrap max-w-64 break-words',
      background: 'bg-white',
      arrow: {
        class: arrowClass,
      },
    }"
    v-bind="$attrs"
    :prevent="!isShowTooltip || prevent"
  >
    <template #text>
      <slot v-if="$slots.text" name="text" />
      <p v-else class="text-xs font-medium text-gray-900">
        {{ text }}
      </p>
      <slot v-if="$slots.description" name="description" />
      <p v-else class="text-xs text-gray-500">
        {{ description }}
      </p>
    </template>
    <slot :get-text-ref="getTextRef" />
  </UTooltip>
</template>

<script lang="ts" setup>
const props = defineProps({
  prevent: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
  },
  description: {
    type: String,
  },
  arrowClass: {
    type: String,
  },
  compareDimenssions: {
    type: Array as PropType<Array<'width' | 'height'>>,
    default: () => ['height'],
  },
})

const isShowTooltip = ref(true)

const getTextRef = (ref: Element | ComponentPublicInstance | null) => {
  const { compareDimenssions } = props
  /**
   * Check if the text has overflowed the container.
   * If the text element is provided, calculate if the scroll height is greater than the client height.
   * This is used to determine if the tooltip should be shown.
   */
  if (ref) {
    const { scrollHeight, clientHeight, scrollWidth, clientWidth } =
      ref as HTMLDivElement
    const isOverHeight = scrollHeight > clientHeight
    const isOverWidth = scrollWidth > clientWidth
    const isMatchHeight = compareDimenssions.includes('height') && isOverHeight
    const isMatchWidth = compareDimenssions.includes('width') && isOverWidth
    isShowTooltip.value = isMatchHeight || isMatchWidth
  }
}
</script>
