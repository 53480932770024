<template>
  <div
    v-if="!disableChangeSection"
    class="space-y-3 mt-3"
    @click.prevent.stop
  >
    <div>
      <p class="text-sm text-gray-700 mb-1 text-start">Select board</p>
      <USelectMenu
        v-model="selectedBoard"
        :popper="{ placement: 'bottom-start', strategy: 'fixed' }"
        :options="boards"
        :ui-menu="{ label: 'line-clamp-1 break-all !whitespace-normal', ...uiMenu }"
        option-attribute="name"
        value-attribute="id"
        data-test="board-move-nemu"
      >
        <template #option="{ option: board }">
          <div class="flex items-center gap-2" data-test="task-board-item">
            <div
              :style="{
                backgroundImage: `url(${getBackgroundUrl(
                  board,
                  BackgroundImageQuality.THUMB
                )})`,
              }"
              class="size-5 rounded-md border bg-center bg-cover min-w-5"
            />
            <span class="flex gap-2">
              <div class="line-clamp-1 break-all">{{ board.name }}</div>
              <div v-if="board.id === task.boardId" class="px-2">
                (Current)
              </div>
            </span>
          </div>
        </template>
      </USelectMenu>
    </div>
    <div>
      <p class="text-sm text-gray-700 mb-1 text-start">Section</p>
      <USelectMenu
        v-model="selectedSection"
        :options="sections"
        :popper="{ placement: 'bottom-start', strategy: 'fixed' }"
        :ui-menu="{ label: 'line-clamp-1 break-all !whitespace-normal', ...uiMenu }"
        option-attribute="name"
        value-attribute="id"
        data-test="task-section-menu"
        :disabled="disableChangeSection"
      >
        <template #option="{ option: section }">
          <span class="truncate">{{ section.name }}</span>
          <template v-if="section.id === task.sectionId">
            (Current)
          </template>
        </template>
      </USelectMenu>
    </div>
  </div>
  <slot
    name="footer"
    :state="{
      canMove,
      selectedBoard,
      selectedSection,
      selectedPosition,
      boards,
      selectedBoardName,
      selectedSectionName,
    }"
  />
</template>

<script lang="ts" setup>
import { first, map } from 'lodash-es'
import type { SectionItem } from '#section/types'
import type { CopyMoveTaskItem } from '#task/types'
import { BackgroundImageQuality } from '#board/constant'

const props = defineProps({
  task: {
    type: Object as PropType<CopyMoveTaskItem>,
    required: true,
  },
  workspaceId: {
    type: String,
    required: true,
  },
  disableChangeSection: {
    type: Boolean,
    default: false,
  },
  uiMenu: {
    type: Object as PropType<{
      container: string
    }>,
    default: () => ({}),
  },
})

const { result: workspaceBoardResult, load: loadWorkspaceBoard } =
  useListWorkspaceBoardsLazyQuery({
    workspaceId: props.workspaceId,
    exceptBaseLevels: ['GUEST'],
  })
const {
  result: sectionsResult,
  load: loadSections,
  onResult: onSectionsResult,
} = useSectionsLazyQuery()

const selectedBoard = ref(props.task.boardId)
const selectedSection = ref(props.task.sectionId)

const selectedBoardName = computed(() => {
  const board = boards.value.find((b) => b.id === selectedBoard.value)
  return board?.name
})
const selectedSectionName = computed(() => {
  const section = sections.value.find((s) => s.id === selectedSection.value)
  return section?.name
})

const selectedPosition = ref(1)
const sections = ref<SectionItem[]>([])

const boards = computed(() => workspaceBoardResult.value?.boards ?? [])

const canMove = computed(
  () => selectedBoard.value && selectedSection.value && selectedPosition.value
)

onSectionsResult(() => {
  if (sectionsResult.value) {
    sections.value = sectionsResult.value.sections || []

    const isCurrentSection = map(sections.value, 'id').includes(
      props.task.sectionId
    )
    selectedSection.value = isCurrentSection
      ? props.task.sectionId
      : first(sections.value)?.id || ''
  }
})

watch(
  () => selectedBoard.value,
  async (boardId) => {
    if (boardId) {
      loadSections(null, {
        boardId,
      })
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  loadWorkspaceBoard()
})
</script>
