import revive_payload_client_0qKksyO73H from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ltlK4F7uE5 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_n3lHHrSS79 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_JezYxDeOnZ from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iKaifIA2b4 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ocUXd6EVlI from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_uIm4qIxhAm from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_DP1QgRfBUG from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.3_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_0JqxNDDvKr from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OGDIj2TzZT from "/app/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.24.3_subscriptions-transport-ws@0.9.19__63u3xfepehqmaoihs5otpxwowi/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import slideovers_wFSCvbQSqQ from "/app/node_modules/.pnpm/@nuxt+ui@2.18.7_change-case@5.4.4_magicast@0.3.5_rollup@4.24.3_ts-node@9.1.1_typescript@5.6.3_tuvqs3sx5pkxa7rzexnwsvx2pq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_uxnbH8Tybh from "/app/node_modules/.pnpm/@nuxt+ui@2.18.7_change-case@5.4.4_magicast@0.3.5_rollup@4.24.3_ts-node@9.1.1_typescript@5.6.3_tuvqs3sx5pkxa7rzexnwsvx2pq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_lkLmAIeZU7 from "/app/node_modules/.pnpm/@nuxt+ui@2.18.7_change-case@5.4.4_magicast@0.3.5_rollup@4.24.3_ts-node@9.1.1_typescript@5.6.3_tuvqs3sx5pkxa7rzexnwsvx2pq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_y7HtM8U3uc from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.24.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_NPbGXJaBAj from "/app/node_modules/.pnpm/@nuxt+icon@1.6.1_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.6_sass@1.80.6_ters_svs6g5r63unzrqpzr27u75nyje/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_DW8bFhtUsX from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_change-case@5.4.4_magicast@0.3.5_rollup@4.24.3_ts-node@9.1.1_typescript@5._tcm4zedvgegink3hsizhjp3spi/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import apollo_TUfb6yUCu5 from "/app/layers/core/plugins/apollo.ts";
import app_jEMrYfhH6W from "/app/layers/core/plugins/app.ts";
import hydration_GkVcf8eLEW from "/app/layers/core/plugins/hydration.ts";
import sync_hover_cQ0llqSKSM from "/app/layers/core/plugins/sync-hover.ts";
import sync_scroll_aaOsshRrjw from "/app/layers/core/plugins/sync-scroll.ts";
import presets_n73heCIgvI from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_change-case@5.4.4_magicast@0.3.5_rollup@4.24.3_ts-node@9.1.1_typescript@5._tcm4zedvgegink3hsizhjp3spi/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_J1qeMicm0z from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_change-case@5.4.4_magicast@0.3.5_rollup@4.24.3_ts-node@9.1.1_typescript@5._tcm4zedvgegink3hsizhjp3spi/node_modules/@nuxt/ui-pro/plugins/variables.ts";
export default [
  revive_payload_client_0qKksyO73H,
  unhead_ltlK4F7uE5,
  router_n3lHHrSS79,
  payload_client_JezYxDeOnZ,
  navigation_repaint_client_iKaifIA2b4,
  check_outdated_build_client_ocUXd6EVlI,
  chunk_reload_client_uIm4qIxhAm,
  plugin_vue3_DP1QgRfBUG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0JqxNDDvKr,
  plugin_OGDIj2TzZT,
  slideovers_wFSCvbQSqQ,
  modals_uxnbH8Tybh,
  colors_lkLmAIeZU7,
  plugin_client_y7HtM8U3uc,
  plugin_NPbGXJaBAj,
  scrollbars_client_DW8bFhtUsX,
  apollo_TUfb6yUCu5,
  app_jEMrYfhH6W,
  hydration_GkVcf8eLEW,
  sync_hover_cQ0llqSKSM,
  sync_scroll_aaOsshRrjw,
  presets_n73heCIgvI,
  variables_J1qeMicm0z
]