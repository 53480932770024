export default defineAppConfig({
  ui: {
    primary: 'green',
    gray: 'zinc',
    button: {
      color: {
        gray: {
          ghost:
            'bg-transparent hover:bg-gray-100 dark:bg-gray-900 dark:hover:bg-gray-800',
        },
      },
      variant: {
        soft: 'bg-{color}-100 hover:bg-{color}-200 text-{color}-700',
      },
    },
    notifications: {
      position: 'bottom-0 left-0'
    }
  },
})
