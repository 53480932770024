import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_change-case@5.4.4_magicast@0.3.5_rollup@4.24.3_ts-node@9.1.1_typescript@5._tcm4zedvgegink3hsizhjp3spi/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}