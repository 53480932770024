<template>
  <div class="border boder-gray-50 rounded-md divide-y overflow-hidden">
    <div
      v-for="attachment in attachments"
      :key="attachment.id"
      class="flex items-start justify-between gap-2 px-2"
      data-test="attachment"
    >
      <div class="flex items-center">
        <div
          class="flex items-center justify-center bg-gray-100 min-w-16 w-16 h-10 rounded"
        >
          <template v-if="isImage(attachment)">
            <img
              class="w-full h-full rounded object-contain"
              loading="lazy"
              decoding="async"
              :src="attachment.url"
            />
          </template>
          <template v-else-if="attachment.resolver">
            <img
              class="h-full rounded object-contain w-10"
              loading="lazy"
              decoding="async"
              :src="attachment.resolver.handle"
            />
          </template>
          <div v-else>
            <Icon name="heroicons:document-arrow-up" :size="20" />
          </div>
        </div>
        <div class="py-2.5 px-2 text-xs">
          <a
            :href="attachment.url"
            target="_blank"
            class="text-gray-900 font-medium line-clamp-2 hover:underline break-all"
            rel="noopener noreferrer"
            data-test="url"
          >
            {{ attachment.name || attachment.url }}
          </a>
          <p class="text-gray-500 mt-0.5">
            {{ dateFormat(attachment.created, 'dd MMMM yyyy') }}
          </p>
        </div>
      </div>
      <div class="flex items-center mt-2">
        <Popover
          :open="selectedAttachment?.id === attachment.id"
          :ui="{ width: 'w-60' }"
          :popper="{
            placement: 'bottom',
          }"
          @close="selectedAttachment = null"
        >
          <template #panel>
            <div class="flex items-center justify-between px-4 pt-3">
              <p class="font-semibold">Edit name</p>
              <UButton
                size="xs"
                icon="i-heroicons-x-mark"
                color="gray"
                variant="ghost"
                @click="selectedAttachment = null"
              />
            </div>
            <div class="mt-3 px-4 pb-3">
              <UFormGroup label="Name" name="name">
                <UInput v-model="formState.name" data-test="attachment-url-input" />
              </UFormGroup>
              <div class="flex justify-end gap-2 mt-3">
                <UButton
                  variant="soft"
                  color="gray"
                  size="sm"
                  @click="selectedAttachment = null"
                >
                  Cancel
                </UButton>
                <UButton
                  type="submit"
                  size="sm"
                  :disabled="
                    formState.name === attachment.name || !formState?.name
                  "
                  data-test="attachment-submit"
                  @click="updateAttachment(attachment)"
                >
                  Save
                </UButton>
              </div>
            </div>
          </template>
          <div class="hidden"></div>
        </Popover>
        <UDropdown
          :items="[actionItems(attachment)]"
          :popper="{ placement: 'bottom' }"
        >
          <UButton
            size="xs"
            variant="ghost"
            color="gray"
            trailing-icon="i-heroicons-ellipsis-horizontal"
          />
        </UDropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { TaskAttachmentResolver } from '#task/types'
import { AttachmentType } from '#attachment/constant'
import type { Attachment } from '#attachment/types'

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  taskCover: {
    type: String as PropType<string | null>,
  },
  attachments: {
    type: Array as PropType<TaskAttachmentResolver[]>,
    required: true,
  },
})

const { updateTask, updateTaskAttachment, deleteAttachment } = useUpdateTask()

const selectedAttachment = ref<Attachment | null>(null)
const formState = reactive({
  name: '',
})

const isImage = (attachment: Attachment) => {
  return attachment.mimeType?.startsWith('image')
}

const updateAttachment = async (attachment: Attachment) => {
  await updateTaskAttachment(attachment.id, formState.name)
  selectedAttachment.value = null
}

const actionItems = (attachment: Attachment) => {
  const items = []
  const isAttachmentCover = encodeURI(attachment.url) === props.taskCover
  if (isImage(attachment) && !isAttachmentCover) {
    items.push({
      label: 'Make cover',
      icon: 'i-heroicons-photo',
      click: async () => {
        await updateTask(props.taskId, {
          cover: attachment.url,
          coverBackgroundColor: await getImageAvgColorHex(attachment.url),
        })
        selectedAttachment.value = null
      },
    })
  }

  if (attachment.type === AttachmentType.FILE) {
    items.push({
      label: 'Download',
      icon: 'i-heroicons-arrow-down-tray',
      click: () => downloadFile(attachment.url, attachment.name),
    })
  }

  return [
    {
      label: 'Rename',
      icon: 'i-heroicons-pencil-square',
      click: () => {
        selectedAttachment.value = attachment
        formState.name = attachment.name
      },
    },
    ...items,
    {
      label: 'Delete',
      icon: 'i-heroicons-trash',
      click: async () => {
        selectedAttachment.value = null
        await deleteAttachment(attachment.id)
        if (isAttachmentCover) {
          updateTask(props.taskId, {
            cover: null,
            coverBackgroundColor: null,
          })
        }
      },
    },
  ]
}
</script>
