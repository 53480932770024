<template>
  <div
    v-if="typeof(type) !== 'undefined'"
    :class="[
      wrapperClass,
      !textOnly && 'border p-1 pr-1.5 rounded font-semibold',
      disabled && 'opacity-50 !cursor-not-allowed',
    ]"
    data-test="status"
    @click="onClick"
  >
    <StatusIcon v-if="!textOnly" :type="type" :size="16" class="min-w-4" />
    <template v-if="$slots.label">
      <slot name="label" />
    </template>

    <Tooltip v-else-if="label" :text="label" v-bind="tooltip">
      <template #default="{ getTextRef }">
        <span :ref="getTextRef" class="line-clamp-1 break-all">
          {{ label }}
        </span>
      </template>
    </Tooltip>
  </div>
</template>

<script lang="ts" setup>
import type { STATUS_TYPE } from '#status/constant'
import { STATUS_TYPE_CLASS } from '#status/constant'

const props = defineProps({
  label: {
    type: String,
  },
  type: {
    type: Number as PropType<STATUS_TYPE>,
    required: false,
  },
  textOnly: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: Object as PropType<Record<string, string>>,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const wrapperClass = computed(() => {
  if (typeof (props.type) === 'undefined') return []

  const classes = STATUS_TYPE_CLASS[props.type]
  return Object.values(classes)
})

const onClick = (e: MouseEvent) => {
  if (props.disabled) {
    e.preventDefault()
    e.stopPropagation()
  }
}
</script>
