import { default as indexQL9eny8BOCMeta } from "/app/layers/board/pages/b/[handle]/index.vue?macro=true";
import { default as indexrvAaInGuMbMeta } from "/app/layers/board/pages/b/[handle]/list/index.vue?macro=true";
import { default as indexNHyOh9OCq4Meta } from "/app/layers/board/pages/b/[handle]/timeline/index.vue?macro=true";
import { default as create_45workspacefHZViZjntAMeta } from "/app/layers/workspace/pages/create-workspace.vue?macro=true";
import { default as forgot_45passwordRFjfByNxJsMeta } from "/app/layers/auth/pages/forgot-password.vue?macro=true";
import { default as indexH3dAuxHmU2Meta } from "/app/layers/core/pages/index.vue?macro=true";
import { default as inviteb66PkVo4nmMeta } from "/app/layers/auth/pages/invite.vue?macro=true";
import { default as loginPVUowvVrx7Meta } from "/app/layers/auth/pages/login.vue?macro=true";
import { default as logoutNUiZ4dbVulMeta } from "/app/layers/auth/pages/logout.vue?macro=true";
import { default as reset_45password6uObkviEbbMeta } from "/app/layers/auth/pages/reset-password.vue?macro=true";
import { default as settings_45packs1jyUVtAXRiMeta } from "/app/layers/settings-pack/pages/settings-packs.vue?macro=true";
import { default as signupJSNKW3lFtLMeta } from "/app/layers/auth/pages/signup.vue?macro=true";
import { default as verify_45emailCMZs7ziFVGMeta } from "/app/layers/auth/pages/verify-email.vue?macro=true";
import { default as boardsqlDBaGXKs9Meta } from "/app/layers/workspace/pages/w/[handle]/boards.vue?macro=true";
import { default as indexdJCdXm3hQhMeta } from "/app/layers/workspace/pages/w/[handle]/index.vue?macro=true";
import { default as membersVHllxp6ARnMeta } from "/app/layers/auth/pages/w/[handle]/members.vue?macro=true";
import { default as rolestbD27GHvhFMeta } from "/app/layers/auth/pages/w/[handle]/roles.vue?macro=true";
import { default as settingsPnFBgTmwaaMeta } from "/app/layers/workspace/pages/w/[handle]/settings.vue?macro=true";
import { default as setupq1SNVPXlyhMeta } from "/app/layers/workspace/pages/w/[handle]/setup.vue?macro=true";
import { default as securityjidIJ0c9zxMeta } from "/app/layers/auth/pages/w/[handle]/u/profile/security.vue?macro=true";
import { default as settingsF7LWsXlRdRMeta } from "/app/layers/auth/pages/w/[handle]/u/profile/settings.vue?macro=true";
export default [
  {
    name: "b-handle",
    path: "/b/:handle()",
    meta: indexQL9eny8BOCMeta || {},
    component: () => import("/app/layers/board/pages/b/[handle]/index.vue")
  },
  {
    name: "b-handle-list",
    path: "/b/:handle()/list",
    meta: indexrvAaInGuMbMeta || {},
    component: () => import("/app/layers/board/pages/b/[handle]/list/index.vue")
  },
  {
    name: "b-handle-timeline",
    path: "/b/:handle()/timeline",
    meta: indexNHyOh9OCq4Meta || {},
    component: () => import("/app/layers/board/pages/b/[handle]/timeline/index.vue")
  },
  {
    name: "create-workspace",
    path: "/create-workspace",
    meta: create_45workspacefHZViZjntAMeta || {},
    component: () => import("/app/layers/workspace/pages/create-workspace.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordRFjfByNxJsMeta || {},
    component: () => import("/app/layers/auth/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexH3dAuxHmU2Meta || {},
    component: () => import("/app/layers/core/pages/index.vue")
  },
  {
    name: "invite",
    path: "/invite",
    meta: inviteb66PkVo4nmMeta || {},
    component: () => import("/app/layers/auth/pages/invite.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginPVUowvVrx7Meta || {},
    component: () => import("/app/layers/auth/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutNUiZ4dbVulMeta || {},
    component: () => import("/app/layers/auth/pages/logout.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password6uObkviEbbMeta || {},
    component: () => import("/app/layers/auth/pages/reset-password.vue")
  },
  {
    name: "settings-packs",
    path: "/settings-packs",
    component: () => import("/app/layers/settings-pack/pages/settings-packs.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupJSNKW3lFtLMeta || {},
    component: () => import("/app/layers/auth/pages/signup.vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: verify_45emailCMZs7ziFVGMeta || {},
    component: () => import("/app/layers/auth/pages/verify-email.vue")
  },
  {
    name: "w-handle-boards",
    path: "/w/:handle()/boards",
    meta: boardsqlDBaGXKs9Meta || {},
    component: () => import("/app/layers/workspace/pages/w/[handle]/boards.vue")
  },
  {
    name: "w-handle",
    path: "/w/:handle()",
    component: () => import("/app/layers/workspace/pages/w/[handle]/index.vue")
  },
  {
    name: "w-handle-members",
    path: "/w/:handle()/members",
    meta: membersVHllxp6ARnMeta || {},
    component: () => import("/app/layers/auth/pages/w/[handle]/members.vue")
  },
  {
    name: "w-handle-roles",
    path: "/w/:handle()/roles",
    meta: rolestbD27GHvhFMeta || {},
    component: () => import("/app/layers/auth/pages/w/[handle]/roles.vue")
  },
  {
    name: "w-handle-settings",
    path: "/w/:handle()/settings",
    component: () => import("/app/layers/workspace/pages/w/[handle]/settings.vue")
  },
  {
    name: "w-handle-setup",
    path: "/w/:handle()/setup",
    meta: setupq1SNVPXlyhMeta || {},
    component: () => import("/app/layers/workspace/pages/w/[handle]/setup.vue")
  },
  {
    name: "w-handle-u-profile-security",
    path: "/w/:handle()/u/profile/security",
    meta: securityjidIJ0c9zxMeta || {},
    component: () => import("/app/layers/auth/pages/w/[handle]/u/profile/security.vue")
  },
  {
    name: "w-handle-u-profile-settings",
    path: "/w/:handle()/u/profile/settings",
    meta: settingsF7LWsXlRdRMeta || {},
    component: () => import("/app/layers/auth/pages/w/[handle]/u/profile/settings.vue")
  },
  {
    name: "w-handle",
    path: "/w/:handle",
    meta: {"middleware":["auth"],"layout":"workspace"},
    component: () => import("/app/layers/workspace/pages/w/[handle]/index.vue")
  },
  {
    name: "w-handle-setup",
    path: "/w/:handle/getting-started",
    meta: {"middleware":["auth"],"layout":"blank"},
    component: () => import("/app/layers/workspace/pages/w/[handle]/setup.vue")
  },
  {
    name: "w-handle-boards",
    path: "/w/:handle/boards",
    meta: {"middleware":["auth"],"layout":"workspace"},
    component: () => import("/app/layers/workspace/pages/w/[handle]/boards.vue")
  },
  {
    name: "w-handle-settings",
    path: "/w/:handle/settings",
    meta: {"middleware":["auth"],"layout":"workspace-settings-pack"},
    component: () => import("/app/layers/workspace/pages/w/[handle]/settings.vue")
  },
  {
    name: "t-handle",
    path: "/t/:handle",
    meta: {"taskPopup":true,"taskViewMode":"modal","middleware":["auth"],"layout":"board"},
    component: () => import("/app/layers/board/pages/b/[handle]/index.vue")
  },
  {
    name: indexQL9eny8BOCMeta?.name,
    path: "/t/:handle/:slug",
    meta: {"taskPopup":true,"taskViewMode":"modal","middleware":["auth"],"layout":"board"},
    component: () => import("/app/layers/board/pages/b/[handle]/index.vue")
  },
  {
    name: indexrvAaInGuMbMeta?.name,
    path: "/list/t/:handle",
    meta: {"taskPopup":true,"taskViewMode":"sidebar","middleware":["auth"],"layout":"board"},
    component: () => import("/app/layers/board/pages/b/[handle]/list/index.vue")
  },
  {
    name: indexrvAaInGuMbMeta?.name,
    path: "/list/t/:handle/:slug",
    meta: {"taskPopup":true,"taskViewMode":"sidebar","middleware":["auth"],"layout":"board"},
    component: () => import("/app/layers/board/pages/b/[handle]/list/index.vue")
  },
  {
    name: indexNHyOh9OCq4Meta?.name,
    path: "/timeline/t/:handle",
    meta: {"taskPopup":true,"taskViewMode":"sidebar","middleware":["auth"],"layout":"board"},
    component: () => import("/app/layers/board/pages/b/[handle]/timeline/index.vue")
  },
  {
    name: indexNHyOh9OCq4Meta?.name,
    path: "/timeline/t/:handle/:slug",
    meta: {"taskPopup":true,"taskViewMode":"sidebar","middleware":["auth"],"layout":"board"},
    component: () => import("/app/layers/board/pages/b/[handle]/timeline/index.vue")
  },
  {
    name: "w-handle-settings-packs",
    path: "/w/:handle/settings-packs",
    meta: {"middleware":["auth"],"layout":"workspace-settings-pack"},
    component: () => import("/app/layers/settings-pack/pages/settings-packs.vue")
  },
  {
    name: "w-handle-user-settings",
    path: "/w/:handle/u/profile/settings",
    meta: {"layout":"user","middleware":["auth"]},
    component: () => import("/app/layers/auth/pages/w/[handle]/u/profile/settings.vue")
  },
  {
    name: "w-handle-user-security",
    path: "/w/:handle/u/profile/security",
    meta: {"layout":"user","middleware":["auth"]},
    component: () => import("/app/layers/auth/pages/w/[handle]/u/profile/security.vue")
  },
  {
    name: "w-handle-members",
    path: "/w/:handle/members",
    meta: {"middleware":["auth"],"layout":"workspace-settings-pack"},
    component: () => import("/app/layers/auth/pages/w/[handle]/members.vue")
  },
  {
    name: "w-handle-roles",
    path: "/w/:handle/roles",
    meta: {"middleware":["auth"],"layout":"workspace-settings-pack"},
    component: () => import("/app/layers/auth/pages/w/[handle]/roles.vue")
  }
]