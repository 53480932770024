import {
  format,
  getYear,
  getMonth,
  getDate,
  getHours,
  getMinutes,
  isEqual,
  lastDayOfMonth,
  getDaysInMonth,
  getDay,
  isToday as isDateToday,
  startOfMonth,
  addDays,
  startOfWeek,
  isSunday,
  isSaturday,
  subDays,
  eachDayOfInterval,
} from 'date-fns'
import { toZonedTime as toZonedTimeFns } from 'date-fns-tz'

export const toZonedTime = (date: number | Date | string) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  return toZonedTimeFns(date, tz)
}

export const dateFormat = <T = string>(
  date: number | Date | string | null | undefined,
  formatString: string = 'yyyy-MM-dd'
) => {
  if (!date) {
    return date as T
  }

  return format(toZonedTime(date), formatString) as T
}

export const padZero = (n: number) => (n < 10 ? '0' + n : n)

export const extractDatetime = (dt: Date) => {
  const y = getYear(dt)
  const m = getMonth(dt)
  const d = getDate(dt)
  const hour = getHours(dt)
  const min = getMinutes(dt)

  return { y, m, d, hour, min }
}

export const isDateEqual = (dateLeft: Date, dateRight: Date): boolean =>
  isEqual(dateLeft, dateRight)

export const getLastDateOfMonth = (date: Date) => lastDayOfMonth(date)

export const monthArr = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const dayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const getDayName = (date: Date) => dayArr[getDay(date)]

export const isToday = isDateToday

export const isSunSat = (date: Date) => isSunday(date) || isSaturday(date)

export const getFirstDateOfMonth = (date: Date) => startOfMonth(date)

export const genAWeekOfDate = (date: Date) => {
  const start = startOfWeek(date)
  return Array.from({ length: 7 }, (_, i) => addDays(start, i))
}

const genFirstWeekInMonth = (date: Date) =>
  genAWeekOfDate(getFirstDateOfMonth(date))

export const dateBetween = (startDate: Date, endDate: Date) => {
  return eachDayOfInterval({
    start: startDate,
    end: endDate,
  })
}

export const calculateWeeksRange = (startDate: Date, endDate: Date) => {
  const weeks = []
  let currentEndDate = endDate
  let startOfCurrentWeek

  while (currentEndDate >= startDate) {
    startOfCurrentWeek = startOfWeek(currentEndDate, { weekStartsOn: 1 })
    if (startOfCurrentWeek < startDate) {
      startOfCurrentWeek = startDate
    }

    weeks.push(dateBetween(startOfCurrentWeek, currentEndDate))
    currentEndDate = subDays(startOfCurrentWeek, 1)
  }

  return weeks.reverse()
}

export const genCalendarArr = (date: Date) => {
  const calendars = []
  const month = getMonth(date)
  const lastDateInThisMonth = getDaysInMonth(date)

  const firstWeek = genFirstWeekInMonth(date)
  calendars[0] = firstWeek

  let going = true

  while (going) {
    const lastItem = calendars[calendars.length - 1]
    if (!lastItem) {
      going = false
      break
    }

    const lastDate = lastItem[lastItem.length - 1]
    const ldate = getDate(lastDate)
    const lmonth = getMonth(lastDate)

    if (lmonth > month || ldate === lastDateInThisMonth) {
      going = false
      break
    }

    const nextDate = addDays(lastDate, 1)
    const week = genAWeekOfDate(nextDate)
    calendars.push(week)
  }

  return calendars
}

export const parseTime = (time: string) => {
  const timeRegex = /^(?:[01]?\d|2[0-3])(?::[0-5]?\d)?$/
  const hourLength = 2
  const minuteLength = 2
  if (!timeRegex.test(time)) {
    return ''
  }

  const [hour, min] = time.split(':')
  const repeatZero = (n: number) => '0'.repeat(n)
  if (hour !== undefined && min !== undefined && +hour < 24 && +min < 60) {
    const result = {
      hour: hour + repeatZero(hourLength - hour.length),
      min: min + repeatZero(minuteLength - min.length),
    }
    return `${result.hour}:${result.min}`
  }

  const result = {
    hour: hour + repeatZero(hourLength - hour.length),
    min: '00',
  }
  return `${result.hour}:${result.min}`
}

export const isStartOfDay = (date: Date | string) => {
  return dateFormat(date, 'HH:mm') === '00:00'
}

export const isDiffYear = (date1: Date | string, date2: Date | string) => {
  return dateFormat(date1, 'yyyy') !== dateFormat(date2, 'yyyy')
}

export const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

export const rangeDateBetween = (date1: Date, date2: Date) => {
  const dates = []
  const currentDate = new Date(date1)
  while (currentDate <= date2) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

export const getStartOfNextWeek = (startDay: number = 1): Date => {
  const today = new Date()
  const currentDay = today.getDay()
  const daysUntilNextWeek = (7 - currentDay + startDay) % 7 || 7
  const nextWeekStart = new Date(today)
  nextWeekStart.setDate(today.getDate() + daysUntilNextWeek)

  return nextWeekStart
}

export const getStartOfNextMonth = (): Date => {
  const today = new Date()
  const nextMonth = today.getMonth() + 1
  const startOfNextMonth = new Date(today.getFullYear(), nextMonth, 1)
  return startOfNextMonth
}

export const getEndOfNextMonth = (): Date => {
  const today = new Date()
  const nextMonth = today.getMonth() + 1
  const startOfMonthAfterNext = new Date(today.getFullYear(), nextMonth + 1, 1)
  const endOfNextMonth = new Date(startOfMonthAfterNext.getTime() - 1)
  return endOfNextMonth
}
