<template>
  <TaskListSelectPopover
    ref="moduleRef"
    heading="Set module"
    search-placeholder="Find a module"
    :accept-levels="[TaskLevel.MODULE]"
    class="w-full"
    :board-id="boardId"
    :selected-task-id="selectedTaskId"
    v-bind="$attrs"
    @change="onSelect"
  >
    <template #empty>
      <div class="px-4">
        <p class="text-sm">No module has been created in this project. Create your first module</p>
      </div>
    </template>
    <template v-if="showUnlink || !!selectedTaskId" #footer>
      <div class="pr-2">
        <UButton
          variant="soft"
          color="gray"
          class="w-full my-3"
          block
          @click.prevent.stop="onSelect(null)"
        >
          Unlink parent
        </UButton>
      </div>
    </template>
    <slot />
  </TaskListSelectPopover>
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'
import { TaskLevel } from '#task/constant'

defineProps({
  selectedTaskId: {
    type: String as PropType<string | null>,
    default: null,
  },
  boardId: {
    type: String,
    required: true,
  },
  showUnlink: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits<{
  (e: 'change', task: string | undefined | null): void
  (e: 'createModule'): void
}>()

const moduleRef = ref<{ open: () => void, close: () => void }> ()

const onSelect = (task: TaskItem | null) => {
  emit('change', task?.id)
}

defineExpose({ open: () => (
  moduleRef.value?.open()
) })
</script>
