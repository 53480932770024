<template>
  <div ref="wrapperRef">
    <EditorExtensionsToolBarMenu v-if="editor " :editor="editor" />
    <EditorContent
      :editor="editor"
      class="tiptap-editor min-h-20 cursor-text"
      :style="{ wordBreak: 'break-word' }"
      :data-test="dataTest"
    />
  </div>
</template>

<script lang="ts" setup>
import type { AnyExtension } from '@tiptap/core/'
import { Editor, EditorContent } from '@tiptap/vue-3'
import Placeholder from '@tiptap/extension-placeholder'
import commonExtensions from '#core/packages/tiptap/extensions'

const content = defineModel('content', {
  type: String,
  default: '',
})

const isEmpty = defineModel('isEmpty', {
  type: Boolean,
  default: true,
})

const attrs = useAttrs()
const props = defineProps({
  placeholder: {
    type: String,
    default: 'Add detail description, \'/\' for commands…',
  },
  additionalExtensions: {
    type: Array as PropType<AnyExtension[]>,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  dataTest: {
    type: String,
  },
})
const emit = defineEmits(['blur', 'cancel', 'created'])

const editor = ref<Editor>()
const wrapperRef = ref<HTMLDivElement>()

const focus = () => {
  editor.value?.commands.focus()
}

const blur = () => {
  editor.value?.commands.blur()
}

onMounted(() => {
  editor.value = new Editor({
    extensions: [
      Placeholder.configure({
        placeholder: () => {
          return props.placeholder
        },
        includeChildren: true,
      }),
      ...commonExtensions,
      ...props.additionalExtensions,
    ],
    content: content.value,
    editable: !props.disabled,
    autofocus: !!attrs.autofocus,
    onUpdate: () => {
      content.value = editor.value?.getHTML() || ''
      isEmpty.value = !editor.value?.state?.doc?.textContent?.trim()?.length
    },
    onCreate: () => {
      editor.value?.commands?.setContent(content.value, false)
      isEmpty.value = !editor.value?.state.doc.textContent.trim().length
      emit('created')
    },
    onBlur: () => {
      const htmlContent = editor.value?.getHTML() || ''
      const cleanedContent = htmlContent.replace(/(<p>(?:<br>)?\s*<\/p>|&nbsp;|\s+)+$/, '')
      content.value = cleanedContent
      emit('blur')
    },
  })
})

onClickOutside(wrapperRef, () => {
  editor.value?.commands.setContent(content.value, false)
})

onUnmounted(() => {
  editor.value?.destroy()
})

defineExpose({
  focus,
  setContent: (value: string) => {
    if (editor.value) {
      editor.value.commands.setContent(value, false)
    }
  },
  isEditing: () => {
    if (editor.value) {
      return editor.value.isFocused
    }
  },
  getHeight: () => editor.value?.view?.dom?.scrollHeight,
  setEditable: (value: boolean) => {
    editor.value?.setEditable(value)
  },
  scrollIntoView: () => {
    wrapperRef.value?.scrollIntoView()
  }
})

defineShortcuts({
  escape: {
    whenever: [() => !!editor.value?.isFocused],
    usingInput: true,
    handler: () => {
      blur()
      emit('cancel')
    },
  },
})
</script>
