import validate from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/pages/runtime/validate.js";
import prefetch_45auth_45global from "/app/layers/auth/middleware/prefetch-auth.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_jgq22bhye6t3k5zbdnnil5ahuy/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  prefetch_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/layers/auth/middleware/auth.ts"),
  guest: () => import("/app/layers/auth/middleware/guest.ts")
}