import type * as Apollo from '@apollo/client'
import {
  TASK_DEPENDENCIES_QUERY,
  TASK_DETAIL_QUERY,
  MY_TASKS_QUERY,
  CREATE_TASK_DEPENDENCY_MUTATION,
  ADD_UPDATE_TASK,
  ADD_UPDATE_TASKS,
  DELETE_TASK_DEPENDENCY_MUTATION,
  UPDATE_TASK_POSITION_MUTATION,
  TOGGLE_TASK_LABELS_MUTATION,
  SET_TASK_ASSIGNEE_MUTATION,
  REMOVE_TASK_ASSIGNEE_MUTATION,
  UPDATE_TASK_CUSTOM_FIELD_MUTATION,
  TASK_SUBTASKS_QUERY,
  COPY_TASKS_MUTATION,
  TASKS_QUERY,
  UPDATE_TASK_DEPENDENCY_MUTATION,
  OPEN_LATEST_ARCHIVED_TASK_MUTATION,
  MOVE_TASKS_MUTATION,
  CREATE_TASK_ATTACHMENT_MUTATION,
  UPDATE_TASK_ATTACHMENT_MUTATION,
} from '#task/schema'
import type { Attachment } from '#attachment/types'
import type {
  TaskDependency,
  TaskDetail,
  TaskItem,
  MyTask,
  TaskPaginationType,
  BaseTask,
  TaskItemLoader,
} from '#task/types'
import { BOARD_TASKS_LOADER_QUERY } from '#task/loader_schema'

export const useLazyTasksQuery = (boardId: string, filter: { nextCurors?: string, search?: string, limit?: number, closed?: boolean }) => {
  return useLazyQuery<{ tasks: TaskPaginationType }>(TASKS_QUERY, {
    boardId,
    filter
  },
  {
    debounce: 500,
  })
}

export const useTasksLazyQuery = () => {
  return useLazyQuery<{ tasks?: TaskItemLoader[] }>(
    BOARD_TASKS_LOADER_QUERY,
    {},
    {
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
    }
  )
}

export const useTaskDetailQuery = (id: string, handle?: string) => {
  return useQuery<{ task?: TaskDetail }>(TASK_DETAIL_QUERY, {
    id,
    handle,
  })
}

export const useTaskDetailLazyQuery = (
  id?: string,
  handle?: string,
  options: Apollo.QueryHookOptions = {}
) => {
  return useLazyQuery<{ task?: TaskDetail }>(
    TASK_DETAIL_QUERY,
    {
      id,
      handle,
    },
    options
  )
}

export const useTaskSubtasksLazyQuery = (
  id: string,
  options: Apollo.QueryHookOptions = {}
) => {
  return useLazyQuery<{ tasks?: TaskItem[] }>(
    TASK_SUBTASKS_QUERY,
    {
      id,
    },
    options
  )
}

export const useListMyTasksLazyQuery = (
  filter: Record<string, string>,
  options: Apollo.QueryHookOptions = {}
) => {
  return useLazyQuery<{ myTasks: MyTask[] }>(
    MY_TASKS_QUERY,
    {
      filter,
    },
    options
  )
}

export const useTaskDependenciesQuery = (boardId: string) => {
  return useQuery<{
    taskDependencies?: TaskDependency[]
  }>(TASK_DEPENDENCIES_QUERY, {
    boardId,
  })
}

export const useAddUpdateTaskMutation = (
  options: Apollo.MutationHookOptions<{
    addUpdateTask: {
      task: TaskItem
      errors: Array<{ field: string; messages: string[] }>
    }
  }>
) => {
  return useMutation(ADD_UPDATE_TASK, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useAddUpdateTasksMutation = (
  options: Apollo.MutationHookOptions<{
    updateTasks: {
      tasks: TaskItem[]
      errors: Array<{ field: string; messages: string[] }>
    }
  }>
) => {
  return useMutation(ADD_UPDATE_TASKS, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useUpdateTaskPositionMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    updateTaskPosition: { success: boolean; tasks: BaseTask[] }
  }>(UPDATE_TASK_POSITION_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useMoveTasksMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    moveTasks: { success: boolean; tasks: BaseTask[] }
  }>(MOVE_TASKS_MUTATION, {
    ...options,
  })
}

export const useCopyTasksMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    copyTasks: { tasks: [TaskItem]; success: boolean }
  }>(COPY_TASKS_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useCreateTaskDependencyMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    createTaskDependency: { taskDependency: TaskDependency; success: boolean }
  }>(CREATE_TASK_DEPENDENCY_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useUpdateTaskDependencyMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{ updateTaskDependency: { success: boolean } }>(
    UPDATE_TASK_DEPENDENCY_MUTATION,
    {
      errorPolicy: 'ignore',
      throws: 'never',
      ...options,
    }
  )
}

export const useDeleteTaskDependencyMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    deleteTaskDependency: { success: boolean }
  }>(DELETE_TASK_DEPENDENCY_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useToggTaskLabelsMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    toggleTaskLabels: { success: boolean }
  }>(TOGGLE_TASK_LABELS_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useSetTaskAssigneeMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    setTaskAssignee: { success: boolean }
  }>(SET_TASK_ASSIGNEE_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useRemoveTaskAssigneeMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    removeTaskAssignee: { success: boolean }
  }>(REMOVE_TASK_ASSIGNEE_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useUpdateTaskCustomFieldMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    updateTaskCustomField: { success: boolean }
  }>(UPDATE_TASK_CUSTOM_FIELD_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useCreateTaskAttachmentMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    createTaskAttachment: { attachments: Attachment[] }
  }>(CREATE_TASK_ATTACHMENT_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useUpdateTaskAttachmentMutation = (
  options: Apollo.MutationHookOptions = {}
) => {
  return useMutation<{
    updateTaskAttachment: { success: boolean }
  }>(UPDATE_TASK_ATTACHMENT_MUTATION, {
    errorPolicy: 'ignore',
    throws: 'never',
    ...options,
  })
}

export const useOpenLatestArchivedTaskMutation = () => {
  return useMutation<{
    openLatestArchivedTask: {
      success: boolean
    }
  }>(OPEN_LATEST_ARCHIVED_TASK_MUTATION)
}
