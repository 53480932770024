import Link from '@tiptap/extension-link'
import Code from '@tiptap/extension-code'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import { InputRule } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import TiptapUnderline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Gapcursor from '@tiptap/extension-gapcursor'
import { Color } from '@tiptap/extension-color'
import BulletList from '@tiptap/extension-bullet-list'
import OrderList from '@tiptap/extension-ordered-list'
import Image from '@tiptap/extension-image'

const LinkExtension = Link.configure({
  openOnClick: false,
  autolink: false,
  protocols: ['http', 'https', 'mailto'],
  HTMLAttributes: {
    target: '_blank',
    rel: 'noopener noreferrer',
    class: 'text-primary-500 text-sm hover:cursor-pointer',
  },
})

const StaterKitExtension = StarterKit.configure({
  horizontalRule: false,
  dropcursor: {
    color: '#DBEAFE',
    width: 4,
  },
  gapcursor: false,
  code: false,
  bulletList: false,
  orderedList: false,
})

const BulletListExtension = BulletList.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Shift-b': () => this.editor.commands.toggleBulletList(),
    }
  },
})
const OrderListExtension = OrderList.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Shift-o': () => this.editor.commands.toggleOrderedList(),
    }
  },
})

const CodeExtension = Code.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Alt-m': () => this.editor.commands.toggleCode(),
    }
  },
})

const HorizontalRuleExtension = HorizontalRule.extend({
  addInputRules() {
    return [
      new InputRule({
        find: /^(?:---|—-|___\s|\*\*\*\s)$/,
        handler: ({ state, range }) => {
          const attributes = {}
          const { tr } = state
          const start = range.from
          const end = range.to
          tr.insert(start - 1, this.type.create(attributes)).delete(
            tr.mapping.map(start),
            tr.mapping.map(end)
          )
        },
      }),
    ]
  },
})

const TaskItemExtension = TaskItem.configure({
  nested: true,
})

const TableExtension = Table.configure({
  resizable: true,
  allowTableNodeSelection: true,
})

const HightLightExtension = Highlight.configure({
  multicolor: true,
})

export default [
  StaterKitExtension,
  LinkExtension,
  CodeExtension,
  HorizontalRuleExtension,
  TaskItemExtension,
  TableExtension,
  HightLightExtension,
  BulletListExtension,
  OrderListExtension,
  TableRow,
  TableCell,
  TableHeader,
  Gapcursor,
  TiptapUnderline,
  TaskList,
  TextStyle,
  Color,
  Image,
]
