<template>
  <div
    v-if="items.length > 0"
    ref="commandListContainer"
    class="z-50 h-auto max-h-[330px] w-72 overflow-y-auto minimal-scrollbar rounded-md border border-stone-200 bg-white px-1 py-2 shadow-md transition-all"
  >
    <button
      v-for="(item, index) in items"
      :key="index"
      class="flex items-center w-full px-2 py-1 space-x-2 text-sm text-left rounded-md text-stone-900 hover:bg-stone-100"
      :class="index === selectedIndex ? 'bg-stone-100 text-stone-900' : ''"
      @click="onSelectItem(index)"
    >
      <div
        class="flex items-center justify-center w-10 h-10 bg-white border rounded-md border-stone-200"
      >
        <Avatar :id="item.user?.id || ''" :src="item.user?.photo" :name="item.user?.fullName" size="sm" />
      </div>
      <div>
        <p class="font-medium">{{ item.user?.fullName }}</p>
        <p class="text-xs text-stone-500">{{ item.user?.email }}</p>
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { Editor, Range } from '@tiptap/core'
import type { BoardMember } from '#auth/types'

const props = defineProps({
  items: {
    type: Array as PropType<BoardMember[]>,
    required: true,
  },
  command: {
    type: Function,
    required: true,
  },
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
  range: {
    type: Object as PropType<Range>,
    required: true,
  },
})
const selectedIndex = ref(0)
const commandListContainer = ref<HTMLDivElement>()

const navigationKeys = ['ArrowUp', 'ArrowDown', 'Enter']

const onSelectItem = (index: number) => {
  const item = props.items[index]
  props.command({ id: item.user?.fullName })
}

const onKeyDown = (e: KeyboardEvent) => {
  if (navigationKeys.includes(e.key)) {
    e.preventDefault()
    if (e.key === 'ArrowUp') {
      selectedIndex.value =
        (selectedIndex.value + props.items.length - 1) % props.items.length
      scrollToSelected()
      return true
    }

    if (e.key === 'ArrowDown') {
      selectedIndex.value = (selectedIndex.value + 1) % props.items.length
      scrollToSelected()
      return true
    }

    if (e.key === 'Enter') {
      onSelectItem(selectedIndex.value)
      return true
    }

    return false
  }
}

const updateScrollView = (container: HTMLElement, item: HTMLElement) => {
  const containerHeight = container.offsetHeight
  const itemHeight = item ? item.offsetHeight : 0
  const top = item.offsetTop
  const bottom = top + itemHeight

  if (top < container.scrollTop) {
    container.scrollTop -= container.scrollTop - top + 5
  } else if (bottom > containerHeight + container.scrollTop) {
    container.scrollTop += bottom - containerHeight - container.scrollTop + 5
  }
}

const scrollToSelected = () => {
  const container = commandListContainer.value
  const item = container?.children[selectedIndex.value] as HTMLElement

  if (container && item) {
    updateScrollView(container, item)
  }
}

watch(
  () => props.items,
  () => {
    selectedIndex.value = 0
  }
)

defineExpose({
  onKeyDown,
})
</script>
