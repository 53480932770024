export const useWorkspaceAbility = () => {
  const currentWorkspacePermissions = useState<string[]>(
    'currentWorkspacePermissions',
    () => []
  )
  const checkedPermissions: Map<string, boolean> = new Map()

  const can = (permisson_required: string) => {
    if (checkedPermissions.get(permisson_required) === undefined) {
      const isAllowed = currentWorkspacePermissions.value.some((permission) =>
        permisson_required.startsWith(permission)
      )
      checkedPermissions.set(permisson_required, isAllowed)
    }

    return checkedPermissions.get(permisson_required)
  }

  const setCurrentWorkspacePermissions = (payload: string[]) => {
    currentWorkspacePermissions.value = payload
    checkedPermissions.clear()
  }

  return { can, setCurrentWorkspacePermissions }
}

export const useBoardAbility = () => {
  const { currentBoardMember } = useBoardMemberSharedState()
  const currentBoardPermissions = computed<string[]>(() => currentBoardMember.value !== false ? currentBoardMember.value?.role.allowedPermissions || [] : [])

  const can = (permisson_required: string) => {
    return currentBoardPermissions.value.some((permission) =>
      permisson_required.startsWith(permission)
    )
  }

  const canAny = (permission_required: string) => {
    return currentBoardPermissions.value.some((permission) => permission.startsWith(permission_required))
  }

  const canAccessAsMember = () => {
    return !!currentBoardMember.value
  }

  const isRole = (role: string) => {
    if (currentBoardMember.value === null || currentBoardMember.value === false)
      return false

    return currentBoardMember.value?.role.level === role
  }

  const isOwner = computed(() => isRole('OWNER'))

  const isAdmin = computed(() => isRole('ADMIN'))

  return { isAdmin, isOwner, can, canAny, canAccessAsMember }
}
