import type { Modifiers } from '@apollo/client/cache'
import { first, last } from 'lodash-es'
import type { Reference } from '@apollo/client'
import type {
  TaskItem,
  CreateTaskParams,
  TaskDependency,
  CopyTasksParams,
  TaskDependencyDetail,
} from '#task/types'
import type { TaskDependencyType } from '#task/constant'
import { TaskLevel } from '#task/constant'
import { INDEX_STEP } from '#core/constant'
import { DELETE_TASK_MUTATION, TASK_DEPENDENCIES_QUERY } from '#task/schema'
import {
  TASK_DEPENDENCY_DETAIL_FRAGMENT,
  TASK_DEPENDENCY_FRAGMENT,
} from '#task/fragment'

export const useReadTasksFromCache = (boardId: string) => {
  const { tasks, loadTasks } = useBoardTasksLoader()
  loadTasks(boardId)
  return tasks.value
}

export const useReadTaskDependenciesFromCache = (boardId: string) => {
  const { client } = useApolloClient()
  const cache = client.readQuery<{
    taskDependencies: TaskDependency[]
  }>({
    query: TASK_DEPENDENCIES_QUERY,
    variables: {
      boardId,
    },
  })

  if (cache?.taskDependencies) {
    return [...cache.taskDependencies]
  }

  return []
}

export const useReadTaskDependenciesMapFromCache = (boardId: string) => {
  const taskDependencies = useReadTaskDependenciesFromCache(boardId)

  return taskDependencies.reduce((acc, curr) => {
    if (!acc[curr.fromTaskId]) {
      acc[curr.fromTaskId] = []
    }

    acc[curr.fromTaskId].push(curr.toTaskId)
    return acc
  }, {} as Record<string, string[]>)
}

export const useCreateTask = () => {
  const { currentBoard } = useWorkspaceSharedState()
  const { getOptimisticTask } = useTaskDataConvert()

  const createTask = async ({
    level = TaskLevel.TASK,
    placement = 'bottom',
    ...params
  }: CreateTaskParams) => {
    const { name, board: boardId } = params
    const taskName = name.trim().replace(/(\r\n|\n|\r)/gm, ' ')
    if (!taskName) {
      return
    }

    const tasks = useReadTasksFromCache(boardId)
    const newPosition =
      params.position ??
      (tasks.length
        ? placement === 'bottom'
          ? (last(tasks)?.position || 0) + 1
          : (first(tasks)?.position || 1) / 2
        : INDEX_STEP)

    const payload = {
      name: taskName,
      section: params.section,
      position: newPosition,
      level,
      parent: params.parent || null,
      status: params.status || null,
      type: params.type,
    }
    const optimisticTask = getOptimisticTask(null, payload)

    const { mutate, error, loading } = useAddUpdateTaskMutation({
      variables: {
        input: payload,
      },
      optimisticResponse: {
        addUpdateTask: {
          task: optimisticTask as TaskItem,
          errors: [],
        },
      },
      update: (_, { data }) => {
        if (data?.addUpdateTask) {
          const { listCache } = useTaskSync()
          listCache.add(boardId, data.addUpdateTask.task)
        }
      },
    })

    if (loading.value) {
      return
    }

    const data = await mutate()
    if (error.value) {
      return {
        error,
      }
    }

    return {
      data,
    }
  }

  const copyTasks = async ({
    tasks,
    sectionId,
    name,
    keepAssignee = true,
    keepAttachments = true,
    keepSubtasks = true,
    onSuccess,
    onError,
  }: CopyTasksParams) => {
    const taskIds = tasks.map((task) => task.id)
    const { mutate, error } = useCopyTasksMutation({
      variables: {
        taskIds,
        sectionId,
        name,
        keepAssignee,
        keepAttachments,
        keepSubtasks,
      },
      update: (_, { data }) => {
        if (data?.copyTasks.success) {
          const { listCache } = useTaskSync()
          data.copyTasks.tasks.forEach((task: TaskItem) => {
            listCache.add(currentBoard.value.id, task)
          })
        }
      },
    })

    const data = await mutate()
    if (!error.value && data?.data?.copyTasks.success) {
      return onSuccess?.()
    }

    onError?.()
  }

  return {
    createTask,
    copyTasks,
  }
}

export const useTaskDependency = () => {
  const { client } = useApolloClient()

  const createDependency = async (
    boardId: string,
    taskDependency: Omit<TaskDependency, 'id'>
  ) => {
    const { fromTaskId, toTaskId, type } = taskDependency
    const { mutate } = useCreateTaskDependencyMutation({
      variables: {
        fromTaskId,
        toTaskId,
        type,
      },
      update: (cache, { data }) => {
        if (data?.createTaskDependency?.taskDependency) {
          const { getTaskIdentifier } = useTaskDataConvert()
          const newDependencyRef = () => {
            return cache.writeFragment({
              data: data.createTaskDependency.taskDependency,
              fragment: TASK_DEPENDENCY_FRAGMENT,
              fragmentName: 'TaskDependency',
            })
          }

          const createTaskDependency = (taskId: string) => {
            cache.modify({
              id: getTaskIdentifier(taskId),
              fields: {
                dependencies: (existingDependencies = [], { readField }) => {
                  const isExisting = existingDependencies.some(
                    (ref: Reference) => {
                      return (
                        readField('fromId', ref) === fromTaskId &&
                        readField('toId', ref) === toTaskId
                      )
                    }
                  )
                  if (isExisting) {
                    return existingDependencies
                  }

                  return existingDependencies.concat(newDependencyRef())
                },
              },
            })
          }

          cache.modify({
            fields: {
              taskDependencies: (
                existingDependencies = [],
                { storeFieldName }
              ) => {
                const storeField = extractStoreFieldName(storeFieldName)
                if (boardId !== storeField?.boardId) {
                  return existingDependencies
                }

                return existingDependencies.concat(newDependencyRef())
              },
            },
          })
          createTaskDependency(taskDependency.fromTaskId)
          createTaskDependency(taskDependency.toTaskId)
        }
      },
    })
    mutate()
  }

  const updateDependency = (
    id: string,
    params: { type: TaskDependencyType; swap?: boolean }
  ) => {
    const { mutate } = useUpdateTaskDependencyMutation({
      variables: {
        id,
        type: params.type,
        swap: params.swap,
      },
      update: (cache, { data }) => {
        if (data.updateTaskDependency?.success) {
          const fields: Modifiers = {
            type: () => params.type,
          }
          if (params.swap) {
            const dependency = cache.readFragment<TaskDependencyDetail>({
              fragment: TASK_DEPENDENCY_DETAIL_FRAGMENT,
              id: getIdentifier(id, 'TaskDependencyType'),
              fragmentName: 'TaskDependencyDetail',
            })
            extend(fields, {
              fromTask: () => dependency?.toTask,
              toTask: () => dependency?.fromTask,
              fromTaskId: () => dependency?.toTask.id,
              toTaskId: () => dependency?.fromTask.id,
            })
          }

          cache.modify({
            id: client.cache.identify({
              id,
              __typename: 'TaskDependencyType',
            }),
            fields,
            optimistic: true,
          })
        }
      },
    })
    mutate()
  }

  const removeDependency = async (id: string) => {
    client.cache.evict({
      id: client.cache.identify({
        id,
        __typename: 'TaskDependencyType',
      }),
    })
    const { mutate } = useDeleteTaskDependencyMutation({
      variables: {
        id,
      },
    })
    mutate()
  }

  return {
    createDependency,
    updateDependency,
    removeDependency,
  }
}

export const useDeleteTask = () => {
  const { getTaskIdentifier } = useTaskDataConvert()
  const deleteTask = async (ids: string[]) => {
    const { mutate } = useMutation<{ deleteTask: { success: boolean } }>(
      DELETE_TASK_MUTATION,
      {
        variables: {
          ids,
        },
        update: (cache, context) => {
          if (context.data?.deleteTask.success) {
            for (const id of ids) {
              cache.evict({
                id: getTaskIdentifier(id),
              })
            }
          }
        },
      }
    )
    return mutate()
  }

  return {
    deleteTask,
  }
}
