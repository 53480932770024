<template>
  <MoreActionDropdown
    ref="moreActionsRef"
    :action-items="actionItems"
    :button-props="buttonProps"
    :active-class="activeClass"
    :in-active-class="inActiveClass"
    class="has-[[data-popper-placement]]:!z-20 z-10"
    data-test="more-actions-button"
    @update:open="onMoreActionOpen"
    @contextmenu.prevent.stop
  >
    <template #default="{ onUpdateOpen }">
      <!-- Others Actions -->
      <slot :on-update-open="onUpdateOpen" />
      <!-- Change task type -->
      <LazyTaskTypeSelectPopover
        ref="taskTypePopoverRef"
        source="board"
        class="absolute inset-0"
        :parent-id="task.boardId"
        :selected-task-type-id="task.type.id"
        :accept-levels="[task.type.level]"
        data-test="change-task-type-popover"
        @change="handleUpdateTaskType($event)"
        @update:open="onUpdateOpen"
        @click.prevent.stop
      >
        <div class="size-6" />
      </LazyTaskTypeSelectPopover>

      <!-- Set module -->
      <LazyTaskListModuleSelectPopover
        ref="modulePopoverRef"
        :selected-task-id="task.parentId"
        :board-id="task.boardId"
        :accept-levels="[TaskLevel.MODULE]"
        class="absolute inset-0"
        :enable-unlink="!!task.parentId"
        data-test="set-module-popover"
        @update:open="onUpdateOpen"
        @click.prevent.stop
        @change="updateTask(task.id, { parent: $event || null })"
      >
        <div class="size-6" />
      </LazyTaskListModuleSelectPopover>

      <!-- Task move -->
      <LazyTaskMovePopover
        ref="movePopoverRef"
        :workspace-id="currentWorkspace.id"
        :tasks="[task]"
        class="absolute inset-0"
        data-test="task-move-popover"
        :ui-menu="uiMenu"
        @click.prevent.stop
        @update:open="onUpdateOpen"
      >
        <div class="size-6" />
      </LazyTaskMovePopover>

      <!-- Task copy -->
      <LazyTaskCopyPopover
        ref="copyPopoverRef"
        :workspace-id="currentWorkspace.id"
        :tasks="[task]"
        class="absolute inset-0"
        data-test="copy-task-popover"
        :ui-menu="uiMenu"
        @click.prevent.stop
        @update:open="onUpdateOpen"
      >
        <div class="size-6" />
      </LazyTaskCopyPopover>
    </template>
  </MoreActionDropdown>
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'
import type { TaskTypeDetail } from '#task-type/types'
import { TaskLevel } from '#task/constant'
import { TaskActionKey } from '#board/constant'
import type { TaskActionItem } from '#board/types'
import type { Button } from '#ui/types'

const props = defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  additionalActions: {
    type: Array as PropType<TaskActionItem[]>,
    default: () => [],
  },
  uiMenu: {
    type: Object as PropType<{
      container: string
    }>,
  },
  activeClass: {
    type: String,
  },
  inActiveClass: {
    type: String,
  },
  buttonProps: {
    type: Object as PropType<
      Button & {
        class?: string
        text?: string
        ui?: Record<string, string>
        popper?: {
          placement?: string
          arrow?: boolean
        }
        shortcuts?: string[]
      }
    >,
  },
})

const { updateTask } = useUpdateTask()
const { currentWorkspace } = useWorkspaceSharedState()
const toast = useToast()
const { archiveTask } = useArchiveTask()

const isMoreActionOpen = ref(false)
const movePopoverRef = ref()
const modulePopoverRef = ref()
const taskTypePopoverRef = ref()
const copyPopoverRef = ref()
const moreActionsRef = ref()

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'changeTaskType', type: TaskTypeDetail): void
}>()

const actionItems = computed((): TaskActionItem[] => {
  const commonActions = [
    {
      key: TaskActionKey.CHANGE_TASK_TYPE,
      label: 'Change task type',
      icon: 'i-heroicons-pencil-square-16-solid',
      hidden: ![TaskLevel.TASK].includes(props.task.level),
      click: () => {
        taskTypePopoverRef.value?.open()
      },
    },
    {
      key: TaskActionKey.MOVE,
      label: 'Move',
      icon: 'heroicons:arrow-right-circle',
      hidden: ![TaskLevel.TASK, TaskLevel.MODULE].includes(props.task.level),
      click: () => {
        movePopoverRef.value?.open()
      },
    },
    {
      key: TaskActionKey.CONVERT_TO_TASK,
      label: 'Convert to task',
      icon: 'heroicons:arrow-path',
      hidden: ![TaskLevel.SUBTASK].includes(props.task.level),
      click: () => {
        onConvertToTask()
      },
    },
    {
      key: TaskActionKey.COPY,
      label: 'Copy',
      icon: 'i-heroicons-document-duplicate',
      click: () => {
        copyPopoverRef.value?.open()
      },
    },
  ].filter((item) => !item.hidden)

  return [
    [...props.additionalActions, ...commonActions],
    [
      {
        key: TaskActionKey.ARCHIVE,
        label: 'Archive',
        icon: 'heroicons:archive-box-arrow-down',
        click: () => {
          onArchiveTask()
        },
      },
    ],
  ]
})

const onArchiveTask = () => {
  archiveTask(props.task)
}

const handleUpdateTaskType = (type: TaskTypeDetail) => {
  updateTask(props.task.id, { type: type.id })
  emit('changeTaskType', type)
}

const onConvertToTask = async () => {
  if (props.task.level !== TaskLevel.SUBTASK) return

  await updateTask(props.task.id, { level: TaskLevel.TASK, parent: null })

  toast.add({
    icon: 'i-heroicons-check-circle',
    color: 'green',
    title: 'Convert to task successfully',
  })
}

const onMoreActionOpen = (value: boolean) => {
  isMoreActionOpen.value = value
  emit('update:open', value)
}

defineExpose({
  close: () => {
    moreActionsRef.value?.close()
  },
  open: () => {
    moreActionsRef.value?.open()
  },
})

defineShortcuts({
  escape: {
    whenever: [isMoreActionOpen],
    handler: () => {
      moreActionsRef.value?.close()
    },
  },
})
</script>
