import { ShortcutKeys } from '#core/constant'
import type { ShortcutKey } from '#core/types'
import type { ShortcutConfig, ShortcutsConfig } from '#ui/composables/defineShortcuts'

const shortcutKeyHandler = (eventKey: string, ignoreRoute?: RegExp | string) => {
  if (ignoreRoute) {
    const currentPath = window.location.pathname
    let regex = ignoreRoute instanceof RegExp ? ignoreRoute : null
    if (typeof ignoreRoute === 'string') {
      const match = ignoreRoute.match(/(.*)-handle$/)
      if (match) {
        const prefix = match[1]
        regex = new RegExp(`^/${prefix}/`)
      }
    }

    if (regex && regex.test(currentPath)) {
      return
    }
  }

  const { emit } = useEventBus(eventKey)
  emit()
}

export const getShortcutKeyConfig = (configs: string[], ignoreRoute?: RegExp | string): ShortcutsConfig =>
  ShortcutKeys.reduce((config: Record<string, ShortcutConfig>, shortcut: ShortcutKey) => {
    if (!shortcut.key || !configs.includes(shortcut.key)) {
      return config
    }

    if (shortcut.shortcut) {
      config[shortcut.shortcut] = {
        usingInput: !!shortcut.usingInput,
        handler: () => shortcut.key && shortcutKeyHandler(shortcut.key, ignoreRoute),
      }
    }

    return config
  }, {} as Record<string, ShortcutConfig>)
