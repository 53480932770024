import { Icon } from '#components'
import TaskIcon from '#core/components/icon/Task.vue'
import StatusIcon from '#core/components/icon/Status.vue'
import type { ShortcutKey, ColorPreset } from '#core/types'

export const INDEX_STEP = 1099511627776 // 2^50

export const SCROLL_TYPE_ALLOW_LIST = new Set(['task', 'section'])

export const MIN_COLUMN_RESIZER_WIDTH = 140
export const MAX_COLUMN_RESIZER_WIDTH = 460

export const TimePickPresets = [
  {
    label: '12:00am',
    value: '00:00',
  },
  {
    label: '12:30am',
    value: '00:30',
  },
  {
    label: '1:00am',
    value: '01:00',
  },
  {
    label: '1:30am',
    value: '01:30',
  },
  {
    label: '2:00am',
    value: '02:00',
  },
  {
    label: '2:30am',
    value: '02:30',
  },
  {
    label: '3:00am',
    value: '03:00',
  },
  {
    label: '3:30am',
    value: '03:30',
  },
  {
    label: '4:00am',
    value: '04:00',
  },
  {
    label: '4:30am',
    value: '04:30',
  },
  {
    label: '5:00am',
    value: '05:00',
  },
  {
    label: '5:30am',
    value: '05:30',
  },
  {
    label: '6:00am',
    value: '06:00',
  },
  {
    label: '6:30am',
    value: '06:30',
  },
  {
    label: '7:00am',
    value: '07:00',
  },
  {
    label: '7:30am',
    value: '07:30',
  },
  {
    label: '8:00am',
    value: '08:00',
  },
  {
    label: '8:30am',
    value: '08:30',
  },
  {
    label: '9:00am',
    value: '09:00',
  },
  {
    label: '9:30am',
    value: '09:30',
  },
  {
    label: '10:00am',
    value: '10:00',
  },
  {
    label: '10:30am',
    value: '10:30',
  },
  {
    label: '11:00am',
    value: '11:00',
  },
  {
    label: '11:30am',
    value: '11:30',
  },
  {
    label: '12:00pm',
    value: '12:00',
  },
  {
    label: '12:30pm',
    value: '12:30',
  },
  {
    label: '1:00pm',
    value: '13:00',
  },
  {
    label: '1:30pm',
    value: '13:30',
  },
  {
    label: '2:00pm',
    value: '14:00',
  },
  {
    label: '2:30pm',
    value: '14:30',
  },
  {
    label: '3:00pm',
    value: '15:00',
  },
  {
    label: '3:30pm',
    value: '15:30',
  },
  {
    label: '4:00pm',
    value: '16:00',
  },
  {
    label: '4:30pm',
    value: '16:30',
  },
  {
    label: '5:00pm',
    value: '17:00',
  },
  {
    label: '5:30pm',
    value: '17:30',
  },
  {
    label: '6:00pm',
    value: '18:00',
  },
  {
    label: '6:30pm',
    value: '18:30',
  },
  {
    label: '7:00pm',
    value: '19:00',
  },
  {
    label: '7:30pm',
    value: '19:30',
  },
  {
    label: '8:00pm',
    value: '20:00',
  },
  {
    label: '8:30pm',
    value: '20:30',
  },
  {
    label: '9:00pm',
    value: '21:00',
  },
  {
    label: '9:30pm',
    value: '21:30',
  },
  {
    label: '10:00pm',
    value: '22:00',
  },
  {
    label: '10:30pm',
    value: '22:30',
  },
  {
    label: '11:00pm',
    value: '23:00',
  },
  {
    label: '11:30pm',
    value: '23:30',
  },
]

export const settingItems = [
  {
    name: 'taskTypes',
    bgColor: 'bg-blue-50',
    textColor: 'text-blue-500',
    icon: TaskIcon,
  },
  {
    name: 'statuses',
    bgColor: 'bg-yellow-50',
    textColor: 'text-yellow-500',
    icon: StatusIcon,
  },
  {
    name: 'labels',
    bgColor: 'bg-green-50',
    textColor: 'text-green-500',
    icon: h(Icon, {
      name: 'heroicons:tag-solid',
      size: '16',
      class: 'text-green-500',
    }),
  },
]

export const ColorPresets: ColorPreset[] = [
  {
    name: 'light-green',
    backgroundColor: '#B4F5D2',
    color: '#142720',
  },
  {
    name: 'light-blue',
    backgroundColor: '#BAE6FD',
    color: '#1B262C',
  },
  {
    name: 'light-yelllow',
    backgroundColor: '#FEF08A',
    color: '#261E17',
  },
  {
    name: 'light-orange',
    backgroundColor: '#FED7AA',
    color: '#7C2D12',
  },
  {
    name: 'light-purple',
    backgroundColor: '#DDD6FE',
    color: '#4C1D95',
  },
  {
    name: 'light-red',
    backgroundColor: '#FECACA',
    color: '#7F1D1D',
  },
  {
    name: 'light-gray',
    backgroundColor: '#E4E4E7',
    color: '#18181B',
  },
  {
    name: 'green',
    backgroundColor: '#00C16A',
    color: '#142720',
  },
  {
    name: 'blue',
    backgroundColor: '#38BDF8',
    color: '#1B262C',
  },
  {
    name: 'yellow',
    backgroundColor: '#FACC15',
    color: '#261E17',
  },
  {
    name: 'orange',
    backgroundColor: '#FB923C',
    color: '#431407',
  },
  {
    name: 'purple',
    backgroundColor: '#A78BFA',
    color: '#211F33',
  },
  {
    name: 'red',
    backgroundColor: '#F87171',
    color: '#450A0A',
  },
  {
    name: 'gray',
    backgroundColor: '#A1A1AA',
    color: '#09090B',
  },
  {
    name: 'dark-green',
    backgroundColor: '#007F45',
    color: '#D9FBE8',
  },
  {
    name: 'dark-blue',
    backgroundColor: '#028EDE',
    color: '#E0F2FE',
  },
  {
    name: 'dark-yellow',
    backgroundColor: '#CA8A04',
    color: '#FEF9C3',
  },
  {
    name: 'dark-orange',
    backgroundColor: '#EA580C',
    color: '#FFEDD5',
  },
  {
    name: 'dark-purple',
    backgroundColor: '#7C3AED',
    color: '#EDE9FE',
  },
  {
    name: 'dark-red',
    backgroundColor: '#DC2626',
    color: '#FEE2E2',
  },
  {
    name: 'dark-gray',
    backgroundColor: '#3F3F46',
    color: '#F4F4F5',
  },
]

export enum UPLOAD_ROOT_PATH {
  USER_AVATAR = 'user_avatar',
  WORKSPACE_LOGO = 'workspace_logo',
  BOARD_BACKGROUND = 'board_background',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ShortcutBusKey {
  KANBAN_TASK = 'kanban-task-shortcut',
  LISTVIEW_TASK = 'listview-task-shortcut',
}

export enum ShortcutCategory {
  GENERAL = 'General shortcuts',
  LIST = 'List interactions',
  CARD = 'Card actions'
}

export enum ShortcutKeyEvent {
  ADD_NEW_CARD = 'shortcut_add_new_card',
  OPEN_CARD = 'shortcut_open_card',
  OPEN_CARD_DUE_DATE = 'shortcut_open_card_due_date',
  ARCHIVE_CARD = 'shortcut_archive_card',
  OPEN_FILTER = 'shortcut_open_filter',
  TOGGLE_FILTER_ONLY_ME = 'shortcut_toggle_filter_only_me',
  TOGGLE_BOARD_SETTINGS = 'shortcut_toggle_board_settings',
  TOGGLE_EXPAND_SIDEBAR = 'shortcut_toggle_expand_sidebar',
  TOGGLE_BOARD_MENU = 'shortcut_toggle_board_menu',
  CLEAR_ALL_FILTER = 'shortcut_clear_all_filter',
  GO_HOME = 'shortcut_go_home',
  EDIT_CART_TITLE = 'shortcut_edit_card_title',
  EDIT_CART_DESCRIPTION = 'shortcut_edit_card_description',
  TOGGLE_TASK_QUICK_ACTIONS = 'shortcut_toggle_task_quick_actions',
  OPEN_SHORTCUT_HELP = 'shortcut_open_shortcut_help',
  TOGGLE_TASK_LABELS = 'shortcut_toggle_task_labels',
  ADD_TASK_DEPENDENCIES = 'shortcut_add_task_dependencies',
  ASSIGN_ME_TO_TASK = 'shortcut_assign_me_to_task',
  MOVE_CARD_TO_RIGHT_SECTION = 'shortcut_move_card_to_right_section',
  MOVE_CARD_TO_LEFT_SECTION = 'shortcut_move_card_to_left_section',
  NAVIGATE_DOWN = 'shortcut_navigate_down',
  NAVIGATE_UP = 'shortcut_navigate_up',
  NAVIGATE_RIGHT = 'shortcut_navigate_right',
  NAVIGATE_LEFT = 'shortcut_navigate_left',
  TOGGLE_MEMBER_MODAL = 'shortcut_toggle_member_modal',
  ADD_MODULE = 'shortcut_add_module',
  EDIT_CELL_LISTVIEW = 'shortcut_edit_cell_listview',
  DELETE_CELL_VALUE = 'shortcut_delete_cell_value',
  CREATE_COMMENT = 'shortcut_create_comment',
  NAVIGATE_BOARD_VIEW = 'shortcut_navigate_board_view',
  NAVIGATE_LIST_VIEW = 'shortcut_navigate_list_view',
  NAVIGATE_TIMELINE_VIEW = 'shortcut_navigate_timeline_view',
}

export const WorkspaceShortcut = [
  ShortcutKeyEvent.OPEN_FILTER,
  ShortcutKeyEvent.OPEN_SHORTCUT_HELP,
  ShortcutKeyEvent.TOGGLE_EXPAND_SIDEBAR,
  ShortcutKeyEvent.TOGGLE_BOARD_MENU,
  ShortcutKeyEvent.GO_HOME,
  ShortcutKeyEvent.CLEAR_ALL_FILTER,
  ShortcutKeyEvent.TOGGLE_FILTER_ONLY_ME,
  ShortcutKeyEvent.TOGGLE_MEMBER_MODAL,
  ShortcutKeyEvent.TOGGLE_BOARD_SETTINGS,
  ShortcutKeyEvent.NAVIGATE_BOARD_VIEW,
  ShortcutKeyEvent.NAVIGATE_LIST_VIEW,
  ShortcutKeyEvent.NAVIGATE_TIMELINE_VIEW,
]

export const KanbanShortcut = [
  ShortcutKeyEvent.OPEN_CARD,
  ShortcutKeyEvent.ADD_NEW_CARD,
  ShortcutKeyEvent.ADD_MODULE,
  ShortcutKeyEvent.MOVE_CARD_TO_RIGHT_SECTION,
  ShortcutKeyEvent.MOVE_CARD_TO_LEFT_SECTION,
  ShortcutKeyEvent.ARCHIVE_CARD,
  ShortcutKeyEvent.OPEN_CARD_DUE_DATE,
  ShortcutKeyEvent.TOGGLE_TASK_LABELS,
  ShortcutKeyEvent.TOGGLE_TASK_QUICK_ACTIONS,
  ShortcutKeyEvent.NAVIGATE_UP,
  ShortcutKeyEvent.NAVIGATE_DOWN,
  ShortcutKeyEvent.NAVIGATE_LEFT,
  ShortcutKeyEvent.NAVIGATE_RIGHT,
  ShortcutKeyEvent.ASSIGN_ME_TO_TASK,
  ShortcutKeyEvent.TOGGLE_MEMBER_MODAL,
]

export const TaskDetailShortcut = [
  ShortcutKeyEvent.EDIT_CART_TITLE,
  ShortcutKeyEvent.EDIT_CART_DESCRIPTION,
  ShortcutKeyEvent.TOGGLE_TASK_LABELS,
  ShortcutKeyEvent.OPEN_CARD_DUE_DATE,
  ShortcutKeyEvent.ADD_MODULE,
  ShortcutKeyEvent.ASSIGN_ME_TO_TASK,
  ShortcutKeyEvent.ADD_TASK_DEPENDENCIES,
  ShortcutKeyEvent.TOGGLE_MEMBER_MODAL,
  ShortcutKeyEvent.ARCHIVE_CARD
]

export const ListViewShortcut = [
  ShortcutKeyEvent.EDIT_CELL_LISTVIEW,
  ShortcutKeyEvent.DELETE_CELL_VALUE,
  ShortcutKeyEvent.NAVIGATE_LEFT,
  ShortcutKeyEvent.NAVIGATE_RIGHT,
]

export const ShortcutKeys: ShortcutKey[] = [
  {
    key: ShortcutKeyEvent.ADD_NEW_CARD,
    shortcut: 'n',
    description: {
      name: 'Create a new task',
      shortcuts: ['N']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.OPEN_CARD,
    shortcut: 'enter',
    description: {
      name: 'Open selected card',
      shortcuts: ['Enter']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.OPEN_CARD_DUE_DATE,
    shortcut: 'd',
    description: {
      name: 'Open the Due Date menu for a card',
      shortcuts: ['D']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.ARCHIVE_CARD,
    shortcut: 'c',
    description: {
      name: 'Archive a card',
      shortcuts: ['C']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.OPEN_FILTER,
    shortcut: 'f',
    description: {
      name: 'Open the Filter menu',
      shortcuts: ['F']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.TOGGLE_FILTER_ONLY_ME,
    shortcut: 'q',
    description: {
      name: 'Show only your tasks',
      shortcuts: ['Q']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.TOGGLE_BOARD_SETTINGS,
    shortcut: 'w',
    description: {
      name: 'Open/Close board settings',
      shortcuts: ['W']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.TOGGLE_EXPAND_SIDEBAR,
    shortcut: '[',
    description: {
      name: 'Collapse/Expand sidebar',
      shortcuts: ['[']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.TOGGLE_BOARD_MENU,
    shortcut: ']',
    description: {
      name: 'Open/Close board menu',
      shortcuts: [']']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.CLEAR_ALL_FILTER,
    shortcut: 'x',
    description: {
      name: 'Clear all filter',
      shortcuts: ['X']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.NAVIGATE_UP,
    shortcut: 'arrowup',
    description: {
      name: 'Navigate to the previous card in the section',
      shortcuts: ['↑']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.NAVIGATE_DOWN,
    shortcut: 'arrowdown',
    description: {
      name: 'Navigate to the next card in the section',
      shortcuts: ['↓']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.NAVIGATE_LEFT,
    shortcut: 'arrowleft',
    description: {
      name: 'Navigate to the previous section',
      shortcuts: ['←']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.NAVIGATE_RIGHT,
    shortcut: 'arrowright',
    description: {
      name: 'Navigate to the next section',
      shortcuts: ['→']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.MOVE_CARD_TO_LEFT_SECTION,
    shortcut: ',',
    description: {
      name: 'Move card to the left section',
      shortcuts: [',']
    },
    category: ShortcutCategory.CARD
  },
  {
    key: ShortcutKeyEvent.MOVE_CARD_TO_RIGHT_SECTION,
    shortcut: '.',
    description: {
      name: 'Move card to the right section',
      shortcuts: ['.']
    },
    category: ShortcutCategory.CARD
  },
  {
    key: ShortcutKeyEvent.EDIT_CART_TITLE,
    shortcut: 't',
    description: {
      name: 'Edit card title',
      shortcuts: ['T']
    },
    category: ShortcutCategory.CARD
  },
  {
    key: ShortcutKeyEvent.EDIT_CART_DESCRIPTION,
    shortcut: 'shift_t',
    description: {
      name: 'Edit card description',
      shortcuts: ['Shift', 'T']
    },
    category: ShortcutCategory.CARD
  },
  {
    shortcut: 'esc',
    description: {
      name: 'Close any open card, menu, or popup',
      shortcuts: ['Esc']
    },
    category: ShortcutCategory.CARD
  },
  {
    key: ShortcutKeyEvent.TOGGLE_MEMBER_MODAL,
    shortcut: 'a',
    description: {
      name: 'Add/Remove members',
      shortcuts: ['A']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Copy a card',
      shortcuts: ['meta', 'C']
    },
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Create a copied card or add an attachment',
      shortcuts: ['meta', 'V']
    },
    category: ShortcutCategory.CARD
  },
  {
    key: ShortcutKeyEvent.TOGGLE_TASK_QUICK_ACTIONS,
    shortcut: 'e',
    description: {
      name: 'Open the Quick Action’s card menu',
      shortcuts: ['E']
    },
    category: ShortcutCategory.CARD
  },
  {
    key: ShortcutKeyEvent.OPEN_SHORTCUT_HELP,
    shortcut: 'meta_,',
    description: {
      name: 'Open shortcut help',
      shortcuts: ['meta', ',']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.TOGGLE_TASK_LABELS,
    shortcut: 'l',
    description: {
      name: 'Open the Labels menu',
      shortcuts: ['L']
    },
    category: ShortcutCategory.CARD
  },
  {
    key: ShortcutKeyEvent.ADD_TASK_DEPENDENCIES,
    shortcut: 'p',
    description: {
      name: 'Add task dependencies',
      shortcuts: ['P']
    },
    category: ShortcutCategory.CARD
  },
  {
    key: ShortcutKeyEvent.ADD_MODULE,
    shortcut: 's',
    description: {
      name: 'Add module',
      shortcuts: ['S']
    },
    category: ShortcutCategory.CARD
  },
  {
    key: ShortcutKeyEvent.ASSIGN_ME_TO_TASK,
    shortcut: ' ',
    description: {
      name: 'Assign me to task/Leave task',
      shortcuts: ['Space'],
      alternative: ['I']
    },
    category: ShortcutCategory.CARD
  },
  {
    key: ShortcutKeyEvent.ASSIGN_ME_TO_TASK,
    shortcut: 'i',
    category: ShortcutCategory.CARD
  },
  {
    description: {
      name: 'Select a range of tasks upwards',
      shortcuts: ['Shift', '↑']
    },
    category: ShortcutCategory.LIST
  },
  {
    description: {
      name: 'Select a range of tasks downwards',
      shortcuts: ['Shift', '↓']
    },
    category: ShortcutCategory.LIST
  },
  {
    description: {
      name: 'Select tasks',
      shortcuts: ['Shift', 'Click']
    },
    category: ShortcutCategory.LIST
  },
  {
    description: {
      name: 'Move left column',
      shortcuts: ['←']
    },
    category: ShortcutCategory.LIST
  },
  {
    description: {
      name: 'Move right column',
      shortcuts: ['→']
    },
    category: ShortcutCategory.LIST
  },
  {
    key: ShortcutKeyEvent.EDIT_CELL_LISTVIEW,
    shortcut: 'enter',
    description: {
      name: 'Edit cell',
      shortcuts: ['Enter']
    },
    category: ShortcutCategory.LIST
  },
  {
    key: ShortcutKeyEvent.DELETE_CELL_VALUE,
    shortcut: 'backspace',
    description: {
      name: 'Delete cell value',
      shortcuts: ['Backspace']
    },
    category: ShortcutCategory.LIST
  },
  {
    key: ShortcutKeyEvent.NAVIGATE_BOARD_VIEW,
    shortcut: '1',
    description: {
      name: 'Navigate to board view',
      shortcuts: ['1']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.NAVIGATE_LIST_VIEW,
    shortcut: '2',
    description: {
      name: 'Navigate to list view',
      shortcuts: ['2']
    },
    category: ShortcutCategory.GENERAL
  },
  {
    key: ShortcutKeyEvent.NAVIGATE_TIMELINE_VIEW,
    shortcut: '3',
    description: {
      name: 'Navigate to timeline view',
      shortcuts: ['3']
    },
    category: ShortcutCategory.GENERAL
  }
]

export enum PLATFORM_HELP_LINKS {
  SAMPLE_CSV_IMPORT_FILE = 'https://drive.google.com/file/u/2/d/1bDaPnBbGfzxMdgi0zHYv-KtRnuuMZKT4/view?usp=sharing',
  IMPORT_TASKS_HELP_DOC = 'https://example.com/',
  EXPORT_FROM_OTHER_TOOLS = 'https://example.com/1',
}

export enum UploadType {
  IMAGE = 'image',
  FILE = 'file',
}
