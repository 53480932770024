<template>
  <UModal
    v-model="visible"
    class="task-detail-modal"
    data-test="task-detail-modal"
    :ui="{ width: 'w-full sm:max-w-[832px]', inner: 'scroll-stable', container: 'sm:items-start' }"
    :prevent-close="preventCloseState.has('task-detail')"
    @close="onCloseTaskView"
  >
    <UCard v-if="currentTask.id" class="overflow-hidden" :ui="{ body: 'p-0' }">
      <TaskDetail
        :task="currentTask"
        @loaded="(task) => (taskDetail = task)"
        @close="onCloseTaskView"
      />
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import type { TaskDetail, CurrentTask } from '#task/types'

const { currentTask, currentBoard, setCurrentTask } = useWorkspaceSharedState()
const { preventCloseState } = useModalManager()

const taskDetail = ref<TaskDetail>()
const visible = ref(false)

const onCloseTaskView = () => {
  window.history.pushState(null, '', `/b/${taskDetail.value?.board.handle}`)
  setCurrentTask({} as CurrentTask)
}

watch(
  () => currentTask.value.id,
  (taskId) => {
    visible.value = !!taskId
    if (visible.value) {
      useHead({
        title: () => `${currentTask.value.name} | ${currentBoard.value.name}`,
      })
    }
  },
  {
    immediate: true,
  }
)
</script>
