import { cloneDeep } from 'lodash-es'
import type { TaskItemLoader } from '#task/types'

const $useBoardTasksLoader = () => {
  const currentBoardId = ref<string | null>(null)

  const { result, load, refetch } = useTasksLazyQuery()

  const tasks = computed<TaskItemLoader[]>(() => {
    return cloneDeep(result.value?.tasks || [])
      .filter((task) => !task.closed)
      .sort(sortPos)
  })

  const loadTasks = (boardId: string | null = null) => {
    if (!boardId) {
      const { currentBoard } = useWorkspaceSharedState()
      boardId = currentBoard.value.id
    }

    if (currentBoardId.value === boardId) {
      return
    }

    currentBoardId.value = boardId
    const loadResponse = load(null, { boardId })
    if (loadResponse === false) {
      return refetch({ boardId })
    }

    return loadResponse
  }

  return {
    tasks,
    loadTasks,
  }
}

export const useBoardTasksLoader = createSharedComposable($useBoardTasksLoader)
