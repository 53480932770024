<template>
  <UPopover
    v-model:open="open"
    :ui="{ width: 'w-64' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
  >
    <template #panel>
      <div class="py-3 px-4">
        <div class="flex items-center justify-between">
          <p class="font-semibold text-sm text-gray-900">Cover</p>
          <UButton
            size="xs"
            icon="i-heroicons-x-mark"
            color="gray"
            variant="ghost"
            @click="open = false"
          />
        </div>
        <div class="mt-3">
          <TaskCoverPanel v-if="open" v-bind="$props" @close="open = false" />
        </div>
      </div>
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
import type { Attachment } from '#attachment/types'

defineProps({
  taskId: {
    type: String,
    required: true,
  },
  cover: {
    type: String as PropType<string | null>,
  },
  attachments: {
    type: Array as PropType<Attachment[]>,
  },
})

const open = ref(false)
</script>
