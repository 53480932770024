import { ATTACHMENT_FRAGMENT } from '#attachment/fragment'
import { BOARD_MIN_FRAGMENT } from '#board/fragment'
import { CUSTOM_FIELD_FRAGMENT } from '#field/fragment'
import { LABEL_FRAGMENT } from '#label/fragment'
import { SECTION_FRAGMENT } from '#section/fragment'
import { STATUS_FRAGMENT } from '#status/fragment'
import { TASK_TYPE_FRAGMENT } from '#task-type/fragment'
import {
  TASK_ATTACHMENT_PREVIEW_FRAGMENT,
  TASK_DEPENDENCY_DETAIL_FRAGMENT,
  TASK_DEPENDENCY_FRAGMENT,
  TASK_DETAIL_FRAGMENT,
  TASK_PREVIEW_FRAGMENT,
} from '#task/fragment'

export const TASK_DETAIL_QUERY = gql`
  ${TASK_DETAIL_FRAGMENT}
  query TaskDetailQuery($id: ID, $handle: String) {
    task(id: $id, handle: $handle) {
      ...TaskDetail
    }
  }
`

export const TASK_SUBTASKS_QUERY = gql`
  ${TASK_PREVIEW_FRAGMENT}
  query TaskSubtasksQuery($id: ID!) {
    tasks: taskSubtasks(id: $id) {
      ...TaskPreview
    }
  }
`

export const TASK_DEPENDENCIES_QUERY = gql`
  ${TASK_DEPENDENCY_FRAGMENT}
  query TaskDependencyQuery($boardId: ID!) {
    taskDependencies(boardId: $boardId) {
      ...TaskDependency
    }
  }
`

export const TASKS_QUERY = gql`
  ${TASK_PREVIEW_FRAGMENT}
  ${SECTION_FRAGMENT}
  query TasksQuery(
    $boardId: ID!
    $filter: TaskFilterInput
  ) {
    tasks(boardId: $boardId, filter: $filter) {
      tasks {
        ...TaskPreview
        section {
          ...Section
        }
      }
      nextCursor
    }
  }
`

export const MY_TASKS_QUERY = gql`
  ${BOARD_MIN_FRAGMENT}
  ${STATUS_FRAGMENT}
  ${LABEL_FRAGMENT}
  query MyTasksQuery($filter: MyTaskFilterInput) {
    myTasks(filter: $filter) {
      id
      name
      handle
      startDate
      dueDate
      board {
        ...Board
      }
      status {
        ...Status
      }
      labels {
        ...Label
      }
      level
    }
  }
`

export const TASK_CHECK_BEFORE_MOVE_QUERY = gql`
  ${STATUS_FRAGMENT}
  ${TASK_TYPE_FRAGMENT}
  query TaskCheckBeforeMoveQuery($id: ID!, $targetBoardId: ID!) {
    taskCheckBeforeMove(id: $id, targetBoardId: $targetBoardId) {
      needMigration
      replaceFields {
        fromStatuses {
          ...Status
        }
        toStatuses {
          ...Status
        }
        fromTaskTypes {
          ...TaskType
        }
        toTaskTypes {
          ...TaskType
        }
      }
    }
  }
`

export const TASK_ATTACHMENT_RESOLVE_URL_QUERY = gql`
  query TaskAttachmentResolveUrlQuery($urls: [String!]!) {
    urls: taskAttachmentResolveUrl(urls: $urls) {
      url
      type
      handle
      isInternal
    }
  }
`

export const TASK_ATTACHMENT_PREVIEW_QUERY = gql`
  ${TASK_ATTACHMENT_PREVIEW_FRAGMENT}
  query TaskAttachmentPreviewQuery($handle: String!) {
    taskPreview: task(handle: $handle) {
      ...TaskAttachmentPreview
    }
  }
`

export const ADD_UPDATE_TASK = gql`
  ${TASK_PREVIEW_FRAGMENT}
  mutation AddUpdateTaskMutation($input: AddUpdateTaskInput!) {
    addUpdateTask(input: $input) {
      task {
        ...TaskPreview
      }
      errors {
        field
        messages
      }
    }
  }
`

export const ADD_UPDATE_TASKS = gql`
  ${TASK_PREVIEW_FRAGMENT}
  mutation UpdateTasksMutation($taskIds: [ID!]!, $fields: JSONString!) {
    updateTasks(taskIds: $taskIds, fields: $fields) {
      tasks {
        ...TaskPreview
      }
    }
  }
`

export const COPY_TASKS_MUTATION = gql`
  ${TASK_PREVIEW_FRAGMENT}
  mutation CopyTasksMutation(
    $taskIds: [ID]!,
    $sectionId: ID!,
    $name: String,
    $keepAssignee: Boolean,
    $keepSubtasks: Boolean,
    $keepAttachments: Boolean
  ) {
    copyTasks(
      taskIds: $taskIds, sectionId: $sectionId, name: $name,
      keepAssignee: $keepAssignee, keepSubtasks: $keepSubtasks,
      keepAttachments: $keepAttachments
    ) {
      success
      tasks {
        ...TaskPreview
      }
    }
  }
`

export const UPDATE_TASK_POSITION_MUTATION = gql`
  mutation UpdateTaskPositionMutation(
    $taskIds: [ID!]!
    $sectionId: ID!
    $position: [Float!]!
    $parentId: ID
  ) {
    updateTaskPosition(
      taskIds: $taskIds
      sectionId: $sectionId
      position: $position
      parentId: $parentId
    ) {
      success
      tasks {
        id
        parentId
        position
      }
    }
  }
`

export const MOVE_TASKS_MUTATION = gql`
  mutation MoveTasks($taskIds: [ID]!, $sectionId: ID!) {
    moveTasks(taskIds: $taskIds, sectionId: $sectionId) {
      success
      tasks {
        id
        name
        parentId
        boardId
        sectionId
        position
      }
    }
  }
`

export const CREATE_TASK_DEPENDENCY_MUTATION = gql`
  ${TASK_DEPENDENCY_DETAIL_FRAGMENT}
  mutation CreateTaskDependencyMutation(
    $fromTaskId: ID!
    $toTaskId: ID!
    $type: Int!
  ) {
    createTaskDependency(
      fromTaskId: $fromTaskId
      toTaskId: $toTaskId
      type: $type
    ) {
      success
      taskDependency {
        fromTaskId
        toTaskId
        ...TaskDependencyDetail
      }
    }
  }
`

export const UPDATE_TASK_DEPENDENCY_MUTATION = gql`
  mutation UpdateTaskDependencyMutation($id: ID!, $type: Int!, $swap: Boolean) {
    updateTaskDependency(id: $id, type: $type, swap: $swap) {
      success
    }
  }
`

export const DELETE_TASK_DEPENDENCY_MUTATION = gql`
  mutation DeleteTaskDependencyMutation($id: ID!) {
    deleteTaskDependency(id: $id) {
      success
    }
  }
`

export const TOGGLE_TASK_LABELS_MUTATION = gql`
  mutation ToggleTaskLabelsMutation($detachLabelIds: [ID!], $taskIds: [ID!]!, $labelIds: [ID!]!) {
    toggleTaskLabels(detachLabelIds: $detachLabelIds, taskIds: $taskIds, labelIds: $labelIds) {
      success
    }
  }
`

export const SET_TASK_ASSIGNEE_MUTATION = gql`
  mutation SetTaskAssigneeMutation($id: ID!, $assigneeId: ID!) {
    setTaskAssignee(id: $id, assigneeId: $assigneeId) {
      success
    }
  }
`

export const REMOVE_TASK_ASSIGNEE_MUTATION = gql`
  mutation RemoveTaskAssigneeMutation($id: ID!) {
    removeTaskAssignee(id: $id) {
      success
    }
  }
`

export const UPDATE_TASK_CUSTOM_FIELD_MUTATION = gql`
  ${CUSTOM_FIELD_FRAGMENT}
  mutation UpdateTaskCustomFieldMutation(
    $taskIds: [ID]!
    $fieldId: ID!
    $value: String
  ) {
    updateTaskCustomField(taskIds: $taskIds, fieldId: $fieldId, value: $value) {
      success
      taskFields {
        ...CustomField
      }
    }
  }
`

export const CREATE_TASK_ATTACHMENT_MUTATION = gql`
  ${ATTACHMENT_FRAGMENT}
  mutation CreateTaskAttachmentMutation(
    $taskId: ID!
    $type: Int!
    $name: String
    $attachments: [String!]!
  ) {
    createTaskAttachment(
      taskId: $taskId
      type: $type
      name: $name
      attachments: $attachments
    ) {
      attachments {
        ...Attachment
      }
    }
  }
`

export const DELETE_TASK_ATTACHMENT_MUTATION = gql`
  mutation DeleteTaskAttachmentMutation($attachmentId: ID!) {
    deleteTaskAttachment(attachmentId: $attachmentId) {
      success
    }
  }
`

export const UPDATE_TASK_ATTACHMENT_MUTATION = gql`
  ${ATTACHMENT_FRAGMENT}
  mutation UpdateTaskAttachmentMutation(
    $attachmentId: ID!
    $name: String
  ) {
    updateTaskAttachment(
      attachmentId: $attachmentId
      name: $name
    ) {
      attachment {
        ...Attachment
      }
    }
  }
`

export const TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION = gql`
  mutation CreateTaskAttachmentPresignedUrlMutation(
    $taskId: ID!
    $files: [FileType!]
  ) {
    presigned: createAttachmentPresignedUrl(taskId: $taskId, files: $files) {
      urls
    }
  }
`

export const DELETE_TASK_MUTATION = gql`
  mutation DeleteTaskMutation($ids: [ID!]!) {
    deleteTask(ids: $ids) {
      success
    }
  }
`

export const OPEN_LATEST_ARCHIVED_TASK_MUTATION = gql`
  mutation OpenLatestArchivedTaskMutation($boardId: ID!) {
    openLatestArchivedTask(boardId: $boardId) {
      success
    }
  }
`
