import { has, invert, uniq } from 'lodash-es'
import { ApolloError } from '@apollo/client/core'

import type { BoardData } from '#board/loader_types'
import {
  MAX_COLUMN_RESIZER_WIDTH,
  MIN_COLUMN_RESIZER_WIDTH,
} from '#core/constant'
import { BoardView, GroupBy, TimeLineRangeView } from '#board/constant'
import type {
  ColumnType,
  SortType,
  TaskViewPosition,
  ViewOptionsType,
} from '#board/types'
import type { BoardMember } from '#auth/types'
import type { TaskItem } from '#task/types'
import type { BaseTaskType } from '#task-type/types'
import type { Label } from '#label/types'
import { FieldType } from '#field/constant'
import { TaskLevel } from '#task/constant'

/**
 * ListView shared state
 */
const $useListViewSharedState = () => {
  const { toggleLabels } = useUpdateTask()
  const checkedTasks = ref<Set<string>>(new Set())
  const selectedTasks = ref<TaskItem[]>([])
  const numColumns = ref(0)
  const listTasks = ref<TaskItem[]>([])
  const tasksShowSubtasks = ref<TaskItem[]>([])
  const tableColumnWidth = reactive<Record<string, number>>({
    name: MAX_COLUMN_RESIZER_WIDTH,
  })

  const currentHoverCellPosition = ref<TaskViewPosition>({
    row: -1,
    column: -1,
  })
  const currentFocusCellPosition = ref<TaskViewPosition>({
    row: -1,
    column: -1,
  })
  const currentSelectedRow = ref<number | null>(null)

  const isFocusCell = computed(() => {
    return currentFocusCellPosition.value.row !== -1
  })

  const renderTasks = computed(() => {
    const renderTasks: TaskItem[] = []

    listTasks.value.forEach((task) => {
      renderTasks.push(task)
      const subtasks = tasksShowSubtasks.value.find(
        (currentTask) => currentTask.id === task.id
      )?.subtasks
      if (subtasks) {
        renderTasks.push(...subtasks)
      }
    })

    return renderTasks
  })

  const getTableColumnWidth = (key: string) => {
    return tableColumnWidth[key] || MIN_COLUMN_RESIZER_WIDTH
  }

  const getTaskRowPosition = (taskId: string) => {
    return renderTasks.value.findIndex((task) => task.id === taskId)
  }

  const focusCell = (column: number, row: number) => {
    currentFocusCellPosition.value = { column, row }
  }

  const updateSelectedTasksLabel = (label: Label, currentValue: string) => {
    if (currentValue === 'unchecked' || currentValue === 'checked') {
      toggleLabels({
        taskIds: Array.from(checkedTasks.value),
        labelIds: [label.id],
        detachLabelIds: [],
      })
      return
    }

    toggleLabels({
      taskIds: Array.from(checkedTasks.value),
      labelIds: [],
      detachLabelIds: [label.id],
    })
  }

  const numRows = computed(() => renderTasks.value.length)

  const handleSelectMultipleTask = (
    fromPosition: number,
    targetPosition: number
  ) => {
    fromPosition = Math.max(0, fromPosition)

    const start = Math.min(fromPosition, targetPosition)
    const end = Math.max(fromPosition, targetPosition)
    selectedTasks.value = renderTasks.value?.slice(start, end + 1)
    return
  }

  const handleSelectTaskByArrowKey = (
    oldPosition: number,
    newPosition: number
  ) => {
    if (oldPosition === newPosition) {
      return
    }

    const newTargetTask = renderTasks.value[newPosition]
    const newTargetTaskIndex = selectedTasks.value.findIndex(
      (t) => t.id === newTargetTask.id
    )
    const oldTargetTask = renderTasks.value[oldPosition]

    if (newTargetTaskIndex === -1) {
      selectedTasks.value.push(newTargetTask)
      if (!selectedTasks.value.some((t) => t.id === oldTargetTask.id)) {
        selectedTasks.value.push(oldTargetTask)
      }
    } else {
      selectedTasks.value.splice(newTargetTaskIndex, 1)
      selectedTasks.value = selectedTasks.value.filter(
        (t) => t.id !== oldTargetTask.id
      )
    }
  }

  return {
    tableColumnWidth,
    currentSelectedRow,
    numRows,
    numColumns,
    isFocusCell,
    listTasks,
    renderTasks,
    tasksShowSubtasks,
    currentFocusCellPosition,
    currentHoverCellPosition,
    checkedTasks,
    selectedTasks,
    focusCell,
    getTaskRowPosition,
    handleSelectMultipleTask,
    updateSelectedTasksLabel,
    handleSelectTaskByArrowKey,
    getTableColumnWidth,
  }
}

export const useListViewSharedState = createSharedComposable(
  $useListViewSharedState
)

/**
 * Kanban shared state
 */
const $useKanbanSharedState = () => {
  const currentFocusKanbanTaskPosition = ref<TaskViewPosition>({
    row: -1,
    column: -1,
  })
  const currentFocusKanbanTask = ref<TaskItem | null>(null)
  const isFocusKanbanTask = ref(false)
  const lastMousePosition = ref<{ x: number; y: number }>({ x: 0, y: 0 })
  const isIgnoreHoverTask = ref(false)
  const currentHoverSection = ref<number>(-1)

  return {
    currentHoverSection,
    isIgnoreHoverTask,
    lastMousePosition,
    currentFocusKanbanTask,
    currentFocusKanbanTaskPosition,
    isFocusKanbanTask,
  }
}

export const useKanbanSharedState = createSharedComposable(
  $useKanbanSharedState
)

/**
 * Board shared state
 * - BoardData loader
 */
const $useBoardSharedState = () => {
  const calcResult = reactive<{ value: number | string; key: string }[]>([])
  const sortBy = reactive<SortType>({
    field: '',
    direction: '',
    key: '',
  })
  const availableSortableColumns = reactive<ColumnType[]>([])
  const calcColumn = reactive<
    { operator: string; key: string; name: string }[]
  >([])
  const groupBy = ref(GroupBy.MODULE)
  const viewOptions = ref<ViewOptionsType>()
  const taskTypeList = ref<BaseTaskType[]>([])
  const boardData = ref<BoardData>({
    settingsPacks: [],
    labels: [],
    statuses: [],
    taskTypes: [],
    fields: [],
    users: [],
  })
  const activeView = ref<BoardView>(BoardView.LIST)
  const isOpenBoardSettingsSlideover = ref(false)

  /**
   * Get default status
   * @returns {BaseTaskType | null}
   */
  const getDefaultStatus = () => {
    const statuses = boardData.value.statuses.filter(
      (status) => !status.settingsPackId
    )
    return statuses.length > 0 ? statuses[0] : null
  }

  /**
   * Get default task type
   * This function returns the first task type that matches the specified level.
   * If no level is provided, it defaults to TaskLevel.TASK.
   *
   * @param {TaskLevel} level - The level of the task type to find.
   * @returns {TaskType | undefined} - The first task type that matches the level, or null if no match is found.
   */
  const getDefaultTaskType = (level: TaskLevel = TaskLevel.TASK) => {
    const taskTypes = boardData.value.taskTypes
    return taskTypes.find(
      (taskType) => taskType.level === level && !taskType.settingsPackId
    )
  }

  const setActiveView = (view: BoardView) => {
    activeView.value = view
  }

  const toggleBoardSettingsSlideover = () => {
    isOpenBoardSettingsSlideover.value = !isOpenBoardSettingsSlideover.value
  }

  const openBoardSettingsSlideover = () => {
    isOpenBoardSettingsSlideover.value = true
  }

  const closeBoardSettingsSlideover = () => {
    isOpenBoardSettingsSlideover.value = false
  }

  return {
    groupBy,
    sortBy,
    availableSortableColumns,
    calcColumn,
    calcResult,
    viewOptions,
    taskTypeList,
    boardData,
    activeView,
    isOpenBoardSettingsSlideover,
    getDefaultStatus,
    getDefaultTaskType,
    setActiveView,
    toggleBoardSettingsSlideover,
    openBoardSettingsSlideover,
    closeBoardSettingsSlideover,
  }
}

export const useBoardSharedState = createSharedComposable($useBoardSharedState)

/**
 * BoardSettingsNavigator shared state
 */
const $useBoardSettingsNavigator = () => {
  const route = useRoute()
  const router = useRouter()

  const isOpen = ref(false)
  const preventClose = ref(false)
  const currentPage = ref('')

  const tabs: Record<string, string> = {
    index: '#settings',
    tasks: '#settings-tasks',
    statuses: '#settings-statuses',
    labels: '#settings-labels',
    settingsPacks: '#settings-packs',
    settingsPack: '#settings-pack',
    settingsPackPreview: '#settings-preview-settings-pack',
    fieldForm: '#settings-fields',
  }
  const invertTabs = invert(tabs)

  const updateComponent = () => {
    const hash = window.location.hash
    isOpen.value = Object.values(tabs).includes(hash)
    if (has(invertTabs, hash)) {
      currentPage.value = invertTabs[hash]
    }
  }

  const setSettingTab = (tab: keyof typeof tabs) => {
    if (has(tabs, tab)) {
      window.location.hash = tabs[tab]
      updateComponent()
    }
  }

  onMounted(() => {
    window.onhashchange = updateComponent
    updateComponent()
  })

  onUnmounted(() => {
    window.onhashchange = null
  })

  const openSettings = () => {
    setSettingTab('index')
    isOpen.value = true
  }

  const closeSettings = () => {
    isOpen.value = false
    const { query: currentQuery } = route
    const { fieldId, id, ...cleanQuery } = currentQuery

    router.replace({ query: cleanQuery })
  }

  watch(
    () => isOpen.value,
    (open) => {
      if (!open) {
        history.pushState(
          '',
          document.title,
          window.location.pathname + window.location.search
        )
      }
    }
  )

  return {
    isOpen,
    preventClose,
    currentPage: readonly(currentPage),
    setSettingTab,
    openSettings,
    onMounted,
    onUnmounted,
    closeSettings,
  }
}

export const useBoardSettingsNavigator = createSharedComposable(
  $useBoardSettingsNavigator
)

/**
 * BoardMember shared state
 */
const $useBoardMemberSharedState = () => {
  const currentBoardMember = ref<BoardMember | null | false>(false)

  const isBoardMember = computed(() => {
    if (
      currentBoardMember.value === false ||
      currentBoardMember.value === undefined
    ) {
      return null
    }

    if (currentBoardMember.value === null) {
      return false
    }

    return currentBoardMember.value.id ? true : false
  })

  const { onResult, load, refetch } = useGetBoardMemberLazyQuery()

  const handleError = (error: unknown) => {
    if (error instanceof ApolloError) {
      const { graphQLErrors } = error
      const message = graphQLErrors.length
        ? graphQLErrors[0].message
        : error.message

      if (message.includes('not found')) {
        currentBoardMember.value = null
        return
      } else {
        currentBoardMember.value = false
      }
    }

    handleSingleGraphQLError(error)
  }

  const loadBoardMember = async (boardId: string) => {
    try {
      await load(null, { boardId })
    } catch (error) {
      handleError(error)
    }
  }

  const refetchBoardMember = async (boardId: string) => {
    try {
      await refetch({ boardId })
    } catch (error) {
      handleError(error)
    }
  }

  onResult((result) => {
    currentBoardMember.value = result.data?.boardMember
  })

  return {
    currentBoardMember,
    isBoardMember,
    loadBoardMember,
    refetchBoardMember,
    onBoardMemberResult: onResult,
  }
}

export const useBoardMemberSharedState = createSharedComposable(
  $useBoardMemberSharedState
)

/**
 * BoardTimeline shared state
 */
const $useBoardTimelineSharedState = () => {
  const tableColumnWidth = reactive<Record<string, number>>({
    name: MIN_COLUMN_RESIZER_WIDTH * 2,
  })
  const rangeView = ref(TimeLineRangeView.WEEKS)

  const getTableColumnWidth = (key: string) => {
    return tableColumnWidth[key] || MIN_COLUMN_RESIZER_WIDTH
  }

  return {
    tableColumnWidth,
    rangeView,
    getTableColumnWidth,
  }
}

export const useBoardTimelineSharedState = createSharedComposable(
  $useBoardTimelineSharedState
)

const $useBoardColumnFields = (params: {
  boardId: string
  activeView: BoardView
}) => {
  const { boardData, viewOptions, availableSortableColumns, sortBy } =
    useBoardSharedState()
  const { numColumns } = useListViewSharedState()

  const filteredColumns = ref<ColumnType[]>([])

  const customFields = computed(() => {
    const boardFields = uniq(
      boardData.value.taskTypes.flatMap((taskType) => taskType.fields)
    )
    return boardFields.map((field) => ({
      key: field.id,
      label: field.name,
      field: field.type,
      boardId: params.boardId,
      isFixedType: false,
      settingsPack: Array.isArray(field?.taskTypes)
        ? field.taskTypes[0]?.settingsPack?.name
        : undefined,
    }))
  })

  const defaultColumns = computed(() => {
    return [
      {
        key: 'name',
        label: 'Task name',
        lock: true,
      },
      {
        key: FieldType.STATUS,
        label: 'Status',
        field: FieldType.STATUS,
        isFixedType: true,
      },
      {
        key: FieldType.ASSIGNEE,
        label: 'Assignee',
        field: FieldType.ASSIGNEE,
        isFixedType: true,
      },
      {
        key: FieldType.TIME_RANGE,
        label: 'Dates',
        field: FieldType.TIME_RANGE,
        isFixedType: true,
      },
      {
        key: FieldType.LABEL,
        label: 'Labels',
        field: FieldType.LABEL,
        isFixedType: true,
      },
      {
        key: FieldType.TASK_PARENT,
        label: 'Task Parent',
        field: FieldType.TASK_PARENT,
        isFixedType: true,
        defaultViews: [BoardView.LIST],
      },
    ]
  })

  const totalColumns = computed(() => {
    return [...defaultColumns.value, ...customFields.value] as ColumnType[]
  })

  const columns = computed(() => {
    const listSortableFields = [
      FieldType.TEXT,
      FieldType.NUMBER,
      FieldType.DATE,
      FieldType.DROPDOWN,
      FieldType.STATUS,
      FieldType.ASSIGNEE,
      FieldType.TIME_RANGE,
    ]
    const viewOptionsList = viewOptions.value?.[params.activeView]

    const mapColumn = (column: ColumnType) => ({
      ...column,
      boardId: params.boardId,
      isFixedType: column?.isFixedType ?? false,
      isHidden: viewOptionsList?.hidden?.includes(column?.key as string),
      isSortable: listSortableFields.includes(column?.field as FieldType),
      width: viewOptionsList?.width?.find(
        (field) => field.field === column?.key
      )?.value,
      direction:
        viewOptionsList?.sortBy?.field === column?.key
          ? viewOptionsList?.sortBy?.value
          : undefined,
      settingsPack: column?.settingsPack,
    })

    if (viewOptionsList?.order) {
      const leftOverColumns = totalColumns.value.reduce((acc, column) => {
        if (!viewOptionsList?.order?.includes(column.key)) {
          acc.push(mapColumn(column))
        }

        return acc
      }, [] as ColumnType[])

      const viewOptionsColumns = viewOptionsList?.order.reduce(
        (acc: ColumnType[], key: string) => {
          const column = totalColumns.value.find((field) => field.key === key)
          if (column) acc.push(mapColumn(column))

          return acc
        },
        []
      )

      return [...viewOptionsColumns, ...leftOverColumns]
    }

    return totalColumns.value.map(mapColumn)
  })

  watch(
    columns,
    (newColumns) => {
      availableSortableColumns.splice(
        0,
        availableSortableColumns.length,
        ...newColumns.filter(
          (column) => !column.isHidden && !column.readOnly && column.isSortable
        )
      )
      filteredColumns.value = newColumns.reduce((acc, column) => {
        if (!column.isHidden) {
          acc.push({
            ...column,
            boardId: column.boardId || params.boardId,
          })
        }

        return acc
      }, [] as ColumnType[])
      const sortableColumn = newColumns.find(
        (column) => column.isSortable && !!column.direction
      )
      extend(sortBy, {
        field: sortableColumn?.field || '',
        direction: sortableColumn?.direction || '',
        key: sortableColumn?.key || '',
      })
    },
    {
      immediate: true,
    }
  )

  watch(
    () => filteredColumns.value,
    (value) => {
      if (value) {
        numColumns.value = value.length
      }
    },
    {
      immediate: true,
    }
  )

  return {
    columns,
    filteredColumns,
  }
}

export const useBoardColumnFields = createSharedComposable(
  $useBoardColumnFields
)

/**
 * BoardImportTasks shared state
 */
export const $useBoardImportTasksSharedState = () => {
  const importFiles = ref<
    {
      boardId: string
      fileName: string
      total: number
      status: string
      remain: number
    }[]
  >([])
  const showButtonImport = ref(false)
  return {
    showButtonImport,
    importFiles,
  }
}

export const useBoardImportTasksSharedState = createSharedComposable(
  $useBoardImportTasksSharedState
)
