<template>
  <UPopover
    v-model:open="open"
    :ui="{
      width: 'w-[280px]',
      wrapper: 'h-full',
      trigger: `h-full flex items-center ${
        disabled ? '!cursor-not-allowed' : ''
      }`,
    }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
    :disabled="disabled"
    v-bind="$attrs"
  >
    <template #panel>
      <div class="flex items-center justify-between px-3 py-2">
        <p class="font-semibold text-sm text-gray-900">{{ heading }}</p>
        <UButton
          size="xs"
          icon="i-heroicons-x-mark"
          color="gray"
          variant="ghost"
          @click="open = false"
        />
      </div>
      <div v-if="!tasks.length" class="mb-4">
        <template v-if="$slots.empty">
          <slot name="empty" />
        </template>
        <p v-else class="text-sm text-gray-700 px-4">{{ emptyMessage }}</p>
      </div>
      <div v-else class="pl-2">
        <div class="pl-2 pr-4">
          <UInput
            v-model="search"
            icon="i-heroicons-magnifying-glass"
            size="sm"
            autofocus
            :placeholder="searchPlaceholder"
          />
        </div>
        <div
          class="mt-3 pb-2 max-h-[18.25rem] overflow-auto pr-1 scroll-stable minimal-scrollbar space-y-1"
        >
          <div
            v-for="(task, index) in filterTasks"
            ref="optionRefs"
            :key="task.id"
            :class="[
              'flex items-center text-gray-900 justify-between px-2 py-1.5 cursor-pointer rounded-md hover:bg-gray-100',
              {
                'bg-gray-100':
                  task.id === selectedTaskId || currentFocusTaskIndex === index,
              },
            ]"
            @mouseenter="currentFocusTaskIndex = index"
            @mouseleave="currentFocusTaskIndex = -1"
            @click="onSelect(task)"
          >
            <div class="flex items-center gap-1.5 truncate">
              <TaskTypeIcon
                size="sm"
                :background="task.type.background"
                :icon="task.type.icon"
              />
              <span class="text-sm text-gray-400"> #{{ task.number }} </span>
              <span
                class="text-sm text-gray-700 truncate"
                data-test="popover-module-name"
              >
                {{ task.name }}
              </span>
            </div>
            <Icon v-if="task.id === selectedTaskId" name="heroicons:check" />
          </div>
        </div>
        <slot name="footer" />
      </div>
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
import type { TaskLevel } from '#task/constant'
import type { TaskItem } from '#task/types'

const props = defineProps({
  selectedTaskId: {
    type: String as PropType<string | null>,
  },
  boardId: {
    type: String,
    required: true,
  },
  heading: {
    type: String,
    default: '',
  },
  acceptLevels: {
    type: Array as PropType<TaskLevel[]>,
    default: () => [],
  },
  searchPlaceholder: {
    type: String,
    default: 'Search...',
  },
  emptyMessage: {
    type: String,
    default: 'No tasks found',
  },
  closeOnSelected: {
    type: Boolean,
    default: true,
  },
  filter: {
    type: Function as PropType<(task: TaskItem) => boolean>,
    default: () => () => true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['change', 'remove'])

const optionRefs = ref<HTMLElement[]>([])
const currentFocusTaskIndex = ref(-1)
const open = ref(false)
const search = ref('')

const { tasks: allTasks } = useBoardTasksLoader()

const tasks = computed(() => {
  if (!open.value) {
    return []
  }

  let tasks = allTasks.value
  if (props.acceptLevels.length) {
    tasks = tasks.filter((task) => props.acceptLevels.includes(task.level))
  }

  if (props.filter) {
    tasks = tasks.filter(props.filter)
  }

  return tasks
})

const filterTasks = computed(() => {
  return tasks.value.filter((task) =>
    task.name.toLowerCase().includes(search.value.toLowerCase())
  )
})

const onSelect = (task: TaskItem | null) => {
  emit('change', task)
  if (props.closeOnSelected) {
    open.value = false
  }
}

const scrollToView = (index: number) => {
  if (optionRefs.value[index]) {
    optionRefs.value[index].scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

defineExpose({
  open: () => (open.value = true),
  close: () => (open.value = false),
})

defineShortcuts({
  arrowup: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusTaskIndex.value > 0) {
        currentFocusTaskIndex.value--
      } else {
        currentFocusTaskIndex.value = filterTasks.value?.length - 1
      }

      scrollToView(currentFocusTaskIndex.value)
    },
  },
  arrowdown: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusTaskIndex.value < filterTasks.value?.length - 1) {
        currentFocusTaskIndex.value++
      } else {
        currentFocusTaskIndex.value = 0
      }

      scrollToView(currentFocusTaskIndex.value)
    },
  },
  enter: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (
        currentFocusTaskIndex.value >= 0 &&
        filterTasks.value![currentFocusTaskIndex.value]
      ) {
        onSelect(filterTasks.value[currentFocusTaskIndex.value])
      }
    },
  },
  escape: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      open.value = false
    },
  },
})

watch(
  () => open.value,
  (isOpen) => {
    if (isOpen) {
      currentFocusTaskIndex.value = -1
    }
  }
)
</script>
