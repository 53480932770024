import { VueRenderer } from '@tiptap/vue-3'
import tippy, { type Instance, type GetReferenceClientRect } from 'tippy.js'
import type { Editor } from '@tiptap/core'
import Mention from '@tiptap/extension-mention'
import MentionList from '#core/components/editor/extensions/MentionList.vue'
import type { BoardMember } from '#auth/types'

const renderItems = () => {
  let component: VueRenderer | null = null
  let popup: Instance | null = null

  return {
    onStart: (props: {
      editor: Editor
      clientRect: GetReferenceClientRect
    }) => {
      component = new VueRenderer(MentionList, {
        props,
        editor: props.editor,
      })
      if (!props.clientRect) {
        return
      }

      const popups = tippy('body', {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'auto-end',
      })
      ;[popup] = popups
    },
    onUpdate: (props: {
      editor: Editor
      clientRect: GetReferenceClientRect
    }) => {
      component?.updateProps(props)
      if (popup) {
        popup.setProps({
          getReferenceClientRect: props.clientRect,
        })
      }
    },
    onKeyDown: (props: { event: KeyboardEvent }) => {
      if (props.event.key === 'Escape') {
        popup?.hide()

        return true
      }

      return component?.ref?.onKeyDown(props.event)
    },
    onExit: () => {
      popup?.destroy()
      component?.destroy()
    },
  }
}

const getMentionItems = (boardMembers: BoardMember[]) => ({ query }: { query: string }) => {
  return boardMembers.filter((member) => {
    return member.user?.fullName.toLowerCase().includes(query.toLowerCase()) || member.user?.email.toLowerCase().includes(query.toLowerCase())
  })
}

export default (boardMembers: BoardMember[]) => Mention.configure({
  HTMLAttributes: {
    class: 'text-primary-500 text-sm font-medium',
  },
  suggestion: {
    items: getMentionItems(boardMembers),
    render: renderItems,
  },
})
