import {
  ApolloClient,
  from,
  type FetchPolicy,
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { apolloCache } from '#core/apollo_cache'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const { accessToken } = storeToRefs(useAuthStore())
  const { $apollo } = useNuxtApp()

  const socketId = [Date.now(), uuid()].join('_')

  const authLink = setContext(async (_, { headers = {} }) => {
    if (accessToken.value) {
      extend(headers, {
        'Authorization': `JWT ${accessToken.value}`,
        'X-Socket-ID': socketId,
      })
    }

    return {
      headers,
    }
  })

  const httpLink = authLink.concat(
    createUploadLink({
      uri: String(runtimeConfig.public.graphqlApiEndpoint),
    })
  )

  const errorLink = onError((err) => {
    nuxtApp.callHook('apollo:error', err)
  })

  const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: apolloCache,
    connectToDevTools: true,
    ssrMode: false,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-and-network' as FetchPolicy,
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  })

  $apollo.clients.default = client

  return {
    provide: {
      socketId,
    },
  }
})
