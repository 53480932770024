<template>
  <UPopover
    ref="popoverRef"
    v-model:open="open"
    :ui="{
      width: 'min-w-64 max-w-[280px]',
      trigger: disabled ? '!cursor-not-allowed' : '',
    }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
    v-bind="$attrs"
  >
    <template #panel>
      <div
        v-if="showHeader"
        class="flex items-center justify-between px-4 pt-3"
      >
        <p class="font-semibold text-sm text-gray-900">Change task type</p>
        <UButton
          size="xs"
          icon="i-heroicons-x-mark"
          color="gray"
          variant="ghost"
          @click.prevent.stop="open = false"
        />
      </div>
      <div class="px-4 pt-2">
        <UInput
          v-model="search"
          icon="i-heroicons-magnifying-glass"
          size="sm"
          autofocus
          placeholder="Search task type"
          :ui="{
            base: '!rounded-md',
          }"
        />
      </div>
      <div class="max-h-[17.75rem] overflow-y-auto scroll-stable minimal-scrollbar mt-2">
        <div
          v-for="(taskTypesGroup, index) in taskTypesGroups"
          :key="index"
          class="border-b pl-2 pr-0.5 py-2 space-y-0.5 last:border-0"
        >
          <div
            v-for="(taskType, typeIndex) in taskTypesGroup"
            ref="optionRefs"
            :key="taskType.id"
            :class="[
              'flex items-center text-gray-900 justify-between px-2 py-1.5 cursor-pointer hover:bg-gray-100 rounded-md gap-1.5',
              {
                'bg-gray-100': taskType.id === selectedTaskTypeId || currentTaskTypeId === taskType.id,
              },
            ]"
            @mouseenter="currentFocusTaskTypeIndex = typeIndex"
            @mouseleave="currentFocusTaskTypeIndex = -1"
            @click.prevent.stop="onSelect(taskType)"
          >
            <div class="flex items-center gap-1.5" data-test="task-type-item">
              <TaskTypeIcon
                :background="taskType.background"
                :icon="taskType.icon"
                size="sm"
              />
              <Tooltip :text="taskType.name" arrow-class="!top-[unset]">
                <template #default="{ getTextRef }">
                  <span :ref="getTextRef" class="text-sm line-clamp-1 break-all" data-test="task-type-select-popover-name">{{ taskType.name }}</span>
                </template>
              </Tooltip>
            </div>
            <div class="flex gap-1.5 items-center">
              <Tooltip v-if="taskType.settingsPackId" :text="getSettingsPackName(taskType.settingsPackId)" arrow-class="!top-[unset]">
                <IconSettingPack />
              </Tooltip>
              <Icon
                v-if="taskType.id === selectedTaskTypeId"
                name="heroicons:check"
                :size="20"
                class="min-w-5"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <slot
      :selected="selectedTaskType"
      :open="() => (open = true)"
      :close="() => (open = false)"
      :is-open="open"
    />
  </UPopover>
</template>

<script lang="ts" setup>
import { flatMap, groupBy } from 'lodash-es'
import { TaskLevel } from '#task/constant'
import type { BaseTaskType } from '#task-type/types'

const props = defineProps({
  selectedTaskTypeId: {
    type: String,
  },
  parentId: {
    type: String,
    required: true,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  acceptLevels: {
    type: Array as PropType<TaskLevel[]>,
    default: () => [],
  },
  closeOnSelected: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['change'])

const { boardData } = useBoardSharedState()
const { currentBoard } = useWorkspaceSharedState()

const variables = reactive({
  parentId: props.parentId,
  source: props.source,
  loadFull: true,
})

const { result, load } = useTaskTypesLazyQuery(variables)

const optionRefs = ref<HTMLElement[]>([])
const currentFocusTaskTypeIndex = ref(-1)
const open = ref(false)
const search = ref('')
const currentTaskTypeId = ref('')
const popoverRef = ref()

const isCurrentBoard = computed(() => currentBoard.value.id === props.parentId)

const flattenedTaskTypes = computed(() => flatMap(taskTypesGroups.value))

const taskTypes = computed(() => {
  let taskTypes = conditionalValue<BaseTaskType[]>(
    isCurrentBoard.value,
    boardData.value.taskTypes,
    result.value?.taskTypes as BaseTaskType[],
    []
  )
  if (isCurrentBoard.value) {
    taskTypes = taskTypes.filter((taskType) => {
      return (
        (taskType.level === TaskLevel.MODULE && !taskType.settingsPackId) ||
        taskType.level === TaskLevel.TASK ||
        (taskType.level === TaskLevel.SUBTASK && !taskType.settingsPackId)
      )
    })
  }

  if (props.acceptLevels.length) {
    return taskTypes.filter((taskType) =>
      props.acceptLevels.includes(taskType.level)
    )
  }

  return taskTypes
})
const taskTypesGroups = computed(() =>
  groupBy(
    taskTypes.value.filter((type) =>
      type.name.toLowerCase().includes(search.value.toLowerCase())
    ),
    'level'
  )
)
const selectedTaskType = computed(() =>
  taskTypes.value.find((taskType) => taskType.id === props.selectedTaskTypeId)
)

const onSelect = (taskType: BaseTaskType) => {
  emit('change', taskType)
  if (props.closeOnSelected) {
    open.value = false
  }
}

const scrollToView = (index: number) => {
  if (optionRefs.value[index]) {
    optionRefs.value[index].scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

const getSettingsPackName = (id: string) => {
  const settingsPacks = boardData.value.settingsPacks
  return settingsPacks?.find((settingsPack) => settingsPack.id === id)?.name
}

defineExpose({
  isOpen: computed(() => open.value),
  open: () => {
    open.value = true
  },
  close: () => {
    open.value = false
  },
})

defineShortcuts({
  arrowup: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusTaskTypeIndex.value > 0) {
        currentFocusTaskTypeIndex.value--
      } else {
        currentFocusTaskTypeIndex.value = taskTypes.value.length - 1
      }

      scrollToView(currentFocusTaskTypeIndex.value)
      currentTaskTypeId.value = flattenedTaskTypes.value[currentFocusTaskTypeIndex.value]?.id
    },
  },
  arrowdown: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusTaskTypeIndex.value < taskTypes.value.length - 1) {
        currentFocusTaskTypeIndex.value++
      } else {
        currentFocusTaskTypeIndex.value = 0
      }

      scrollToView(currentFocusTaskTypeIndex.value)
      currentTaskTypeId.value = flattenedTaskTypes.value[currentFocusTaskTypeIndex.value]?.id
    },
  },
  enter: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusTaskTypeIndex.value !== -1) {
        onSelect(flattenedTaskTypes.value[currentFocusTaskTypeIndex.value])
      }
    },
  },
})

watch(
  () => open.value,
  async (isOpen) => {
    if (isOpen) {
      extend(variables, {
        parentId: props.parentId,
      })
      if (!isCurrentBoard.value) {
        await load()
        nextTick(() => {
          popoverRef.value.popperInstance?.forceUpdate()
        })
      }
    }
  }
)
</script>
