import { first, memoize, pick } from 'lodash-es'
import { ColorPresets } from '#core/constant'
import type { ColorPreset } from '#core/types'

export const sleep = (ms: number = 0) =>
  new Promise((resolve) => setTimeout(resolve, ms))

export const evaluativeFn = (value: unknown) => {
  return value instanceof Function ? value() : value
}

export const uuid = () => {
  const tempUrl = URL.createObjectURL(new Blob())
  const uuid = tempUrl.toString()
  URL.revokeObjectURL(tempUrl)
  return uuid.substr(uuid.lastIndexOf('/') + 1)
}

export const safeParseStringToJSON = <T = unknown>(
  str: string,
  defaultValue: T = [] as T
): T => {
  try {
    return JSON.parse(str) as T
  } catch (e) {
    return defaultValue
  }
}

export const slugify = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

export const extend = Object.assign

export const merge = (
  target: Record<string, unknown>,
  source: Record<string, unknown>
) => {
  return {
    ...target,
    ...source,
  }
}

export const conditionalValue = <T = unknown>(
  condition: boolean,
  valueTrue: T,
  valueFalse: T,
  fallback: T | null = null
): T => {
  return condition ? valueTrue : valueFalse ?? (fallback as T)
}

export const colorPresetMap = ColorPresets.reduce((acc, preset) => {
  acc[preset.name] = preset
  return acc
}, {} as Record<string, ColorPreset>)

export const getColorPreset = memoize(
  (name: string, preferLight = false) => {
    let preset = colorPresetMap[name]
    if (!preset) {
      preset = first(ColorPresets)!
    }

    preset = pick(preset, ['backgroundColor', 'color']) as ColorPreset
    if (preferLight && !name.startsWith('light')) {
      preset.color = '#FFF'
    }

    return preset
  },
  (name: string, preferLight: boolean) => [name, preferLight].join()
)

export const isUrl = (url: string): boolean => {
  return url.startsWith('http://') || url.startsWith('https://')
}

export const isID = (uuid: string): boolean => {
  if (!uuid) {
    return false
  }

  return /^[0-9a-zA-Z]{1,8}-[0-9a-zA-Z]{1,4}-[0-9a-zA-Z]{1,4}-[0-9a-zA-Z]{1,4}-[0-9a-zA-Z]{1,12}$/.test(
    uuid
  )
}

export const capitalizeFirstChar = (str: string): string => {
  if (!str) return str

  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const blurClickCheckbox = () => {
  nextTick(() => {
    const activeElement = document.activeElement as HTMLElement
    activeElement.blur()
  })
}

export const calculateOffset = (a: number, b: number) => {
  return a > b ? a - b : b - a
}
