import { LABEL_FRAGMENT } from '#label/fragment'
import { STATUS_FRAGMENT } from '#status/fragment'
import { TASK_TYPE_FRAGMENT } from '#task-type/fragment'
import { BOARD_BASE_FRAGMENT } from '#board/fragment'

export const SETTINGS_PACK_FRAGMENT = gql`
  ${TASK_TYPE_FRAGMENT}
  ${STATUS_FRAGMENT}
  ${LABEL_FRAGMENT}
  ${BOARD_BASE_FRAGMENT}
  fragment SettingsPack on SettingsPackType {
    id
    name
    description
    boards {
      ...BaseBoard
    }
    taskTypes {
      ...TaskType
    }
    statuses {
      ...Status
    }
    labels {
      ...Label
    }
  }
`
