import { clone, has } from 'lodash-es'
import type { BaseBoard } from '#board/types'
import type { CurrentTask } from '#task/types'
import type { BaseWorkspace } from '#workspace/types'
import type { SettingsPack } from '#settings-pack/types'
import { TaskViewMode } from '#task/constant'

export const useWorkspaceSharedState = () => {
  const toast = useToast()

  const currentWorkspace = useState<BaseWorkspace>(
    'currentWorkspace',
    () => ({} as BaseWorkspace)
  )
  const currentBoard = useState<BaseBoard>(
    'currentBoard',
    () => ({} as BaseBoard)
  )
  const currentTask = useState<CurrentTask>(
    'currentTask',
    () => ({} as CurrentTask)
  )
  const currentSettingsPack = useState<SettingsPack>(
    'currentSettingsPack',
    () => ({} as SettingsPack)
  )

  const setCurrentWorkspace = (payload: BaseWorkspace) => {
    currentWorkspace.value = payload
  }

  const setCurrentBoard = (payload: BaseBoard) => {
    currentBoard.value = payload
  }

  const setCurrentTask = (payload: CurrentTask, shouldUpdateUrl = false) => {
    // Don't open optimistic tasks in the UI
    if (has(payload, 'id') && isOptimisticId(payload.id)) {
      toast.add({
        title: 'Can\'t open task',
        description: 'This task is being created, please wait for the server to respond before opening it.',
        color: 'yellow',
      })
      return
    }

    const nextTask = clone(payload)
    if (!nextTask.viewMode) {
      nextTask.viewMode = currentTask.value.viewMode || TaskViewMode.MODAL
    }

    currentTask.value = nextTask
    if (shouldUpdateUrl && nextTask.handle && import.meta.client) {
      const { pathname } = window.location
      const newPath = pathname.replace(/\/t\/[^/]+/, `/t/${nextTask.handle}`)
      window.history.pushState(null, '', newPath)
    }
  }

  const setCurrentSettingsPack = (payload: SettingsPack) => {
    currentSettingsPack.value = payload
  }

  return {
    currentWorkspace,
    currentBoard,
    currentTask,
    currentSettingsPack,
    setCurrentWorkspace,
    setCurrentBoard,
    setCurrentTask,
    setCurrentSettingsPack,
  }
}
