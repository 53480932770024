import {
  TimelineDayHeader,
  TimelineMonthHeader,
  TimelineWeekHeader,
  TimelineYearHeader,
} from '#components'

export enum BoardVisibility {
  PRIVATE = 'PRIVATE',
  WORKSPACE = 'WORKSPACE',
}

export enum HomeBoardTab {
  ALL = 'all',
  STARRED = 'starred',
}

export enum BoardView {
  BOARD = 'board',
  LIST = 'list',
  TIMELINE = 'timeline',
}

export const BoardVisibilityOptions = [
  {
    value: BoardVisibility.PRIVATE,
    name: 'Private',
    icon: 'i-heroicons-lock-closed',
    description:
      'Only board members can see this board. Workspace admins can close the board or remove member.',
  },
  {
    value: BoardVisibility.WORKSPACE,
    name: 'Workspace',
    icon: 'i-heroicons-users',
    description: 'All member of the Leanbase workspace can see and edit this board.',
  },
]

export enum BackgroundTypes {
  PHOTO = 'PHOTO',
  COLOR = 'COLOR',
  CUSTOM = 'CUSTOM',
}

export const BackgroundColors = [
  'chill-vibe',
  'violet',
  'peach-pink',
  'sunset-blush',
  'power',
  'deep-ocean',
  'ocean-mist',
  'lean',
  'midnight-flow',
  'default',
]

export enum GroupBy {
  SECTION = 'SECTION',
  MODULE = 'MODULE',
}

export enum TimeLineRangeView {
  DAYS,
  WEEKS,
  MONTHS,
  QUATERS,
  YEARS,
}

export enum SectionActionKey {
  STATUS = 'status',
  NAME = 'name',
  ARCHIVE = 'archive',
}

export enum TaskActionKey {
  CHANGE_TASK_TYPE = 'change-task-type',
  SET_MODULE = 'set-module',
  MOVE = 'move',
  CONVERT_TO_TASK = 'convert-to-task',
  COPY = 'copy',
  ARCHIVE = 'archive',
}

export enum MultiTaskMoreActionKey {
  EDIT_STATUS = 'edit-status',
  ADD_LABELS = 'add-labels',
  CHANGE_TASK_TYPE = 'change-task-type',
  SET_MODULE = 'set-module',
  COPY = 'copy',
}

export enum BackgroundImageQuality {
  RAW = 'raw',
  FULL = 'full',
  REGULAR = 'regular',
  SMALL = 'small',
  THUMB = 'thumb',
}

export const HeaderComponent = {
  [TimeLineRangeView.DAYS]: TimelineDayHeader,
  [TimeLineRangeView.WEEKS]: TimelineWeekHeader,
  [TimeLineRangeView.MONTHS]: TimelineMonthHeader,
  [TimeLineRangeView.QUATERS]: TimelineMonthHeader,
  [TimeLineRangeView.YEARS]: TimelineYearHeader,
}

export const ModifyDayMap = {
  [TimeLineRangeView.DAYS]: 2,
  [TimeLineRangeView.WEEKS]: 4,
  [TimeLineRangeView.MONTHS]: 12,
  [TimeLineRangeView.QUATERS]: 18,
  [TimeLineRangeView.YEARS]: 24,
}

export const ColWidthMap = {
  [TimeLineRangeView.DAYS]: 40,
  [TimeLineRangeView.WEEKS]: 24,
  [TimeLineRangeView.MONTHS]: 8,
  [TimeLineRangeView.QUATERS]: 4,
  [TimeLineRangeView.YEARS]: 2,
}

export const OtherPlatforms = [
  'jira',
  'asana',
  'trello',
  'clickup',
  'google-sheets',
  'monday',
  'airtable',
  'wrike',
  'smartsheet',
]

export const LineLimitImportFile = 2000
export const LimitRenderImportTask = 25

export enum IMPORT_TASK_ERROR {
  MISSING_MATCHING_FIELD = 'Missing matching field',
  MISSING_REQUIRED_FIELD = 'Missing required field',
}
