<template>
  <div v-if="loading" class="pl-16 py-6 pr-6 min-h-96">
    <div class="grid grid-cols-3">
      <div class="col-span-3">
        <USkeleton class="h-8 w-20" />
      </div>
      <div class="col-span-2">
        <USkeleton class="h-8 w-96 mt-4" />
        <USkeleton class="h-6 w-full mt-6" />
        <USkeleton class="h-6 w-72 mt-2" />
        <USkeleton class="h-6 w-24 mt-2" />
      </div>
      <div class="col-span-1 flex flex-col items-end gap-2 pt-6">
        <USkeleton class="h-8 w-44" />
        <USkeleton class="h-8 w-44" />
        <USkeleton class="h-8 w-44" />
      </div>
    </div>
  </div>
  <div
    v-else-if="taskDetail"
    ref="taskDetailDropZoneRef"
    class="relative"
    data-test="task-details"
  >
    <div v-if="taskDetail.cover" class="h-44 relative">
      <div
        class="absolute h-full w-full left-0 flex items-center justify-center"
        :style="{
          backgroundColor: taskDetail.coverBackgroundColor || '',
        }"
      >
        <img
          :src="taskDetail.cover"
          class="max-h-full max-w-full object-contain"
        />
      </div>
    </div>
    <div class="relative">
      <UAlert
        v-if="
          taskDetail.closed
            && !taskDetail.parentClosed
            && !taskDetail.sectionClosed
        "
        icon="i-heroicons-archive-box"
        class="bg-gray-100 dark:bg-gray-900 !overflow-visible rounded-none sticky top-0 pl-16"
        :ui="{
          title: 'text-sm text-gray-900',
          icon: { base: 'size-5' },
          gap: 'gap-x-3',
        }"
        title="This card is archived."
        data-test="closed"
      />
      <UAlert
        v-if="taskDetail.parentClosed && !taskDetail.sectionClosed"
        icon="i-heroicons-archive-box"
        class="bg-gray-100 dark:bg-gray-900 !overflow-visible rounded-none sticky top-0 pl-16"
        :ui="{
          title: 'text-sm text-gray-900',
          icon: { base: 'size-5' },
          gap: 'gap-x-3',
        }"
        title="This task belongs to an archived module. Remove module to resend to board."
        data-test="closed"
      />
      <UAlert
        v-if="taskDetail.sectionClosed"
        icon="i-heroicons-archive-box"
        class="bg-gray-100 dark:bg-gray-900 !overflow-visible rounded-none sticky top-0 pl-16"
        :ui="{
          title: 'text-sm text-gray-900',
          icon: { base: 'size-5' },
          gap: 'gap-x-3',
        }"
        title="This task belongs to an archived section. Change or unarchive section to resend to board"
        data-test="closed"
      />
      <img
        src="/assets/images/archive-background.png"
        width="100%"
        class="absolute inset-0 object-cover h-full"
      />
    </div>

    <UButton
      variant="solid"
      color="white"
      class="absolute right-3 top-3 z-20"
      size="sm"
      icon="heroicons:x-mark"
      :ui="{
        color: {
          white: {
            solid: 'shadow-none ring-white',
          },
        },
      }"
      data-test="close-task-detail"
      @click.prevent.stop="$emit('close')"
    />
    <div
      class="pl-16 pb-6 pr-6 z-10"
      :class="taskDetail.closed || taskDetail.cover ? 'pt-4' : 'pt-6'"
    >
      <div class="flex items-center mb-4 gap-1 -translate-x-1 z-10 relative">
        <!-- Parent Task -->
        <div
          v-if="taskDetail.parent"
          class="flex items-center"
          @click="onOpenParentTask"
        >
          <Tooltip :text="taskDetail.parent.name">
            <template #default="{ getTextRef }">
              <UButton
                v-if="taskDetail.level === TaskLevel.TASK"
                color="white"
                variant="ghost"
                size="2xs"
                class="items-center flex hover:bg-gray-100 py-1 px-1.5"
                data-test="task-parent"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
              >
                <template #leading>
                  <TaskTypeIcon
                    transparent
                    icon-class="text-gray-700"
                    :size-wrapper="false"
                    :icon="taskDetail.parent.type?.icon"
                  />
                </template>
                <span
                  :ref="getTextRef"
                  class="text-xs font-medium line-clamp-1 break-all max-w-72 leading-4"
                >
                  {{ taskDetail.parent.name }}
                </span>
              </UButton>
              <UButton
                v-else
                color="white"
                size="2xs"
                variant="ghost"
                class="hover:bg-gray-100 py-1 px-1.5"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
              >
                <template #leading>
                  <TaskTypeIcon
                    transparent
                    icon-class="text-gray-700"
                    :size-wrapper="false"
                    :icon="taskDetail.parent?.type?.icon"
                  />
                </template>
                <span
                  :ref="getTextRef"
                  class="text-xs font-medium line-clamp-1 break-all max-w-72 leading-4"
                >
                  {{ taskDetail.parent.name }}
                </span>
              </UButton>
            </template>
          </Tooltip>
          <span class="text-gray-400 ml-1">/</span>
        </div>
        <!-- Current Task -->
        <TaskTypeSelectPopover
          v-if="taskDetail?.type"
          source="board"
          :parent-id="taskDetail.boardId"
          class="flex items-center"
          :selected-task-type-id="taskDetail.typeId"
          :accept-levels="[taskDetail.level]"
          @change="updateTask(taskDetail.id, { type: $event.id })"
        >
          <template #default="{ open, isOpen }">
            <UButton
              size="2xs"
              color="white"
              variant="ghost"
              class="hover:bg-gray-100 flex items-center p-0 max-h-6 group gap-x-0"
              :disabled="!can('dashboard.data.managing_tasks_sections')"
              @click.prevent.stop="
                taskDetail.level === TaskLevel.TASK && open()
              "
            >
              <Tooltip
                :text="`${taskDetail.type.name}: Change task type`"
                :prevent="taskDetail.type.level !== TaskLevel.TASK || isOpen"
              >
                <div
                  :class="[
                    'flex items-center gap-1 py-1 px-1.5  rounded-l-md',
                    {
                      'hover:bg-gray-200':
                        taskDetail.type.level === TaskLevel.TASK,
                    },
                  ]"
                >
                  <div class="relative">
                    <div
                      :class="[
                        'visible',
                        {
                          'group-hover:invisible':
                            taskDetail.type.level === TaskLevel.TASK,
                        },
                      ]"
                    >
                      <TaskTypeIcon
                        transparent
                        icon-class="text-gray-700"
                        :size-wrapper="false"
                        :icon="taskDetail.type.icon"
                      />
                    </div>
                    <div
                      v-if="taskDetail.type.level === TaskLevel.TASK"
                      class="h-full flex items-center absolute inset-0 invisible group-hover:visible"
                    >
                      <UIcon
                        name="i-heroicons-chevron-down-20-solid"
                        :size="16"
                        class="absolute"
                      />
                    </div>
                  </div>
                  <div class="text-xs font-medium leading-4">
                    <span data-test="task-type">
                      {{ taskDetail.type.name }}
                    </span>
                    #{{ taskDetail.number }}
                  </div>
                </div>
              </Tooltip>
              <template #trailing>
                <UTooltip
                  v-if="taskDetail.number"
                  text="Copy ID number"
                  :popper="{ placement: 'top', arrow: true }"
                  class="h-full"
                >
                  <UButton
                    size="xs"
                    class="px-1 py-0 flex items-center rounded-l-none hover:bg-gray-200 bg-gray-100 h-full group-hover:visible invisible gap-x-1"
                    variant="ghost"
                    color="white"
                    @click.prevent.stop="copy(taskDetail.number.toString())"
                  >
                    <Icon name="i-heroicons-document-duplicate" :size="16" />
                    <span v-if="copied" class="text-xs font-medium">
                      Copied!
                    </span>
                  </UButton>
                </UTooltip>
              </template>
            </UButton>
          </template>
        </TaskTypeSelectPopover>
      </div>
      <div class="flex items-start justify-between gap-8">
        <div class="flex-1">
          <UTextarea
            ref="titleRef"
            v-model="state.name"
            data-test="task-name"
            autoresize
            maxlength="255"
            :rows="1"
            :ui="{
              base: 'w-full !ring-0 !shadow-none !text-2xl text-gray-900 font-semibold resize-none appearance-none overflow-hidden break-words focus:bg-gray-50 focus:outline-none focus:!ring-2 rounded-md p-1 -ml-2',
            }"
            :disabled="!can('dashboard.data.managing_tasks_sections')"
            @blur.prevent="onUpdateTaskProperty('name')"
            @keydown.enter.prevent.stop="$event.target.blur()"
            @keydown.esc.prevent.stop="cancelUpdateTitle"
          />
          <div class="flex flex-col mt-4 gap-2">
            <div
              v-if="taskDetail.level !== TaskLevel.SUBTASK"
              class="flex items-center gap-2"
            >
              <div
                class="w-32 min-w-32 cursor-pointer"
                @click="taskMovePopoverRef?.open"
              >
                <div class="flex items-center gap-1.5 text-sm text-gray-500">
                  <Icon name="heroicons:document-check" /> In section
                </div>
              </div>
              <TaskMovePopover
                ref="taskMovePopoverRef"
                :workspace-id="currentWorkspace.id"
                :tasks="[taskDetail]"
                class="w-full grow"
              >
                <UButton
                  variant="ghost"
                  color="gray"
                  trailing-icon="i-heroicons-chevron-down-20-solid"
                  class="max-w-full"
                  :disabled="!can('dashboard.data.managing_tasks_sections')"
                >
                  <Tooltip :text="taskDetail.section.name">
                    <template #default="{ getTextRef }">
                      <span :ref="getTextRef" class="line-clamp-1 break-all">
                        {{ taskDetail.section.name }}
                      </span>
                    </template>
                  </Tooltip>
                </UButton>
              </TaskMovePopover>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="w-32 min-w-32 cursor-pointer"
                @click="statusPopoverRef?.open"
              >
                <div class="flex items-center gap-1.5 text-sm text-gray-500">
                  <Icon name="heroicons:ellipsis-horizontal-circle" />
                  Status
                </div>
              </div>
              <StatusSelectPopover
                ref="statusPopoverRef"
                :selected-status-id="taskDetail.status?.id"
                :parent-id="taskDetail.boardId"
                class="w-full grow"
                source="board"
                @change="updateTask(taskDetail.id, { status: $event })"
              >
                <StatusBadge
                  v-if="taskDetail.status"
                  class="flex items-center gap-1 text-xs ml-2.5"
                  :type="taskDetail.status.type"
                  :label="taskDetail.status.name"
                  :disabled="!can('dashboard.data.managing_tasks_sections')"
                />
                <UButton
                  v-else
                  variant="ghost"
                  color="gray"
                  class="font-normal text-gray-400"
                  :disabled="!can('dashboard.data.managing_tasks_sections')"
                >
                  Set status
                </UButton>
              </StatusSelectPopover>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="w-32 min-w-32 cursor-pointer"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
                @click="assigneePopoverRef?.open"
              >
                <div class="flex items-center gap-1.5 text-sm text-gray-500">
                  <Icon name="heroicons:user" /> Assignee
                </div>
              </div>
              <TaskAssigneePopover
                ref="assigneePopoverRef"
                :current-assignee="taskDetail.assignee"
                class="w-full grow"
                :board-id="taskDetail.boardId"
                @select="(assignee) => updateTask(taskDetail.id, { assignee })"
                @remove="updateTask(taskDetail.id, { assignee: null })"
              >
                <div
                  v-if="taskDetail.assignee"
                  class="flex items-center gap-0.5"
                >
                  <UButton
                    variant="ghost"
                    color="gray"
                    class="text-gray-900 max-w-full"
                    :disabled="!can('dashboard.data.managing_tasks_sections')"
                  >
                    <template #leading>
                      <Avatar
                        :id="taskDetail.assignee.id"
                        size="2xs"
                        :name="taskDetail.assignee.fullName"
                        :src="taskDetail.assignee.photo"
                      />
                    </template>
                    <Tooltip :text="taskDetail.assignee.fullName">
                      <template #default="{ getTextRef }">
                        <span
                          :ref="getTextRef"
                          class="line-clamp-1 break-all"
                          data-test="task-assignee"
                        >
                          {{ taskDetail.assignee.fullName }}
                        </span>
                      </template>
                    </Tooltip>
                  </UButton>
                  <UButton
                    variant="ghost"
                    color="gray"
                    size="2xs"
                    class="font-normal text-gray-900"
                    icon="heroicons:x-mark"
                    :disabled="!can('dashboard.data.managing_tasks_sections')"
                    data-test="remove-assignee-icon"
                    @click.prevent.stop="
                      updateTask(taskDetail.id, { assignee: null })
                    "
                  />
                </div>
                <template v-else>
                  <UButton
                    variant="ghost"
                    color="gray"
                    icon="i-heroicons-user-circle"
                    class="font-normal text-gray-400"
                    :disabled="!can('dashboard.data.managing_tasks_sections')"
                  >
                    Assignee member
                  </UButton>
                </template>
              </TaskAssigneePopover>
            </div>
            <div class="flex items-center gap-2 text-sm">
              <div
                class="w-32 min-w-32 cursor-pointer"
                @click="dueDatePopoverRef?.open"
              >
                <div class="flex items-center gap-1.5 text-sm text-gray-500">
                  <Icon name="heroicons:calendar" />
                  {{ dateTimeText }}
                </div>
              </div>
              <TaskDatePickerPopover
                ref="dueDatePopoverRef"
                :start-date="taskDetail.startDate"
                :due-date="taskDetail.dueDate"
                @change="(dates) => updateTask(taskDetail.id, dates)"
              >
                <div
                  v-if="taskDetail.startDate || taskDetail.dueDate"
                  class="flex items-center gap-0.5"
                >
                  <UButton
                    variant="ghost"
                    color="gray"
                    class="text-gray-900"
                    :disabled="!can('dashboard.data.managing_tasks_sections')"
                  >
                    <TaskDatesFormat
                      :start="taskDetail.startDate"
                      :end="taskDetail.dueDate"
                    />
                  </UButton>
                  <UButton
                    variant="ghost"
                    color="gray"
                    size="2xs"
                    class="font-normal text-gray-900"
                    icon="heroicons:x-mark"
                    :disabled="!can('dashboard.data.managing_tasks_sections')"
                    data-test="remove-due-date-icon"
                    @click.prevent.stop="onClearTaskDueDate"
                  />
                </div>
                <UButton
                  v-else
                  variant="ghost"
                  color="gray"
                  icon="heroicons:calendar"
                  class="font-normal text-gray-400"
                  :disabled="!can('dashboard.data.managing_tasks_sections')"
                >
                  Add date
                </UButton>
              </TaskDatePickerPopover>
            </div>
            <div class="flex gap-2">
              <div
                class="w-32 min-w-32 leading-8 cursor-pointer py-1.5"
                @click="addLabelPopoverRef?.open"
              >
                <div class="flex items-center gap-1.5 text-sm text-gray-500">
                  <Icon name="heroicons:tag" /> Labels
                </div>
              </div>
              <div
                class="flex items-center flex-wrap gap-1 flex-1 min-h-8"
                :class="{
                  'pl-2.5': filteredLabels.length,
                }"
              >
                <LabelItem
                  v-for="label in filteredLabels"
                  :key="label.id"
                  :label="label"
                  :expanded="true"
                />
                <LabelListPopover
                  ref="addLabelPopoverRef"
                  source="board"
                  heading="Add labels"
                  class="flex items-center"
                  :selected-ids="map(filteredLabels, 'id')"
                  :parent-id="taskDetail.boardId"
                  @select="onToggleLabels"
                  @remove="onToggleLabels"
                >
                  <UButton
                    v-if="filteredLabels.length"
                    color="gray"
                    variant="soft"
                    size="2xs"
                    icon="i-heroicons-plus-small"
                    :disabled="!can('dashboard.data.managing_tasks_sections')"
                    data-test="add-label-icon"
                  />
                  <UButton
                    v-else
                    color="gray"
                    variant="ghost"
                    class="font-normal text-gray-400"
                    :disabled="!can('dashboard.data.managing_tasks_sections')"
                    data-test="add-label-icon"
                  >
                    Add labels
                  </UButton>
                </LabelListPopover>
              </div>
            </div>
            <div class="flex gap-2">
              <div class="w-32 min-w-32 leading-8 cursor-pointer py-1.5">
                <div class="flex items-center gap-1.5 text-sm text-gray-500">
                  <Icon name="heroicons:link" /> Dependencies
                </div>
              </div>
              <div class="flex items-center flex-wrap gap-1 flex-1 min-h-8">
                <TaskDependencyList
                  ref="taskDependencyListRef"
                  :task-id="taskDetail.id"
                  :board-id="taskDetail.boardId"
                  :dependencies="taskDetail.dependencies"
                />
              </div>
            </div>
          </div>
          <div class="mt-6 relative">
            <Icon
              name="heroicons:bars-3-center-left-20-solid"
              class="absolute top-0.5 -left-8"
              :size="20"
            />
            <p class="text-gray-900 font-semibold">Description</p>
            <div
              :class="[
                'transition-all duration-100 ease-in-out overflow-hidden mt-2 focus-within:ring-2 focus-within:ring-primary-500 rounded-md p-3 -ml-3',
                isDescriptionExpanded
                  ? 'max-h-[unset]'
                  : 'max-h-[500px] mask-description relative',
              ]"
            >
              <div
                v-if="!isDescriptionExpanded"
                class="absolute inset-0 z-20 cursor-pointer"
                data-test="toggle-description"
                @click="toggleDescriptionVisibility"
              />
              <Editor
                ref="descriptionRef"
                v-model:content="state.description"
                data-test="description"
                :additional-extensions="[SlashCommand([], { onFileChange })]"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
                @blur="onUpdateTaskProperty('description')"
                @created="checkExpandDescription"
              />
            </div>
            <UButton
              v-if="showToggleButton"
              variant="soft"
              color="gray"
              :icon="
                isDescriptionExpanded
                  ? 'i-heroicons-chevron-up'
                  : 'i-heroicons-chevron-down'
              "
              class="w-full flex justify-center mt-2 text-sm"
              @click="toggleDescriptionVisibility"
            >
              {{ isDescriptionExpanded ? 'Show less' : 'Show more' }}
            </UButton>
          </div>
          <!-- Custom fields -->
          <TaskCustomFieldsTable
            class="mt-3"
            :task-fields="taskDetail.taskFields"
            :board-id="taskDetail.boardId"
            :type-id="taskDetail.typeId"
            @change="onUpdateTaskCustomField"
          />
          <LazyDeferredContent
            v-if="taskDetail.level !== TaskLevel.SUBTASK"
            :key="taskDetail.id"
            class="relative mt-6"
          >
            <Icon
              name="leanbase:subtask-outline"
              class="absolute top-1.5 -left-8"
              :size="20"
            />
            <div class="flex justify-between items-center">
              <p class="text-gray-900 font-semibold">
                {{
                  taskDetail.level === TaskLevel.MODULE
                    ? 'Children Tasks'
                    : 'Subtasks'
                }}
              </p>
              <UButton
                icon="heroicons:plus"
                size="sm"
                color="gray"
                variant="ghost"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
                @click="subtasksTable?.showCreateSubtask()"
              />
            </div>
            <TaskSubtasksTable
              ref="subtasksTable"
              :parent="
                pick(taskDetail, ['id', 'level', 'boardId', 'sectionId', 'closed'])
              "
            />
          </LazyDeferredContent>
          <LazyDeferredContent
            v-if="taskDetail.attachments?.length"
            :key="taskDetail.id"
          >
            <TaskAttachmentContext
              class="mt-2"
              :attachments="taskDetail.attachments"
            >
              <template #external="{ attachments }">
                <div v-if="attachments?.length" class="relative mt-6">
                  <Icon
                    name="heroicons:paper-clip"
                    class="absolute top-0.5 -left-8"
                    :size="20"
                  />
                  <div class="flex justify-between items-center mb-3">
                    <p class="text-gray-900 font-semibold">Attachments</p>
                    <TaskAttachmentPopover :task-id="taskDetail.id">
                      <UButton
                        icon="heroicons:plus"
                        size="sm"
                        color="gray"
                        variant="ghost"
                        :disabled="
                          !can('dashboard.data.managing_tasks_sections')
                        "
                      />
                    </TaskAttachmentPopover>
                  </div>
                  <TaskExternalAttachment
                    :attachments="attachments"
                    :task-id="taskDetail.id"
                    :task-cover="taskDetail.cover"
                  />
                </div>
              </template>
              <template #internal="{ attachments }">
                <div v-if="attachments?.length" class="relative mt-6">
                  <Icon
                    name="leanbase:lighting"
                    class="absolute top-0.5 -left-8"
                    :size="20"
                  />
                  <div class="flex justify-between items-center mb-3">
                    <p class="text-gray-900 font-semibold">
                      Leanbase attachments
                    </p>
                    <TaskAttachmentPopover :task-id="taskDetail.id">
                      <UButton
                        icon="heroicons:plus"
                        size="sm"
                        color="gray"
                        variant="ghost"
                      />
                    </TaskAttachmentPopover>
                  </div>
                  <TaskInternalAttachment
                    class="grid grid-cols-2 gap-3"
                    :attachments="attachments"
                    :task-id="taskDetail.id"
                  />
                </div>
              </template>
            </TaskAttachmentContext>
          </LazyDeferredContent>
          <TaskActivity
            v-if="route.hash"
            :task-id="taskDetail.id"
            :board-id="taskDetail.boardId"
          />
          <LazyDeferredContent v-else :key="taskDetail.id">
            <TaskActivity
              :task-id="taskDetail.id"
              :board-id="taskDetail.boardId"
            />
          </LazyDeferredContent>
        </div>
        <!-- Right sidebar -->
        <div class="pt-3 w-44">
          <p class="font-semibold text-gray-900 text-sm mb-2">Add to task</p>
          <div class="flex flex-col gap-2">
            <TaskAssigneePopover
              ref="sideAssigneePopoverRef"
              :current-assignee="taskDetail.assignee"
              :board-id="taskDetail.boardId"
              @select="(assignee) => updateTask(taskDetail.id, { assignee })"
              @remove="updateTask(taskDetail.id, { assignee: null })"
            >
              <UTooltip
                class="w-full"
                text="Assignee member"
                :ui="{ middot: 'hidden', shortcuts: 'ml-1' }"
                :popper="{ placement: 'top', arrow: true }"
                :shortcuts="['A']"
              >
                <UButton
                  class="w-full"
                  variant="soft"
                  color="gray"
                  icon="i-heroicons-user-circle"
                  :disabled="!can('dashboard.data.managing_tasks_sections')"
                >
                  Assignee
                </UButton>
              </UTooltip>
            </TaskAssigneePopover>
            <TaskDatePickerPopover
              ref="sideDatesPopoverRef"
              :start-date="taskDetail.startDate"
              :due-date="taskDetail.dueDate"
              @change="(dates) => updateTask(taskDetail.id, dates)"
            >
              <UTooltip
                class="w-full"
                text="Add due date"
                :ui="{ middot: 'hidden', shortcuts: 'ml-1' }"
                :popper="{ placement: 'top', arrow: true }"
                :shortcuts="['D']"
              >
                <UButton
                  variant="soft"
                  color="gray"
                  class="w-full"
                  icon="i-heroicons-calendar"
                  :disabled="!can('dashboard.data.managing_tasks_sections')"
                >
                  Dates
                </UButton>
              </UTooltip>
            </TaskDatePickerPopover>
            <LabelListPopover
              ref="sideLabelPopoverRef"
              source="board"
              heading="Add labels"
              :selected-ids="map(filteredLabels, 'id')"
              :parent-id="taskDetail.boardId"
              @select="onToggleLabels"
              @remove="onToggleLabels"
            >
              <UTooltip
                class="w-full"
                text="Add labels"
                :ui="{ middot: 'hidden', shortcuts: 'ml-1' }"
                :popper="{ placement: 'top', arrow: true }"
                :shortcuts="['L']"
              >
                <UButton
                  variant="soft"
                  color="gray"
                  icon="i-heroicons-tag"
                  class="w-full"
                  :disabled="!can('dashboard.data.managing_tasks_sections')"
                >
                  Labels
                </UButton>
              </UTooltip>
            </LabelListPopover>
            <UTooltip
              class="w-full"
              text="Add dependencies"
              :ui="{ middot: 'hidden', shortcuts: 'ml-1' }"
              :popper="{ placement: 'top', arrow: true }"
              :shortcuts="['P']"
            >
              <UButton
                variant="soft"
                color="gray"
                class="w-full"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
                @click="taskDependencyListRef?.showCreateable"
              >
                <Icon name="heroicons:link" :size="20" />
                Dependencies
              </UButton>
            </UTooltip>
            <UButton
              v-if="taskDetail.level !== TaskLevel.SUBTASK"
              variant="soft"
              color="gray"
              :disabled="!can('dashboard.data.managing_tasks_sections')"
              @click="subtasksTable?.showCreateSubtask()"
            >
              <Icon name="leanbase:subtask-outline" :size="20" />
              {{
                taskDetail.level === TaskLevel.MODULE
                  ? 'Children tasks'
                  : 'Subtasks'
              }}
            </UButton>
            <TaskAttachmentPopover :task-id="taskDetail.id">
              <UButton
                variant="soft"
                color="gray"
                icon="i-heroicons-paper-clip"
                class="w-full"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
              >
                Attachment
              </UButton>
            </TaskAttachmentPopover>
            <TaskCoverPopover
              :task-id="taskDetail.id"
              :cover="taskDetail.cover"
              :attachments="taskDetail.attachments"
            >
              <UButton
                variant="soft"
                color="gray"
                icon="i-heroicons-photo"
                class="w-full"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
              >
                Cover
              </UButton>
            </TaskCoverPopover>
            <UButton
              v-if="taskDetail.level === TaskLevel.SUBTASK"
              variant="soft"
              color="gray"
              icon="heroicons:arrow-path"
              class="w-full"
              :disabled="!can('dashboard.data.managing_tasks_sections')"
              @click="onConvertToTask"
            >
              Convert to task
            </UButton>
            <TaskListModuleSelectPopover
              v-if="taskDetail.level === TaskLevel.TASK"
              ref="sideTaskModuleRef"
              :selected-task-id="taskDetail.parentId"
              :board-id="taskDetail.boardId"
              :show-unlink="!!taskDetail.parentId"
              @change="updateTask(taskDetail.id, { parent: $event || null })"
            >
              <UTooltip
                class="w-full"
                text="Add module:"
                :ui="{ middot: 'hidden', shortcuts: 'ml-1' }"
                :popper="{ placement: 'top', arrow: true }"
                :shortcuts="['S']"
              >
                <UButton
                  variant="soft"
                  color="gray"
                  icon="i-heroicons-cube"
                  class="w-full"
                  :disabled="!can('dashboard.data.managing_tasks_sections')"
                >
                  Set module
                </UButton>
              </UTooltip>
            </TaskListModuleSelectPopover>
          </div>
          <p class="font-semibold text-gray-900 text-sm mt-6 mb-2">Actions</p>
          <div class="flex flex-col gap-2">
            <TaskMovePopover
              v-if="taskDetail.level !== TaskLevel.SUBTASK"
              :workspace-id="currentWorkspace.id"
              :tasks="[taskDetail]"
            >
              <UButton
                variant="soft"
                color="gray"
                class="w-full"
                icon="i-heroicons-arrow-right"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
              >
                Move
              </UButton>
            </TaskMovePopover>
            <TaskCopyPopover
              :workspace-id="currentWorkspace.id"
              :tasks="[taskDetail]"
            >
              <UButton
                variant="soft"
                color="gray"
                icon="i-heroicons-document-duplicate"
                class="w-full"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
              >
                Copy
              </UButton>
            </TaskCopyPopover>
            <TaskSharePopover :task="taskDetail">
              <UButton
                variant="soft"
                color="gray"
                icon="i-heroicons-share"
                class="w-full"
              >
                Share
              </UButton>
            </TaskSharePopover>
            <UTooltip
              v-if="
                !taskDetail.closed
                  && !taskDetail.sectionClosed
                  && !taskDetail.parentClosed
              "
              class="w-full"
              text="Archive task"
              :ui="{ middot: 'hidden', shortcuts: 'ml-1' }"
              :popper="{ placement: 'top', arrow: true }"
              :shortcuts="['C']"
            >
              <UButton
                class="w-full"
                variant="soft"
                color="gray"
                icon="i-heroicons-archive-box-arrow-down"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
                data-test="btn-archive-task"
                @click="onArchiveTask"
              >
                Archive
              </UButton>
            </UTooltip>

            <template v-else>
              <UButton
                variant="soft"
                color="gray"
                icon="i-heroicons-arrow-uturn-left"
                :disabled="
                  !can('dashboard.data.managing_tasks_sections')
                    || taskDetail.sectionClosed
                    || taskDetail.parentClosed
                "
                @click="updateTask(taskDetail.id, { closed: false })"
              >
                Send to board
              </UButton>
              <UButton
                variant="soft"
                color="red"
                icon="i-heroicons-trash"
                :disabled="!can('dashboard.data.managing_tasks_sections')"
                @click="onDeleteTask"
              >
                Delete
              </UButton>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { pick, map, cloneDeep } from 'lodash-es'
import TaskExternalAttachment from '#task/components/TaskExternalAttachment.vue'
import { TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION } from '#task/schema'
import SlashCommand from '#core/packages/tiptap/slash-command'
import type {
  BaseTask,
  TaskDetail,
  TaskCustomField,
  CurrentTask,
  TaskItem,
} from '#task/types'
import { TaskLevel } from '#task/constant'
import type { Label } from '#label/types'
import { ShortcutKeyEvent, TaskDetailShortcut } from '#core/constant'
import type { FileChangeParams } from '#core/types/packages/tiptap'
import { AttachmentType } from '#attachment/constant'

const props = defineProps({
  task: {
    type: Object as PropType<Pick<BaseTask, 'id' | 'name' | 'handle'>>,
    required: true,
  },
})
const emit = defineEmits<{
  (e: 'loaded', task: TaskDetail): void
  (e: 'close'): void
}>()

const toast = useToast()
const modal = useModal()
const route = useRoute()
const { copy, copied } = useClipboard()
const { currentWorkspace, setCurrentTask } = useWorkspaceSharedState()
const { updateTask, onDropAttachment, toggleLabels, updateTaskCustomField } =
  useUpdateTask()
const { deleteTask } = useDeleteTask()
const { auth } = storeToRefs(useAuthStore())
const { can } = useBoardAbility()

const { createAttachment } = useUpdateTask()
const { upload } = usePresignedUpload()
const {
  load: loadTask,
  start,
  onResult,
  stop,
} = useTaskDetailLazyQuery(props.task.id)
const { archiveTask } = useArchiveTask()
const { preventCloseState } = useModalManager()

const taskDetail = ref<TaskDetail>(props.task as TaskDetail)
const loading = ref(true)
const taskDetailDropZoneRef = ref<HTMLDivElement>()
const titleRef = ref()
const descriptionRef = ref()
const subtasksTable = ref()
const statusPopoverRef = ref()
const taskMovePopoverRef = ref()
const assigneePopoverRef = ref()
const dueDatePopoverRef = ref()
const addLabelPopoverRef = ref()

const sideAssigneePopoverRef = ref()
const sideLabelPopoverRef = ref()
const sideDatesPopoverRef = ref()
const sideTaskModuleRef = ref()
const taskDependencyListRef = ref()
const isDescriptionExpanded = ref(false)
const showToggleButton = ref(false)

const state = reactive<Partial<TaskDetail>>({
  name: '',
  description: '',
})

const filteredLabels = computed(() =>
  taskDetail.value.labels?.filter((label) => label.id)
)

const dateTimeText = computed(() => {
  switch (true) {
    case !!taskDetail.value.startDate && !!taskDetail.value.dueDate:
      return 'Dates'
    case !!taskDetail.value.startDate:
      return 'Start date'
    case !!taskDetail.value.dueDate:
      return 'Due date'
    default:
      return 'Dates'
  }
})

const cancelUpdateTitle = (event: KeyboardEvent) => {
  state.name = taskDetail.value['name']
  const target = event.target as HTMLElement
  target.blur()
}

const onUpdateTaskProperty = async (field: keyof TaskDetail) => {
  if (field === 'name' && !state.name?.trim()) {
    state.name = taskDetail.value[field]
    return
  }

  if (state[field] !== taskDetail.value[field]) {
    await updateTask(taskDetail.value.id, pick(state, ['name', 'description']))
  }
}

const onToggleLabels = (label: Label) => toggleLabels({ taskIds: [props.task.id], labelIds: [label.id], detachLabelIds: [] })

const onUpdateTaskCustomField = (customField: TaskCustomField) => {
  updateTaskCustomField({
    fieldId: customField.field.id,
    taskIds: [taskDetail.value.id],
    value: customField.value,
  })
}

const onArchiveTask = () => {
  preventCloseState.value.add('task-detail')
  archiveTask(taskDetail.value as unknown as TaskItem)
}

const onClearTaskDueDate = () => {
  updateTask(taskDetail.value.id, {
    startDate: null as unknown as string,
    dueDate: null as unknown as string,
  })
}

const onOpenParentTask = () => {
  if (!taskDetail.value.parent) {
    return
  }

  const prefix = /^(.*)\/t\/.+$/.exec(window.location.pathname)?.[1]

  updateTaskUrl(taskDetail.value.parent, prefix)
  setCurrentTask(taskDetail.value.parent)
}

const checkExpandDescription = () => {
  const editorHeight = descriptionRef.value?.getHeight()
  showToggleButton.value = editorHeight > 500

  if (!showToggleButton.value) {
    isDescriptionExpanded.value = true
    if (can('dashboard.data.managing_tasks_sections')) {
      descriptionRef.value?.setEditable(true)
    }

    return
  }
}

const onDeleteTask = async () => {
  const data = await deleteTask([taskDetail.value.id])
  if (!data?.data?.deleteTask.success) {
    return toast.add({
      title: 'Something went wrong',
      color: 'red',
    })
  }

  stop()
  modal.close()
  emit('close')
}

useDropZone(taskDetailDropZoneRef, {
  onDrop: async (files, event) => {
    onDropAttachment(files, event, taskDetail.value.id)
  },
})

onResult((result) => {
  if (result.data?.task) {
    extend(state, result.data.task)

    taskDetail.value = cloneDeep(result.data.task as TaskDetail)
    loading.value = false

    emit('loaded', taskDetail.value)
  }
})

watch(
  () => props.task.id,
  (taskId) => {
    start()
    loadTask(null, {
      id: taskId,
    })
  },
  {
    immediate: true,
  }
)

watch(
  [() => taskDetail.value?.name, () => taskDetail.value?.description],
  ([name, description]) => {
    extend(state, { name, description })
    if (!descriptionRef.value?.isEditing()) {
      descriptionRef.value?.setContent(description)
    }
  }
)
const onBack = (event: PopStateEvent) => {
  event.preventDefault()
  // If user go back but the current route is /b/:handle, then close the task detail
  if (/\/b\/[^/]+/.test(window.location.pathname)) {
    setCurrentTask({} as CurrentTask)
  }
}

const onConvertToTask = async () => {
  if (taskDetail.value.level !== TaskLevel.SUBTASK) {
    return
  }

  await updateTask(taskDetail.value.id, { level: TaskLevel.TASK, parent: null })

  toast.add({
    icon: 'i-heroicons-check-circle',
    color: 'green',
    title: 'Convert to task successfully',
  })
}

const scrollToComment = () => {
  const hash = window.location.hash
  if (!hash) {
    return
  }

  const element = document.querySelector(hash)
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
}

const onFileChange = async (params: FileChangeParams) => {
  const { id } = taskDetail.value
  const data = await upload(
    TASK_ATTACHMENT_CREATE_PRESIGNED_URL_MUTATION,
    {
      taskId: id,
    },
    [params.file]
  )
  if (!data.success) {
    return toast.add({
      color: 'red',
      title: data.message,
    })
  }

  const response = await createAttachment(id, {
    attachments: data.presignedUrls,
    type: AttachmentType.FILE,
    name: '',
  })

  if (response && response.length) {
    params.callback?.(response[0].url)
  }
}

const toggleDescriptionVisibility = () => {
  isDescriptionExpanded.value = !isDescriptionExpanded.value
  descriptionRef.value.setEditable(isDescriptionExpanded.value)
  if (isDescriptionExpanded.value) {
    descriptionRef.value.focus()
  } else {
    descriptionRef.value?.scrollIntoView()
  }
}

onMounted(() => {
  window.onhashchange = scrollToComment
  window.addEventListener('popstate', onBack)
  setTimeout(() => scrollToComment(), 2000)
})

onUnmounted(() => {
  window.removeEventListener('popstate', onBack)
  window.onhashchange = null
})

useShortcutKeys([
  {
    shortcutKeyEvent: ShortcutKeyEvent.EDIT_CART_TITLE,
    handle: () => {
      nextTick(() => {
        const textarea = titleRef.value?.$el.querySelector('textarea')
        textarea?.focus()
        textarea?.select()
      })
    },
  },
  {
    shortcutKeyEvent: ShortcutKeyEvent.EDIT_CART_DESCRIPTION,
    handle: () => {
      descriptionRef.value.focus()
    },
  },
  {
    shortcutKeyEvent: ShortcutKeyEvent.OPEN_CARD_DUE_DATE,
    handle: () => {
      sideDatesPopoverRef.value?.open()
    },
  },
  {
    shortcutKeyEvent: ShortcutKeyEvent.ARCHIVE_CARD,
    handle: onArchiveTask,
  },
  {
    shortcutKeyEvent: ShortcutKeyEvent.ASSIGN_ME_TO_TASK,
    handle: () => {
      if (taskDetail.value.assignee?.id === auth.value.id) {
        updateTask(taskDetail.value.id, { assignee: null })
        return
      }

      updateTask(taskDetail.value.id, { assignee: auth.value.id })
    },
  },
  {
    shortcutKeyEvent: ShortcutKeyEvent.TOGGLE_TASK_LABELS,
    handle: () => {
      sideLabelPopoverRef.value?.open()
    },
  },
  {
    shortcutKeyEvent: ShortcutKeyEvent.ADD_MODULE,
    handle: () => {
      sideTaskModuleRef.value?.open()
    },
  },
  {
    shortcutKeyEvent: ShortcutKeyEvent.TOGGLE_MEMBER_MODAL,
    handle: () => {
      sideAssigneePopoverRef.value?.open()
    },
  },
  {
    shortcutKeyEvent: ShortcutKeyEvent.ADD_TASK_DEPENDENCIES,
    handle: () => {
      taskDependencyListRef.value?.showCreateable()
    },
  },
])

defineShortcuts(getShortcutKeyConfig(TaskDetailShortcut))
</script>

<style scoped lang="scss">
.mask-description {
  overflow: hidden;
  -webkit-mask-image: linear-gradient(
    to bottom,
    black 0,
    black calc(100% - 100px),
    transparent 100%
  );
  mask-image: linear-gradient(
    to bottom,
    black 0,
    black calc(100% - 100px),
    transparent 100%
  );
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
</style>
