<template>
  <UPopover
    v-model:open="open"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
    v-bind="$attrs"
  >
    <template #panel>
      <div class="px-4 py-3" @click.prevent.stop.exact>
        <div class="grid grid-cols-2 gap-2 ">
          <div class="w-32 relative max-h-8">
            <template v-if="showStartDateInput">
              <UInput
                v-model="formStateTemp.startDate"
                placeholder="Start date"
                class="w-full"
                data-test="start-date-input"
                @blur="setFormStateTemp('startDate', $event.target.value)"
              />
              <UButton
                size="2xs"
                color="gray"
                variant="ghost"
                icon="i-heroicons-x-mark"
                class="absolute right-1 top-1/2 -translate-y-1/2"
                data-test="clear-start-date"
                @click="onClearDate('startDate')"
              />
            </template>
            <UButton
              v-else
              icon="i-heroicons-plus-small"
              class="w-full"
              color="gray"
              variant="ghost"
              size="sm"
              data-test="add-start-date-btn"
              @click="showStartDateInput = true"
            >
              Start date
            </UButton>
          </div>
          <div class="w-32 relative">
            <UInput
              v-model="formStateTemp.dueDate"
              autofocus
              placeholder="Due date"
              class="w-full"
              data-test="due-date-input"
              @blur="setFormStateTemp('dueDate', $event.target.value)"
            />
            <UButton
              size="2xs"
              color="gray"
              variant="ghost"
              icon="i-heroicons-x-mark"
              class="absolute right-1 top-1/2 -translate-y-1/2"
              @click="onClearDate('dueDate')"
            />
          </div>
          <template v-if="showTimeInput">
            <div class="w-32 relative">
              <template v-if="showStartTimeInput">
                <UInputMenu
                  ref="startTimeRef"
                  v-model="formStateTemp.startTime"
                  trailing-icon=""
                  placeholder="Start time"
                  class="w-full"
                  option-attribute="label"
                  value-attribute="value"
                  :ui-menu="{
                    container:
                      !timePresetValues.includes(formStateTemp.startTime)
                      && formStateTemp.startTime
                        ? 'hidden'
                        : 'z-20 group',
                  }"
                  :search-attributes="['label', 'value']"
                  :options="TimePickPresets"
                  :query="formStateTemp.startTime"
                  @focus="startTimeRef.trigger.$el.click()"
                  @change="(value: string) => setFormStateTemp('startTime', value)"
                  @blur="setFormStateTemp('startTime', $event.target.value)"
                  @keydown.enter.prevent="
                    setFormStateTemp('startTime', $event.target.value)
                  "
                >
                  <template #trailing>
                    <div class="hidden"></div>
                  </template>
                </UInputMenu>
                <UButton
                  v-if="formStateTemp.startTime"
                  size="2xs"
                  color="gray"
                  variant="ghost"
                  icon="i-heroicons-x-mark"
                  class="absolute right-1 top-1/2 -translate-y-1/2"
                  @click="setFormStateTemp('startTime', '')"
                />
              </template>
              <UButton
                v-else
                icon="i-heroicons-plus-small"
                class="w-full"
                color="gray"
                variant="ghost"
                size="sm"
                @click="showStartTimeInput = true"
              >
                Start time
              </UButton>
            </div>
            <div class="w-32 relative">
              <UInputMenu
                ref="dueTimeRef"
                v-model="formStateTemp.dueTime"
                trailing-icon=""
                placeholder="Due time"
                class="w-full"
                option-attribute="label"
                value-attribute="value"
                :ui-menu="{
                  container:
                    !timePresetValues.includes(formStateTemp.dueTime)
                    && formStateTemp.dueTime
                      ? 'hidden'
                      : 'z-20 group',
                }"
                :query="formStateTemp.dueTime"
                :search-attributes="['label', 'value']"
                :options="TimePickPresets"
                @focus="dueTimeRef.trigger.$el.click()"
                @change="(value: string) => setFormStateTemp('dueTime', value)"
                @blur="setFormStateTemp('dueTime', $event.target.value)"
                @keydown.enter.prevent="
                  setFormStateTemp('dueTime', $event.target.value)
                "
              >
                <template #trailing>
                  <div class="hidden"></div>
                </template>
              </UInputMenu>
              <UButton
                v-if="formStateTemp.dueTime"
                size="2xs"
                color="gray"
                variant="ghost"
                icon="i-heroicons-x-mark"
                class="absolute right-1 top-1/2 -translate-y-1/2"
                @click="setFormStateTemp('dueTime', '')"
              />
            </div>
          </template>
        </div>
        <div class="flex justify-center mt-4">
          <DatePicker
            :model-value="rangeState"
            @update:model-value="(value) => (rangeState = value)"
          />
        </div>
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <UTooltip
              text="Add time"
              :popper="{
                placement: 'top',
              }"
            >
              <UButton
                :color="showTimeInput ? 'primary' : 'gray'"
                variant="soft"
                size="sm"
                icon="i-heroicons-clock"
                @click="showTimeInput = !showTimeInput"
              />
            </UTooltip>
          </div>
          <div>
            <UButton color="gray" variant="soft" size="sm" @click="onClearAll">
              Clear
            </UButton>
          </div>
        </div>
      </div>
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import type {
  DatePickerDate,
  DatePickerRangeObject,
} from 'v-calendar/dist/types/src/use/datePicker.js'
import { isAfter } from 'date-fns'
import { map } from 'lodash-es'
import { TimePickPresets } from '#core/constant'

const props = defineProps({
  startDate: {
    type: String,
  },
  dueDate: {
    type: String,
  },
})

const emit = defineEmits(['change'])

const dateSchema = z.coerce.date()

const defaultDate = () => {
  const { startDate, dueDate } = props
  return {
    startDate: startDate ? dateFormat(startDate) : '',
    dueDate: dueDate ? dateFormat(dueDate) : '',
    startTime: startDate ? dateFormat(startDate, 'HH:mm') : '',
    dueTime: dueDate ? dateFormat(dueDate, 'HH:mm') : '',
  }
}

const open = ref(false)
const lastClear = ref(Date.now())
const showStartDateInput = ref(!!props.startDate)
const showTimeInput = ref(false)
const showStartTimeInput = ref(false)
const formState = reactive(defaultDate())
const startTimeRef = ref()
const dueTimeRef = ref()

const timePresetValues = computed(() => map(TimePickPresets, 'value'))
const formStateTemp = computed<typeof formState>({
  set({ startDate, dueDate, dueTime, startTime }) {
    const isRecentClear = Date.now() - lastClear.value < 100
    const parseStart = dateSchema.safeParse(startDate)
    const parseDue = dateSchema.safeParse(dueDate)
    formState.startDate = parseStart.success ? dateFormat(parseStart.data) : ''
    formState.dueDate = parseDue.success ? dateFormat(parseDue.data) : ''
    formState.dueTime = isRecentClear ? '' : parseTime(dueTime)
    formState.startTime = isRecentClear ? '' : parseTime(startTime)
    if (formState.dueTime && !formState.dueDate) {
      formState.dueDate = dateFormat(new Date())
    }

    if (formState.startTime && !formState.startDate) {
      showStartDateInput.value = true
      formState.startDate = dateFormat(new Date())
    }

    if (Object.values(formState).every((value) => value)) {
      const startDateTime = new Date(
        `${formState.startDate} ${formState.startTime}`
      )
      const dueDateTime = new Date(`${formState.dueDate} ${formState.dueTime}`)
      if (isAfter(startDateTime, dueDateTime)) {
        ;[formState.startTime, formState.dueTime] = [
          formState.dueTime,
          formState.startTime,
        ]
      }
    }
  },
  get() {
    return { ...formState }
  },
})
const rangeState = computed<DatePickerDate | DatePickerRangeObject>({
  set(value: DatePickerDate | DatePickerRangeObject) {
    if (value) {
      if (Object.prototype.hasOwnProperty.call(value, 'start')) {
        const { start, end } = value as DatePickerRangeObject
        const isRecentClear = Date.now() - lastClear.value < 100
        const shouldUpdateStart =
          start && (!isRecentClear || formState.startDate)
        const shouldUpdateEnd = end && (!isRecentClear || formState.dueDate)
        return extend(formState, {
          startDate: shouldUpdateStart ? dateFormat(start as string) : '',
          dueDate: shouldUpdateEnd ? dateFormat(end as string) : '',
        })
      }

      const partialValue = dateFormat(value as string)
      if (!formState.startDate && !formState.dueDate) {
        return extend(formState, {
          startDate: showStartDateInput.value ? partialValue : '',
          dueDate: partialValue,
        })
      }

      if (formState.startDate) {
        formState.startDate = partialValue
      }

      if (formState.dueDate) {
        formState.dueDate = partialValue
      }
    }
  },
  get() {
    const { startDate, dueDate } = formState
    if (startDate && dueDate) {
      return {
        start: startDate,
        end: dueDate,
      }
    }

    if (showStartDateInput.value && dueDate) {
      return {
        start: dueDate,
        end: dueDate,
      }
    }

    if (startDate && !dueDate) {
      return {
        start: startDate,
        end: startDate,
      }
    }

    return startDate || dueDate || null
  },
})

const setFormStateTemp = (key: string, value: string) => {
  formStateTemp.value = {
    ...formStateTemp.value,
    [key]: value,
  }
}

const onClearDate = (key: string) => {
  extend(formState, {
    [key]: '',
  })
  lastClear.value = Date.now()
}

const onClearAll = () => {
  extend(formState, {
    startDate: '',
    dueDate: '',
    startTime: '',
    dueTime: '',
  })
  showTimeInput.value = false
  showStartTimeInput.value = false
}

defineExpose({
  open: () => (open.value = true),
})

watch(
  () => open.value,
  (isOpen) => {
    if (isOpen) {
      extend(formState, defaultDate())
      const isEmpty = (value: string) => !value || value === '00:00'
      showStartDateInput.value = !!props.startDate
      showStartTimeInput.value = !isEmpty(formState.startTime)
      showTimeInput.value =
        showStartTimeInput.value || !isEmpty(formState.dueTime)
    }
  }
)

watch(formState, () => {
  const { startDate, dueDate, startTime, dueTime } = formState
  const startDateTime = startDate ? new Date(`${startDate} ${startTime}`).toISOString() : null
  const dueDateTime = dueDate ? new Date(`${dueDate} ${dueTime}`).toISOString() : null
  const emitDates: Record<string, string | null> = {}
  if (props.startDate != startDateTime) {
    emitDates.startDate = startDateTime || null
  }

  if (props.dueDate != dueDateTime) {
    emitDates.dueDate = dueDateTime || null
  }

  emit('change', emitDates)
})
</script>
