<template>
  <div class="flex items-center p-1.5 bg-white border border-gray-200 rounded gap-1.5">
    <UInput
      v-model="linkText"
      placeholder="Enter an url"
      class="w-full"
      autofocus
      input-class="py-1 pl-2 pr-0 text-xs leading-4 text-gray-900"
      @blur="handleEditLinkText"
      @keydown.enter.prevent.stop="$event.target.blur()"
    />
    <UTooltip v-if="showGotoLink" :popper="{ placement: 'top', arrow: true }" text="Go to link">
      <UButton
        variant="ghost"
        size="2xs"
        color="gray"
        icon="heroicons-chevron-right"
        class="flex items-center p-1"
        @click="handleGotoLink"
      />
    </UTooltip>
    <UButton
      variant="ghost"
      size="2xs"
      color="gray"
      icon="heroicons-x-mark"
      class="flex items-center p-1"
      @click="onClose"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  link: {
    type: String,
    required: true,
  },
  showGotoLink: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits<{
  (e: 'close'): void,
  (e: 'change', value: string): void
}>()

const linkText = ref(props.link)

const onClose = () => {
  emit('close')
}

const handleGotoLink = () => {
  const url = props.link
  if (url) {
    window.open(url, '_blank')
  }
}

const handleEditLinkText = () => {
  if (linkText.value.trim()) {
    emit('change', linkText.value)
  } else {
    emit('change', '')
  }
}

watch(() => props.link, (val) => {
  linkText.value = val
})
</script>
