import type { BaseTask } from '#task/types'
import type { BaseSection } from '#section/types'

type Item = BaseSection | BaseTask | { id: string; position: number }

export const sortPos = (a: { position: number }, b: { position: number }) =>
  a.position - b.position

const isInPosition = (index: number, allItems: Item[], item?: Item | null) => {
  if (!item?.id) {
    return false
  }

  const itemAtPosition = allItems[index]
  return itemAtPosition?.id === item.id
}

export const calculateItemPosition = (
  index: number,
  allItems: Item[],
  item?: Item | null
): number => {
  const items = allItems.filter((thisItem) => item?.id !== thisItem.id)

  // If the item is in position no point in moving it around
  if (item && isInPosition(index, allItems, item)) {
    return item.position
  }

  const indexBounded = Math.min(Math.max(index, 0), items.length)

  const itemPrev = items[indexBounded - 1]
  const itemNext = items[indexBounded]

  const posItemCurr = item?.position ?? -1
  const posItemPrev = itemPrev?.position ?? -1
  const posItemNext = itemNext?.position ?? -1

  if (posItemNext === -1) {
    // Ensure that the new pos comes after the prev task pos
    if (item && posItemCurr > posItemPrev) {
      // it's already after so no need to update
      return posItemCurr
    }

    // Bump it one past the last item
    return posItemPrev + 1
  }

  if (item && posItemCurr > posItemPrev && posItemCurr < posItemNext) {
    return posItemCurr
  }

  return posItemPrev >= 0 ? (posItemNext + posItemPrev) / 2 : posItemNext / 2
}

/**
 * Pragmatic drag-and-drop applies a fix designed to address a very specific bug
 * in Chromium called `blockPointerEventsOnEverything`: https://gist.github.com/alexreardon/247fe8faecf3eced42ba1baacba9b9ae#file-fix-post-drop-pointer-bug-ts-L55
 *
 * Unfortunately, this fix can result in significant performance degradation on
 * very large boards, as it introduces a style recalculation that scales in
 * duration with the number of rendered elements. This can cause drag-and-drop
 * to take nearly half a second to resolve on incredibly large boards.
 *
 * While the bug it's designed to address is undesirable, this performance issue
 * is far more severe in impact, so we need to opt out of it for now. If Chrome
 * fixes the underlying bug, or if we introduce pagination to make our boards
 * vastly more performant, we can hopefully get rid of this.
 *
 * This function should be invoked in the `onDrop` callback.
 *
 */
export const unblockPointerEventsOnEverything = () => {
  // The fix is wrapped behind two layers of microtasks, so we need to enqueue
  queueMicrotask(() => {
    queueMicrotask(() => {
      const element = document.querySelector('style[pdnd-post-drag-fix]')
      if (!(element instanceof HTMLStyleElement)) {
        return
      }

      // Remove the `* { pointer-events: none !important; }` rule
      if (element.sheet?.cssRules?.length) {
        element.sheet?.deleteRule(0)
      }
    })
  })
}

/**
 * A bit of a painful workaround for a persistent Chromium bug:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=630726
 *
 * Sometimes when we start dragging a task, the add task button in the list
 * footer has its hover state triggered. This is a workaround for that issue,
 * though we should seek to clean it up if that issue is ever resolved.
 *
 * This function should be invoked in the `onDragStart` callback.
 */
const CHROMIUM_WORKAROUND_CLASS_NAME = 'chromium-is-dragging'

export const addChromiumDragAndDropWorkaround = () => {
  document.body.classList.add(CHROMIUM_WORKAROUND_CLASS_NAME)
}

/**
 * To be paired with {@link addChromiumDragAndDropWorkaround}.
 *
 * This function should be invoked in the `onDrop` callback.
 */
export const clearChromiumDragAndDropWorkaround = () => {
  document.body.classList.remove(CHROMIUM_WORKAROUND_CLASS_NAME)
}

export const clamp = (params: { value: number; min: number; max: number }) => {
  return Math.max(params.min, Math.min(params.value, params.max))
}
