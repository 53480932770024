/**********************************************
 * 🚨 WARNING: MANUAL SYNC REQUIRED 🚨
 *
 * This file contains the GraphQL fragment and
 * schema for the Board Loader. Ensure that they
 * are manually kept in sync with the backend
 * {model}/sync. Any changes to backend models
 * must be reflected here to prevent issues.
 *
 **********************************************/

import { TASK_LOADER_FRAGMENT } from '#task/loader_fragment'

export const BOARD_TASKS_LOADER_QUERY = gql`
  ${TASK_LOADER_FRAGMENT}
  query BoardTasksQuery($boardId: ID!) {
    tasks: boardTasks(boardId: $boardId) {
      ...TaskLoader
    }
  }
`
