import { useWorkspaceDetailLazyQuery } from './gql'
import { WORKSPACE_DETAIL_QUERY } from '#workspace/schema'
import type { RouteLocationNormalizedLoaded } from '#vue-router'
import { TASK_DETAIL_QUERY } from '#task/schema'
import { BOARD_QUERY } from '#board/schema'

export const useWorkspaceStateUpdater = async () => {
  const router = useRouter()
  const { client } = useApolloClient()
  const {
    currentWorkspace,
    currentBoard,
    currentTask,
    setCurrentWorkspace,
    setCurrentBoard,
    setCurrentTask,
  } = useWorkspaceSharedState()
  const { setCurrentWorkspacePermissions } = useWorkspaceAbility()
  const { result, load, refetch } = useWorkspaceDetailLazyQuery('')
  const {
    result: currentPermissionResult,
    load: loadPermissions,
    refetch: refetchPermissions,
  } = useUserCurrentWorkspacePermissionsQuery()

  const updateWorkspaceState = (
    route: RouteLocationNormalizedLoaded
  ): Promise<() => void> => {
    return new Promise((resolve) => {
      const routeName = (route.name as string) ?? ''

      let workspaceId: string | null
      let unsubscribeFromQuery = () => {}

      const workspacePrefix = 'w-handle'
      const boardPrefix = 'b-handle'

      const possiblySetWorkspaceState = async () => {
        if (!workspaceId) {
          resolve(unsubscribeFromQuery)
          return showError({
            statusCode: 404,
            statusMessage: 'Workspace not found',
          })
        }

        try {
          const [workspace, permission] = await Promise.all([
            load(null, {
              id: workspaceId,
            }),
            loadPermissions(null, {
              workspaceId,
            }),
          ])
          if (currentWorkspace.value.id !== workspaceId) {
            const refetchPromises = []
            if (!workspace) {
              refetchPromises.push(refetch({ id: workspaceId }))
            }

            if (!permission) {
              refetchPromises.push(refetchPermissions({ workspaceId }))
            }

            await Promise.all(refetchPromises)
          }
        } catch (error: unknown) {
          resolve(unsubscribeFromQuery)
          return showError({
            statusCode: 403,
            statusMessage: 'You dont have permission to access it',
          })
        }

        if (!result.value?.workspace) {
          resolve(unsubscribeFromQuery)
          return showError({
            statusCode: 404,
            statusMessage:
              'Workspace not found or you dont have permission to access it',
          })
        }

        setCurrentWorkspace(result.value.workspace)
        if (currentPermissionResult.value) {
          setCurrentWorkspacePermissions(
            currentPermissionResult.value.currentWorkspacePermissions
          )
        }

        resolve(unsubscribeFromQuery)
      }

      if (
        routeName.startsWith(workspacePrefix) &&
        currentWorkspace.value.handle !== route.params.handle
      ) {
        const observer = client.watchQuery({
          query: WORKSPACE_DETAIL_QUERY,
          variables: {
            handle: route.params.handle,
          },
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
        })
        const subscriber = observer.subscribe((result) => {
          if (result.error || !result?.data?.workspace) {
            resolve(unsubscribeFromQuery)
            return showError({
              statusCode: 404,
              statusMessage:
                'Workspace not found or you dont have permission to access it',
            })
          }

          const workspace = result?.data?.workspace
          workspaceId = workspace?.id ?? null
          possiblySetWorkspaceState()
        })
        unsubscribeFromQuery = subscriber.unsubscribe.bind(subscriber)
      } else if (
        routeName.startsWith(boardPrefix) &&
        route.params.handle !== currentBoard.value.handle
      ) {
        const observer = client.watchQuery({
          query: BOARD_QUERY,
          variables: {
            handle: route.params.handle,
          },
          errorPolicy: 'ignore',
        })
        const subscriber = observer.subscribe((result) => {
          if (result.error || !result?.data?.board) {
            resolve(unsubscribeFromQuery)
            return showError({
              statusCode: 404,
              statusMessage:
                'Board not found or you dont have permission to access it',
            })
          }

          const board = result?.data?.board
          workspaceId = board?.workspace?.id ?? null
          setCurrentBoard(board)
          possiblySetWorkspaceState()
        })
        unsubscribeFromQuery = subscriber.unsubscribe.bind(subscriber)
      } else if (
        route.meta?.taskPopup &&
        route.params.handle !== currentTask.value.handle
      ) {
        const observer = client.watchQuery({
          query: TASK_DETAIL_QUERY,
          variables: {
            handle: route.params.handle,
          },
          errorPolicy: 'ignore',
        })
        const subscriber = observer.subscribe((result) => {
          if (result.error || !result?.data?.task) {
            resolve(unsubscribeFromQuery)
            return showError({
              statusCode: 404,
              statusMessage:
                'Task not found or you dont have permission to access it',
            })
          }

          const task = result?.data?.task
          const board = task.board
          workspaceId = board?.workspace?.id ?? null
          setCurrentTask({
            ...task,
            viewMode: route.meta?.taskViewMode,
          })
          setCurrentBoard(board)
          possiblySetWorkspaceState()
        })
        unsubscribeFromQuery = subscriber.unsubscribe.bind(subscriber)
      } else {
        resolve(unsubscribeFromQuery)
      }
    })
  }

  /**
   * We need wait workspace update state when navigate into workspace context
   */
  router.beforeEach(async (to) => {
    logger.time('Handle workspace state update')
    const unsubscribe = await updateWorkspaceState(to)
    logger.timeEnd('Handle workspace state update')
    unsubscribe?.()
    return true
  })

  const unsubscribe = await updateWorkspaceState(router.currentRoute.value)
  unsubscribe?.()
}
