<template>
  <BubbleMenu
    plugin-key="bubbleMenuText"
    :editor="editor"
    class="flex items-center p-1.5 bg-white border border-gray-200 w-fit rounded pl-0 relative"
    :tippy-options="{ duration: 150 }"
    :should-show="showBubbleMenuText"
  >
    <UPopover
      v-model:open="popoverLinkOpen"
      :ui="{ width: 'w-80 max-w-80' }"
      :popper="{ placement: 'bottom-end', strategy: 'fixed' }"
      class="absolute inset-0"
    >
      <template #panel>
        <EditorExtensionsToolbarLink
          :link="editor.getAttributes('link')?.href || ''"
          @close="popoverLinkOpen = false"
          @change="handleEditLinkTextPopover"
        />
      </template>
      <div class="w-full h-6" @click.prevent.stop />
    </UPopover>
    <div class="flex items-center gap-2 divide-x divide-gray-200">
      <div
        v-for="toolbarList in toolbarGroups"
        :key="toolbarList[0].title"
        class="flex gap-1 pl-2"
      >
        <UTooltip
          v-for="toolbar in toolbarList"
          :key="toolbar.icon"
          :popper="{ placement: 'top', arrow: true }"
          :text="toolbar.title"
          :shortcuts="toolbar.shortCuts"
        >
          <UButton
            variant="ghost"
            size="2xs"
            color="gray"
            :class="[
              'flex items-center p-1',
              {
                'bg-gray-200': editor.isActive(
                  toolbar.active.name,
                  toolbar.active.attrs
                ),
              },
            ]"
            @click="toolbar.click()"
          >
            <Suspense :timeout="1000">
              <Icon v-if="toolbar.icon" :name="toolbar.icon" class="size-4" />
            </Suspense>
          </UButton>
        </UTooltip>
      </div>
    </div>
  </BubbleMenu>
  <BubbleMenu
    v-show="showLinkMenu"
    ref="linkMenu"
    plugin-key="bubbleMenuLink"
    :tippy-options="{ duration: 150 }"
    class-name="bubble-menu-dark"
    :editor="editor"
    :should-show="showShowLinkText"
  >
    <EditorExtensionsToolbarLink
      :link="editor.getAttributes('link')?.href || ''"
      show-goto-link
      @close="showLinkMenu = false"
      @change="handleEditLinkText"
    />
  </BubbleMenu>
  <BubbleMenu
    plugin-key="bubbleMenuImage"
    :tippy-options="{ duration: 150 }"
    class-name="bubble-menu-dark"
    :editor="editor"
    :should-show="showImageMenu"
  >
  </BubbleMenu>
</template>

<script lang="ts" setup>
import type { Editor } from '@tiptap/core'
import { BubbleMenu } from '@tiptap/vue-3'
import type { ToolbarItem } from '#core/types/packages/tiptap'

const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
})
const linkMenu = ref()
const showLinkMenu = ref(false)
const popoverLinkOpen = ref(false)

const toolbarGroups: ToolbarItem[][] = [
  [
    {
      title: 'Bold',
      icon: 'tabler:bold',
      shortCuts: ['⌘', 'B'],
      active: { name: 'bold' },
      click: () => {
        props.editor.chain().focus().toggleBold().run()
        props.editor.commands.blur()
      },
    },
    {
      title: 'Italic',
      icon: 'tabler:italic',
      shortCuts: ['⌘', 'I'],
      active: { name: 'italic' },
      click: () => {
        props.editor.chain().focus().toggleItalic().run()
        props.editor.commands.blur()
      },
    },
    {
      title: 'Strikethrough',
      icon: 'tabler:strikethrough',
      shortCuts: ['⌘', '⇧', 'S'],
      active: { name: 'strike' },
      click: () => {
        props.editor.chain().focus().toggleStrike().run()
        props.editor.commands.blur()
      },
    },
    {
      title: 'Code',
      icon: 'tabler:code',
      shortCuts: ['⌘', '⌥', 'M'],
      active: { name: 'code' },
      click: () => {
        props.editor.chain().focus().toggleCode().run()
        props.editor.commands.blur()
      },
    },
  ],
  [
    {
      title: 'Paragraph',
      icon: 'tabler:letter-t',
      shortCuts: ['⌘', '⌥', '0'],
      active: { name: 'paragraph' },
      click: () => {
        props.editor.chain().focus().setParagraph().run()
      },
    },
    {
      title: 'H1',
      icon: 'tabler:h-1',
      shortCuts: ['⌘', '⌥', '1'],
      active: { name: 'heading', attrs: { level: 1 } },
      click: () => {
        props.editor.chain().focus().toggleHeading({ level: 1 }).run()
        props.editor.commands.blur()
      },
    },
    {
      title: 'H2',
      icon: 'tabler:h-2',
      shortCuts: ['⌘', '⌥', '2'],
      active: { name: 'heading', attrs: { level: 2 } },
      click: () => {
        props.editor.chain().focus().toggleHeading({ level: 2 }).run()
        props.editor.commands.blur()
      },
    },
    {
      title: 'H3',
      icon: 'tabler:h-3',
      shortCuts: ['⌘', '⌥', '3'],
      active: { name: 'heading', attrs: { level: 3 } },
      click: () => {
        props.editor.chain().focus().toggleHeading({ level: 3 }).run()
        props.editor.commands.blur()
      },
    },
  ],
  [
    {
      title: 'Bullet list',
      icon: 'tabler:list',
      shortCuts: ['⌘', '⇧', 'B'],
      active: { name: 'bulletList' },
      click: () => {
        props.editor.chain().focus().toggleBulletList().run()
      },
    },
    {
      title: 'Number list',
      icon: 'tabler:list-numbers',
      shortCuts: ['⌘', '⇧', 'O'],
      active: { name: 'orderedList' },
      click: () => {
        props.editor.chain().focus().toggleOrderedList().run()
        props.editor.commands.blur()
      },
    },
    {
      title: 'Link',
      icon: 'heroicons-link',
      active: { name: 'link' },
      click: () => {
        popoverLinkOpen.value = true
      },
    },
  ],
]

const handleEditLinkText = (value: string) => {
  if (value) {
    props.editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({
        href: value,
        target: '_blank',
        class: 'underline text-primary-500 underline-offset-2',
      })
      .run()
  } else {
    props.editor.chain().focus().extendMarkRange('link').unsetLink().run()
  }

  props.editor.commands.blur()
}

const handleEditLinkTextPopover = (value: string) => {
  handleEditLinkText(value)
  popoverLinkOpen.value = false
}

const showShowLinkText = ({
  editor,
  from,
  to,
}: {
  editor: Editor
  from: number
  to: number
}) => {
  if (from === to && editor.isActive('link')) {
    showLinkMenu.value = true
    return editor.isEditable
  }

  return false
}

const showBubbleMenuText = ({
  editor,
  from,
  to,
}: {
  editor: Editor
  from: number
  to: number
}) => {
  if (to - from > 1) {
    popoverLinkOpen.value = false
    return editor.isEditable
  }

  return false
}

const showImageMenu = ({
  editor
}: {
  editor: Editor
  from: number
  to: number
}) => {
  const isImageActive = editor?.isActive('image')
  return isImageActive && editor.isEditable
}
</script>
