import type * as Apollo from '@apollo/client'
import {
  CREATE_WORKSPACE_MUTATION,
  WORKSPACES_QUERY,
  UPDATE_WORKSPACE_MUTATION,
  WORKSPACE_DETAIL_QUERY,
  WORKSPACE_DETAIL_SETTING_QUERY,
  WORKSPACE_BOARDS_QUERY,
  WORKSPACE_LIST_BOARDS_QUERY,
  UPDATE_WORKSPACE_WITH_LOGO_MUTATION,
} from '#workspace/schema'
import type {
  BaseWorkspace,
  CreateWorkspaceParams,
  UpdateWorkspaceParams,
  WorkspaceSettings,
} from '#workspace/types'
import type { BaseBoard } from '#board/types'
import type { FormError } from '#core/types'
import type { BoardMember } from '#auth/types'

export const useWorkspaceDetailQuery = (id: string) => {
  return useQuery<{ workspace?: BaseWorkspace }>(WORKSPACE_DETAIL_QUERY, {
    id,
  })
}

export const useWorkspaceDetailLazyQuery = (id: string) => {
  return useLazyQuery<{ workspace?: BaseWorkspace }>(WORKSPACE_DETAIL_QUERY, {
    id,
  })
}

export const useWorkspaceSettingsQuery = (id: string) => {
  return useQuery<{ workspace?: WorkspaceSettings }>(
    WORKSPACE_DETAIL_SETTING_QUERY,
    {
      id,
    }
  )
}

export const useListWorkspacesQuery = () => {
  return useQuery<{ workspaces: BaseWorkspace[] }>(WORKSPACES_QUERY)
}

export const useListWorkspacesLazyQuery = () => {
  return useLazyQuery<{ workspaces: BaseWorkspace[] }>(WORKSPACES_QUERY)
}

export const useListWorkspaceBoardsQuery = (
  filter: Record<string, string>,
  options: Apollo.QueryHookOptions = {}
) => {
  return useQuery<{
    boards: BaseBoard[]
  }>(
    WORKSPACE_BOARDS_QUERY,
    {
      filter,
    },
    options
  )
}

export const useListWorkspaceBoardsLazyQuery = (
  filter: Record<string, unknown>,
  options: Apollo.QueryHookOptions = {}
) => {
  return useLazyQuery<{
    boards: BaseBoard[]
  }>(
    WORKSPACE_BOARDS_QUERY,
    {
      filter,
    },
    options
  )
}

export const useWorkspaceListBoardsQuery = (
  filter: Record<string, string>,
  options: Apollo.QueryHookOptions = {}
) => {
  return useQuery<{
    boards: BaseBoard[],
    memberBoards: Array<BoardMember & { board: BaseBoard }>
  }>(
    WORKSPACE_LIST_BOARDS_QUERY,
    {
      filter,
      workspaceId: filter.workspaceId,
    },
    options
  )
}

export const useCreateWorkspaceMutation = (params: CreateWorkspaceParams) => {
  return useMutation<{ createWorkspace: { workspace: BaseWorkspace } }>(
    CREATE_WORKSPACE_MUTATION,
    {
      variables: params,
      refetchQueries: [
        {
          query: WORKSPACES_QUERY,
          fetchPolicy: 'no-cache',
        },
      ],
      async onQueryUpdated(observableQuery) {
        await observableQuery.refetch()
      },
    }
  )
}

export const useUpdateWorkspaceMutation = (
  { input, logo }: UpdateWorkspaceParams
) => {
  const schema = logo ? UPDATE_WORKSPACE_WITH_LOGO_MUTATION : UPDATE_WORKSPACE_MUTATION

  if ('logo' in input) {
    delete input.logo
  }

  return useMutation<{
    updateWorkspace: { workspace: BaseWorkspace; errors: FormError[] },
    updateWorkspaceLogo?: { logo: string }
  }>(schema, {
    variables: { input, logo, id: input.id },
  })
}
