import tippy, {
  type Instance,
  type GetReferenceClientRect,
  type Content,
} from 'tippy.js'
import { VueRenderer } from '@tiptap/vue-3'
import type { Editor } from '@tiptap/core'
import { Extension } from '@tiptap/core'
import Suggestion, { type SuggestionOptions } from '@tiptap/suggestion'
import type { CommandProps, FileChangeParams } from '#core/types/packages/tiptap'
import CommandList from '#core/components/editor/extensions/CommandList.vue'
import { UploadType } from '#core/constant'

const Command = Extension.create({
  name: 'slash-command',
  addOptions() {
    return {
      suggestion: {
        char: '/',
        command: ({ editor, range, props }) => {
          props.command({ editor, range })
        },
      } as SuggestionOptions,
    }
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})

const getSuggestionItems =
  (excludeCommandList?: string[]) =>
    ({ query }: { query: string }) => {
      return [
        {
          title: 'Text',
          description: 'Just start typing with plain text.',
          searchTerms: ['p', 'paragraph'],
          icon: 'tabler:text-size',
          command: ({ editor, range }: CommandProps) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .toggleNode('paragraph', 'paragraph')
              .run()
          },
        },
        {
          title: 'To-do List',
          description: 'Track tasks with a to-do list.',
          searchTerms: ['todo', 'task', 'list', 'check', 'checkbox'],
          icon: 'tabler:list-check',
          command: ({ editor, range }: CommandProps) => {
            editor.chain().focus().deleteRange(range).toggleTaskList().run()
          },
        },
        // TODO: Uncomment this if needed
        // {
        //   title: 'Table',
        //   description: 'Create a table',
        //   searchTerms: ['table', 'grid'],
        //   icon: 'tabler:table',
        //   command: ({ editor, range }: CommandProps) => {
        //     editor
        //       .chain()
        //       .focus()
        //       .deleteRange(range)
        //       .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
        //       .run()
        //   },
        // },
        {
          title: 'Heading 1',
          description: 'Big section heading.',
          searchTerms: ['title', 'big', 'large'],
          icon: 'tabler:heading',
          command: ({ editor, range }: CommandProps) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setNode('heading', { level: 1 })
              .run()
          },
        },
        {
          title: 'Heading 2',
          description: 'Medium section heading.',
          searchTerms: ['subtitle', 'medium'],
          icon: 'tabler:heading',
          command: ({ editor, range }: CommandProps) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setNode('heading', { level: 2 })
              .run()
          },
        },
        {
          title: 'Heading 3',
          description: 'Small section heading.',
          searchTerms: ['subtitle', 'small'],
          icon: 'tabler:heading',
          command: ({ editor, range }: CommandProps) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .setNode('heading', { level: 3 })
              .run()
          },
        },
        {
          title: 'Bullet List',
          description: 'Create a simple bullet list.',
          searchTerms: ['unordered', 'point'],
          icon: 'tabler:list',
          command: ({ editor, range }: CommandProps) => {
            editor.chain().focus().deleteRange(range).toggleBulletList().run()
          },
        },
        {
          title: 'Numbered List',
          description: 'Create a list with numbering.',
          searchTerms: ['ordered'],
          icon: 'tabler:list-numbers',
          command: ({ editor, range }: CommandProps) => {
            editor.chain().focus().deleteRange(range).toggleOrderedList().run()
          },
        },
        {
          title: 'Quote',
          description: 'Capture a quote.',
          searchTerms: ['blockquote'],
          icon: 'tabler:quote',
          command: ({ editor, range }: CommandProps) =>
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .toggleNode('paragraph', 'paragraph')
              .toggleBlockquote()
              .run(),
        },
        {
          title: 'Code',
          description: 'Capture a code snippet.',
          searchTerms: ['codeblock'],
          icon: 'tabler:code',
          command: ({ editor, range }: CommandProps) =>
            editor.chain().focus().deleteRange(range).toggleCodeBlock().run(),
        },
        {
          title: 'Image',
          description: 'Upload image.',
          searchTerms: ['image', 'picture', 'photo'],
          icon: 'heroicons-photo',
          uploadType: UploadType.IMAGE,
          command: ({ editor, range: { from, to } }: CommandProps) => {
            editor.chain().focus().deleteRange({ from: from - 1, to }).run()
            editor.commands.enter()
            editor.commands.focus('end', { scrollIntoView: true, })
          },
        },
        // TODO: Install tiptap pro to use this
        // {
        //   title: 'File',
        //   description: 'Upload file.',
        //   searchTerms: ['file'],
        //   icon: 'heroicons-paper-clip',
        //   uploadType: UploadType.FILE,
        // },
      ].filter((item) => {
        if (
          excludeCommandList?.length &&
          excludeCommandList.includes(item.title)
        ) {
          return false
        }

        if (typeof query === 'string' && query.length > 0) {
          const search = query.toLowerCase()
          return (
            item.title.toLowerCase().includes(search) ||
            item.description.toLowerCase().includes(search) ||
            (item.searchTerms &&
            item.searchTerms.some((term: string) => term.includes(search)))
          )
        }

        return true
      })
    }

const renderItems = (params?: { onFileChange?: (params: FileChangeParams) => void }) => () => {
  let component: VueRenderer | null = null
  let popup: Instance | null = null

  return {
    onStart: (props: {
      editor: Editor
      clientRect: GetReferenceClientRect
    }) => {
      extend(props.editor, {
        params
      })
      component = new VueRenderer(CommandList, {
        props,
        editor: props.editor,
      })

      if (!props.clientRect) {
        return
      }

      const popups = tippy('body', {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: component.element as Content,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'auto-end',
      })
      ;[popup] = popups
    },
    onUpdate: (props: {
      editor: Editor
      clientRect: GetReferenceClientRect
    }) => {
      component?.updateProps(props)
      if (popup) {
        popup.setProps({
          getReferenceClientRect: props.clientRect,
        })
      }
    },
    onKeyDown: (props: { event: KeyboardEvent }) => {
      if (props.event.key === 'Escape') {
        popup?.hide()

        return true
      }

      return component?.ref?.onKeyDown(props.event)
    },
    onExit: () => {
      popup?.destroy()
      component?.destroy()
    },
  }
}

export default (
  excludeCommandList: string[] = [],
  options?: { onFileChange?: (params: FileChangeParams) => void }
) => {
  const _renderItems = renderItems(options)

  return Command.configure({
    suggestion: {
      items: getSuggestionItems(excludeCommandList),
      render: _renderItems,
    },
  })
}
