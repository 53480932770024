<template>
  <UPopover
    ref="popoverRef"
    v-model:open="open"
    :ui="{ width: 'w-[280px]', trigger: disabled ? '!cursor-not-allowed' : '' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
    :disabled="disabled"
    v-bind="$attrs"
  >
    <template #panel>
      <div class="pt-3" data-test="assignee-popover">
        <div class="flex items-center justify-between px-4">
          <p class="font-semibold text-sm text-gray-900">Assignee</p>
          <UButton
            size="xs"
            icon="i-heroicons-x-mark"
            color="gray"
            variant="ghost"
            @click="open = false"
          />
        </div>
        <div class="mt-2 px-4">
          <UInput
            v-model="variables.filter.search"
            icon="i-heroicons-magnifying-glass"
            size="sm"
            autofocus
            placeholder="Search by name or email"
          />
        </div>
        <slot name="custom-members" />
        <div class="mt-4 pl-2">
          <div class="text-xs font-semibold text-gray-900 px-2 mb-1">
            <span>Members</span>
          </div>
          <div
            class="max-h-[22.25rem] pb-2 pr-0.5 scroll-stable overflow-y-auto minimal-scrollbar"
          >
            <div
              v-for="(member, index) in boardMembers"
              ref="optionRefs"
              :key="member.id"
              :class="[
                'px-2 py-1.5 rounded-md mt-0.5 cursor-pointer flex items-center justify-between hover:bg-gray-100',
                {
                  'bg-gray-100':
                    member.id === currentAssignee?.id
                    || currentFocusAssigneeIndex === index,
                },
              ]"
              @mouseenter="currentFocusAssigneeIndex = index"
              @mouseleave="currentFocusAssigneeIndex = -1"
              @click.prevent.stop="onSelectMember(member)"
            >
              <div class="flex items-center gap-2">
                <Avatar
                  :id="member.id"
                  :src="member.photo"
                  :name="member.fullName"
                  size="2xs"
                />
                <Tooltip :text="member.fullName" arrow-class="!top-[unset]">
                  <template #default="{ getTextRef }">
                    <p
                      :ref="getTextRef"
                      class="text-gray-900 text-sm line-clamp-1 break-all"
                      data-test="member-name-item"
                    >
                      {{ member.fullName }}
                    </p>
                  </template>
                </Tooltip>
              </div>
              <UButton
                v-if="currentAssignee?.id === member.id"
                variant="ghost"
                color="white"
                size="2xs"
                icon="i-heroicons-x-mark"
                data-test="remove-assignee-icon"
                @click.prevent.stop="$emit('remove')"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
import { cloneDeep } from 'lodash-es'
import type { TaskDetail } from '#task/types'
import type { BaseUser, BoardMember } from '#auth/types'

const props = defineProps({
  boardId: {
    type: String,
    required: false,
  },
  currentAssignee: {
    type: Object as PropType<TaskDetail['assignee']>,
    default: () => ({}),
  },
  closeOnSelected: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['select', 'remove'])

const { auth } = storeToRefs(useAuthStore())

const optionRefs = ref<HTMLElement[]>([])
const currentFocusAssigneeIndex = ref(-1)
const open = ref(false)
const popoverRef = ref()
const variables = reactive({
  boardId: props.boardId,
  filter: {
    search: '',
    limit: 8,
    active: true,
  },
})

const { result, load, refetch, onResult } = useListBoardMembersQuery(
  variables,
  {
    debounce: 300,
  }
)

const boardMembers = ref<BaseUser[]>([])

const bringMemberToTop = (members: BoardMember[], id: string) => {
  const memberIndex = members.findIndex((member) => member.user?.id === id)
  if (memberIndex !== -1) {
    const cloneMember = members[memberIndex]
    members.splice(memberIndex, 1)
    members.unshift(cloneMember)
  }
}

const onSelectMember = (member: BaseUser) => {
  emit('select', member.id)
  if (props.closeOnSelected) {
    open.value = false
  }
}

onResult(() => {
  const members = cloneDeep(result.value?.boardMembers?.items || [])
  bringMemberToTop(members, auth.value.id)
  if (props.currentAssignee?.id) {
    bringMemberToTop(members, props.currentAssignee.id)
  }

  boardMembers.value = members.map((member) => member.user as BaseUser)
})

const scrollToView = (index: number) => {
  if (optionRefs.value[index]) {
    optionRefs.value[index].scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

defineShortcuts({
  arrowup: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusAssigneeIndex.value > 0) {
        currentFocusAssigneeIndex.value--
      } else {
        currentFocusAssigneeIndex.value = boardMembers.value?.length - 1
      }

      scrollToView(currentFocusAssigneeIndex.value)
    },
  },
  arrowdown: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusAssigneeIndex.value < boardMembers.value?.length - 1) {
        currentFocusAssigneeIndex.value++
      } else {
        currentFocusAssigneeIndex.value = 0
      }

      scrollToView(currentFocusAssigneeIndex.value)
    },
  },
  enter: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusAssigneeIndex.value >= 0 && boardMembers.value![currentFocusAssigneeIndex.value]) {
        if (props.currentAssignee?.id === boardMembers.value[currentFocusAssigneeIndex.value].id) {
          return emit('remove')
        }

        onSelectMember(boardMembers.value[currentFocusAssigneeIndex.value])
      }
    },
  },
})

defineExpose({
  open: () => (open.value = true),
})

watch(
  () => open.value,
  async (isOpen) => {
    if (isOpen) {
      currentFocusAssigneeIndex.value = -1
      extend(variables, {
        boardId: props.boardId,
      })
      await load()
      await refetch()
      nextTick(() => {
        popoverRef.value.popperInstance?.forceUpdate()
      })
    }
  }
)
</script>
