<template>
  <UPopover
    v-model:open="open"
    :ui="{ width: 'w-64' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'fixed',
    }"
  >
    <template #panel>
      <div class="py-3 px-4">
        <div class="flex items-center justify-between">
          <p class="font-semibold text-sm text-gray-900">Share task</p>
          <UButton
            size="xs"
            icon="i-heroicons-x-mark"
            color="gray"
            variant="ghost"
            @click="open = false"
          />
        </div>
        <div class="mt-3">
          <p class="text-sm mb-1.5 font-medium">Link to this task</p>
          <div class="relative">
            <UInput
              :model-value="shareUrl"
              readonly
              :ui="{ padding: { sm: 'pr-7' } }"
            />
            <Icon
              name="heroicons:document-duplicate"
              class="absolute right-1 top-1.5 cursor-pointer"
              :size="20"
              @click="onCopyShareUrl"
            />
          </div>
        </div>
      </div>
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
import type { BaseTask } from '#task/types'
import { makeTaskUrl } from '#task/utils/ultitity'

const props = defineProps({
  task: {
    type: Object as PropType<BaseTask>,
    required: true,
  },
})

const toast = useToast()
const { origin } = useRequestURL()
const { copy, copied } = useClipboard()

const open = ref(false)

const shareUrl = computed(() => {
  return `${origin}${makeTaskUrl(props.task)}`
})

const onCopyShareUrl = async () => {
  await copy(shareUrl.value)
  if (copied.value) {
    toast.add({
      color: 'green',
      title: 'Copied!',
    })
  }
}
</script>
