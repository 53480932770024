import type { DraggingState } from '#core/types'

const $useCurrentDraggingTask = () => {
  const currentDraggingTask = reactive<Partial<DraggingState>>({})

  return {
    currentDraggingTask,
  }
}

export const useCurrentDraggingTask = createSharedComposable(
  $useCurrentDraggingTask
)
